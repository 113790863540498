<div>
    <mat-card class="container-card">
        <mat-card class="background-card">
            <div style="padding-bottom: 10px;">
                <mat-card class="heading">
                    <h1 id="title">Kinase Members</h1>
                </mat-card>
            </div>

            <!-- <div> -->
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                <!-- class="mat-elevation-z8"> -->
                <ng-container matColumnDef="{{ genecolumn }}" *ngFor="let genecolumn of geneColumns">
                    <th mat-header-cell *matHeaderCellDef>
                        <h1>{{ genecolumn }} </h1>
                    </th>
                    <td mat-cell *matCellDef="let gene"> {{gene.gene_name}}</td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let gene" [attr.colspan]="geneColumns.length">
                        <div class="example-element-detail" [@detailExpand]="gene == expandedGene ? 'expanded' : 'collapsed'">

                            <div class="example-element-description">
                                <table mat-table [dataSource]="gene.uniquegenes" multiTemplateDataRows>
                                    <ng-container matColumnDef="Gene">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <h3> Gene </h3>
                                        </th>
                                        <td mat-cell *matCellDef="let uniquegene"> {{ uniquegene.unique_gene_name }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="DNA Sequence">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let uniquegene">
                                            <button mat-raised-button class="button-color" (click)="determineExpandedSequence(uniquegene, 1)">
                                                DNA Sequence
                                            </button>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Protein Sequence">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let uniquegene" style="overflow: hidden; text-overflow: ellipsis; width: 100px">
                                            <button mat-raised-button class="button-color" (click)="determineExpandedSequence(uniquegene, 2)">
                                                Protein Sequence
                                            </button>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sequDetail">
                                        <td mat-cell *matCellDef="let uniquegene" [attr.colspan]="uniquegeneColumns.length" style="width: 100px">
                                            <div class="example-element-detail" [@sequExpand]="uniquegene == expandedSequence ? 'expanded' : 'collapsed'">

                                                <div *ngIf="displaySeq == 1 ">
                                                    <h4>DNA Sequence</h4> <button mat-raised-button cdkCopyToClipboard="{{uniquegene.dna_seq}}">Copy to clipboard</button> <br>
                                                    <br> {{ uniquegene.dna_seq }}
                                                </div>
                                                <div *ngIf="displaySeq == 2 ">
                                                    <h4>Protein Sequence</h4> <button mat-raised-button cdkCopyToClipboard="{{uniquegene.protein_seq}}">Copy to clipboard</button> <br>
                                                    <br> {{ uniquegene.protein_seq }}
                                                </div>
                                            </div>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="uniquegeneColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: uniquegeneColumns;" class="example-element-row"></tr>
                                    <tr mat-row *matRowDef="let row; columns: ['sequDetail']" class="example-detail-row"></tr>
                                    <!-- <tr routerLink="/kinase" *ngFor = "let subgroup of group.subgroups">
                                        <a href = 'kinase-genedata/{{ subgroup.name }}' >{{ subgroup.name }} </a>
                                    </tr> -->
                                </table>

                            </div>
                        </div>
                        </td>
                </ng-container>

                <!-- <ng-container matColumnDef = "" -->

                <!-- <tr mat-header-row *matHeaderRowDef="geneColumns"></tr> -->
                <tr mat-row *matRowDef="let element; columns: geneColumns;" class="example-element-row" [class.example-expanded-row]="expandedGene === element" (click)="expandedGene = expandedGene === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
            <!-- </div> -->
        </mat-card>
    </mat-card>
</div>
<!-- <app-footer></app-footer> -->