<!-- {{ model }} -->


<mat-card class="container-card">
  <mat-card class="background-card">
    <div style="padding-bottom: 10px;">
      <mat-card >
        <h1 id="title">Phylogenetic Tree</h1> 
      </mat-card>
    </div>
    <!-- <mat-slide-toggle aria-label="input-selector" color=primary [(ngModel)]="model"></mat-slide-toggle> -->

    <div style="padding-bottom: 10px;" *ngIf="selected_data">
      <mat-card > 
        <img src = "/assets/PhylogeneticImages/{{selected_data['id']}}.jpg" id="hp">

        <h1> Selected Species</h1>
        <h2>{{ selected_data['Name'] }} </h2>
        <h3> Common Name: {{ selected_data['CommonName'] }} </h3>
        <h3> Lineage: {{ selected_data['Lineage'] }} </h3>
        <h3> Mnemonic: {{ selected_data['Mnemonic'] }} </h3>
        <h3> Rank: {{ selected_data['Rank'] }} </h3>
        <h3> Type: {{ selected_data['Type'] }} </h3>
        <table>
          <th><h3>Other Names:</h3></th>
          <tr *ngFor = "let i of selected_data ['Othername'].split('|')">
            <td>{{i}}</td>
          </tr>
        </table>
        <br>
        <!-- <h3> Othername: {{ this.selected_data['Othername'] }}</h3> -->
        <button mat-raised-button class="button-color"
                    [routerLink] = "['/organism-search/', selected_data['id']]">
                        Explore
                        <!-- <mat-icon style="font-size: 25px;">arrow_drop_down</mat-icon> -->
        </button>
        <a [routerLink]="['organism-search']" [state]="{ id:1 , name:'Angular'}"></a>

      </mat-card>
    </div>
    
    <div style="padding-bottom: 10px;">
      <mat-card id = radial-tree-card  style="padding-bottom: 10px;">
        <h2 id="exptitle">Circular Dendrogram</h2>
        <div id='radialtree' (click)="click('radialtree')"></div>
      </mat-card>
    </div>
    <div *ngIf="!organismData && !newickError">
      <mat-card>
        <mat-progress-bar mode="query"></mat-progress-bar>
      </mat-card>
    </div>

    <div *ngIf="newickError">
      <mat-card>
        <p>Could not complete the request: {{newickError}}</p>
      </mat-card>
    </div>

    <div *ngIf="organismData != null && organismData.length > 0">
      <mat-card>
        <table mat-table
          [dataSource]="dataSource" multiTemplateDataRows
          matSort>
          <!-- class="mat-elevation-z8"> -->
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef
                  mat-sort-header> 
                  <h1 id="exptitle"> Organism Name</h1> 
                </th>
                <td mat-cell *matCellDef="let organism" (click) = "test(organism)"> 
                    {{ organism['Name'] }} 

                </td>
                
            </ng-container>

            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef
                  mat-sort-header> 
                  <h1 id="exptitle"> Organism ID </h1> 
                </th>
                <td mat-cell *matCellDef="let organism"> 
                  <button mat-raised-button class="button-color"
                    [routerLink] = "['/organism-search/', organism['id']]">
                    {{ organism['id'] }}
                        <!-- <mat-icon style="font-size: 25px;">arrow_drop_down</mat-icon> -->
                    </button>
                  <!-- <a [routerLink] = "['/phylogenetic-tree/', organism['id']]">{{ organism['id'] }} </a> -->
                  
                </td>
            </ng-container>

            <ng-container matColumnDef="CommonName">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> <h1 id="exptitle"> Common Name </h1> </th>
              <td mat-cell *matCellDef="let organism"> 
                <div *ngIf = "organism['CommonName'] != ''">
                  {{ organism['CommonName'] }} 
                </div>

                <div *ngIf = "organism['CommonName'] == ''">
                  N/A
                </div>
                
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="Link">
                <th mat-header-cell *matHeaderCellDef> <h1> Description </h1> </th>
                <td mat-cell *matCellDef="let protein"> 
                    <button mat-raised-button class="button-color"
                    (click)="determineExpandedSequence(protein, 2)">
                        Protein Description  
                        <mat-icon style="font-size: 25px;">arrow_drop_down</mat-icon>
                    </button> 
                </td>
            </ng-container> -->

            <ng-container matColumnDef="Link">
                <th mat-header-cell *matHeaderCellDef> <h1 id="exptitle"> P3DB Data </h1> </th>
                  <td mat-cell *matCellDef="let organism"> 
                    <div class = "container">
                      <div class = "center">
                        <button mat-raised-button class="button-color" [routerLink] = "['/phylogenetic-tree/', organism['id']]">View Data </button>
                    <!-- <button mat-raised-button class="button-color"
                    [routerLink] = "['/organism-search/', organism['id']]">
                        View Data
                    </button> -->
                  </div>
                </div>
                  </td>
            </ng-container>

            <!-- <ng-container matColumnDef = "" -->

            <tr mat-header-row *matHeaderRowDef="organismColumns"></tr>
            <tr mat-row *matRowDef="let element; columns:organismColumns;"
                (click) = "update_selected_row(element)"
                class="example-element-row"
                [class.example-expanded-row]="expandedProtein === element">
            </tr>
            <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20, 45]"

          showFirstLastButtons
          aria-label="Select page">
        </mat-paginator>
      </mat-card>
    </div>
    
  </mat-card>
</mat-card>
