import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
declare var logojs:any;

@Component({
  selector: 'app-motif',
  templateUrl: './motif.component.html',
  styleUrls: ['./motif.component.scss']
})
export class MotifComponent implements OnInit {


  constructor(private http: HttpClient) { }
  ELEMENT_DATA;
  requestError: string;
  PROTEIN_PPM = [];
  Motif_files_list = [];
  Motiffiles = new FormControl();
  errorMessage: boolean = false;

  ngOnInit(): void {
    //this.createLogo();
    this.get_motif_files();

  }

  load_logojs(){
    this.getData(this.Motiffiles.value);
    this.getsortedsites(this.Motiffiles.value);
  }

  get_motif_files(){
    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'getmotifnames/'),
    ]).subscribe((result) => {
      this.Motif_files_list = Object.values(result[0]['data']);
    }, error => {
      console.log(error);
      //this.requestError = error.statusText;
    });
  }
   transpose(matrix: number[][]): number[][] {
    return matrix[0].map((_, i) => matrix.map(row => row[i]));
}

  getData(kinase: string) {

    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'getpwm/?kinase='+ kinase),
    ]).subscribe((result) => {
      let res: any = Object.values(result[0]['data']);

      this.PROTEIN_PPM = result[0]['data'];
      if(res.length == 0){
        var elem = document.getElementById("logo");
        elem.innerHTML = "";
        this.errorMessage = true;
      }else{
        let alphabets = Array.from({length: 26}, (_, i) => String.fromCharCode(65 + i));
        let prot_ppm = {}

        alphabets.forEach(a => {

          if(this.PROTEIN_PPM[a] == undefined){
            if(a!='O' && a!='J' && a!='U' && a!='X'){
            prot_ppm[a] =  new Array(res[0].length).fill(0.0);
            }
          }
          else{
            prot_ppm[a] = this.PROTEIN_PPM[a]
          }
        });
        console.log(prot_ppm);
        this.errorMessage = false;
        this.PROTEIN_PPM = this.transpose(Object.values(prot_ppm));
        console.log(this.PROTEIN_PPM)

        logojs.embedProteinLogo(document.getElementById("logo"), { ppm: this.PROTEIN_PPM });
      }


    }, error => {
      console.log(error);
      //this.requestError = error.statusText;
    });
  }

  getsortedsites(kinase){
    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'getsortedsites/?kinase=' + kinase),
    ]).subscribe((result) => {
      let info = Object.values(result[0]['data']);
      this.ELEMENT_DATA = {
        name: [
          'Sequence Name',
          'Start',
          'P-Value',
          'Site',
        ],
        display: [
          'Sequence Name',
          'Start',
          'P-Value',
          'Site',
        ],
        data: info,
      };
    }, error => {
      console.log(error);
      this.requestError = error.statusText;
    });

  }

  fetchJson(filename) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }


  // createLogo(): void {
  //   const sequences = [
  //     {bits: 2, seq: 'A'},
  //     {bits: 2, seq: 'C'},
  //     {bits: 2, seq: 'G'},
  //     {bits: 2, seq: 'T'}
  //   ];

  //   const logo = new Logo(sequences, {
  //     selector: '#motif-logo',
  //     width: 100,
  //     height: 100
  //   });

  //   sequenceLogo(logo);
  // }

}


