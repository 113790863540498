import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-csv-display',
  templateUrl: './csv-display.component.html',
  styleUrls: ['./csv-display.component.css']
})
export class CsvDisplayComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  defaultPageSize = 50;
  pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.loadCsvData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.paginator.pageSize = this.defaultPageSize;
  }

  private loadCsvData(): void {
    const csvUrl = 'assets/yourfile.csv'; // Make sure this is the correct path
    this.http.get(csvUrl, { responseType: 'text' })
      .pipe(map(csv => this.csvToArray(csv)))
      .subscribe(data => {
        if (data && data.length > 0) {
          this.displayedColumns = Object.keys(data[0]);
          this.dataSource.data = data;
        }
      });
  }

  private csvToArray(csv: string): any[] {
    const lines = csv.split('\n');
    const headers = lines.shift().split('\t');
    return lines.map(line => {
      const data = line.split('\t');
      return headers.reduce((obj, header, idx) => {
        obj[header] = data[idx];
        return obj;
      }, {});
    });
  }
}
