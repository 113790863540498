
  <div class="header" style = "font-weight: bold">
    Investigators
  </div>


  <div class="row" style = "justify-content: center;">

    <div class="column">
      <div class="card">
        <img src="assets/images/dongxu.jpg" alt="Jane" class="image">
        <div class="container">
          
          <h2>Dong Xu</h2>
          <a href="mailto:xudong@missouri.edu">xudong@missouri.edu</a>
          <h2>Curators’ Distinguished Professor</h2>
          <p >University of Missouri - Columbia</p>
        
          <div class="information">
            Dr. Xu is Shumaker Endowed Professor in Department of Electrical Engineering and Computer Science, with appointments in the Christopher S. Bond Life Sciences Center and the Informatics Institute at the University of Missouri. His research is in computational biology and bioinformatics. More information about his research can be found <a href="http://digbio.missouri.edu/">here</a>.
                  </div>
        </div>
      </div>
    </div>


 
        <div class="column">
          <div class="card">
            <img src="assets/images/qiuming3.jpg" alt="Jane" class ="image" >

            <div class="container">
              
              <h2>Qiuming Yao</h2>
              <a href="mailto:qyao3@unl.edu">qyao3@unl.edu</a>
              <h2>Assistant Professor</h2>
              <p >University of Nebraska - Lincoln</p>
            
              <div class="information">
                Dr. Yao is the project leader of Integrated Digital Omics Lab (IDOL) at the University of Nebraska Lincoln. His lab develops scalable algorithms, tools and databases in order to integrate different types of high throughput omics data to understand complex biological systems. Dr. Yao's website is linked <a href="https://cse.unl.edu/~qyao/#home">here.</a>
              </div>
            </div>
          </div>
        </div>
        
        <div class="column">
          <div class="card">
            <img src="assets/images/jay.jpg" alt="Jane" class ="image" >
            <div class="container">
              
              <h2>Jay Thelen</h2>
              <a href="mailto:thelenj@missouri.edu">thelenj@missouri.edu</a>
              <h2>Professor</h2>
              <p >University of Missouri - Columbia</p>
              <div class="information">
                Dr. Thelen is a Professor of Biochemistry at the Bond Life Sciences Center of the University of Missouri. He is a recipient of both the Mizzou Chancellor’s and Presidential Awards for research creativity and excellence. His lab studies metabolic regulation of oil production in plants, and leverages these findings to engineer higher oil crops.
              </div>
            </div>
          </div>
        </div>

        <div class="header" style = "font-weight: bold">
          Collaborators
        </div>
      
        <div class="row" style = "justify-content: center;">
              <div class="column">
                <div class="card">
                  <!-- <img src="assets/images/ianwallaceedit.jpg" alt="Jane" style=" width: 100%" > -->
                  <img src="assets/images/ianwallace.jpg" alt="Jane" class="image" >

                  <div class="container">
                    
                    <h2>Ian Wallace</h2>
                    <a href="mailto:ian.wallace@uga.edu">ian.wallace@uga.edu</a>
                    <h2>Associate Professor</h2>
                    <p >University of Georgia</p>
                    <div class="information">

                      Dr. Wallace is an Associate Professor and the director of the biochemistry graduate program at the University of Nevada. He specializes in molecular biology and molecular cloning, and is the leader of the Wallace Lab at the University of Nevada, Reno. His lab's website can be found <a href="https://bmb.uga.edu/directory/people/ian-wallace">here.</a>
                    </div>
                  </div>
                </div>
              </div>

        <div class="column">
          <div class="card">
            <img src="assets/images/bartley_laura.jpeg" alt="Jane" class ="image" >
            <div class="container">
              
              <h2>Laura Bartley</h2>
              <a href="mailto:laura.bartley@wsu.edu">laura.bartley@wsu.edu</a>
              <h2>Associate Professor</h2>
              <p >Washington State University</p>
            
              <div class="information">
                Dr. Bartley is Associate Professor at Washington State University. She uses her expertise in molecular biology and biochemistry to research the synthesis and regulation of grass cell walls in order to help with the world's energy needs in the future. Her page on WSU's website can be found <a href="https://ibc.wsu.edu/laura-bartley/">here.</a>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <img src="assets/images/garystacey.jpg" alt="Jane" class ="image" >
            <div class="container">
              
              <h2>Gary Stacey</h2>
              <a href="StaceyG@missouri.edu">StaceyG@missouri.edu</a>
              <h2>Curators’ Distinguished Professor</h2>
              <p >University of Missouri - Columbia</p>
            
                   <div class="information">
                    Dr. Stacey is a Curators' Professor of Plant Science at the University of Missouri - Columbia, as well as the director of the Legume-Microbe Interactions Laboratory, which broadly focuses on plant and microbe development. His page on Mizzou's website can be found <a href="https://cafnr.missouri.edu/person/gary-stacey/">here</a> and his lab's website can be found <a href="https://staceylab.missouri.edu/">here.</a>
                  </div>
            </div>
          </div>
        </div>

      
       



      <div class="header" style = "font-weight: bold">
        Developers
      </div>
      <div class="row" style = "justify-content: center;">

        <div class="column">
          <div class="card">
            <img src="assets/images/LiSu.jpg" alt="LiSu" class ="image" >
            <div class="container">
              
              <h2>Li Su</h2>
              <a href="mailto:lisu@mail.missouri.edu">lisu@mail.missouri.edu</a>
              <h2>Ph.D. student, Data Analyst</h2>
              <p >University of Missouri - Columbia</p>
            
              <div class="information">
                Li Su is a third year Ph.D. student at DBL. She collects and performs data wrangling for P3DB sites. In addition, she is also interested in single-cell analytics.
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <img src="assets/images/chunhui.jpg" alt="Chunhui" class ="image" >
            <div class="container">
              
              <h2>Chunhui Xu</h2>
              <a href="mailto:cx9p9@mail.missouri.edu">cx9p9@mail.missouri.edu</a>
              <h2>Ph.D. student, Project Manager and Data Analyst</h2>
              <p >University of Missouri - Columbia</p>
            
              <div class="information">
                Chunhui Xu is a Ph.D. student at the MU Institute for Data Science and Informatics (MUIDSI).
                He works as a research assistant for Professor Dong Xu studying deep learning methods to predict bioinformatics topics,
                including PTM predictions and sequence prediction.
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <img src="assets/images/Sai.jpg" alt="Sai" class ="image" >
            <div class="container">
              
              <h2>Sai Akhil Chopparapu</h2>
              <a href="mailto:scd2z@missouri.edu">scd2z@missouri.edu</a>
              <h2>Main Developer</h2>
              <p >University of Missouri - Columbia</p>
            
              <div class="information">
                Developer Sai Akhil Chopparapu is pursuing a Master's in Computer Science at the University of Missouri. He is from India and interested in Web Application Development. In his free time, Sai loves to travel and play cricket.
          
                </div>
            </div>
          </div>
        </div>

      </div>


                <div class="column">
          <div class="card">
            <img src="assets/images/jamal_saied.jpg" alt="jamal" class ="image" >
            <div class="container">
              
              <h2>Jamal Saied Walker</h2>
              <a href="mailto:jsaied@mail.missouri.edu">jsaied@mail.missouri.edu</a>
              <h2>Main Developer</h2>
              <p >University of Missouri - Columbia</p>
              <!-- <a href="https://www.hudso.dev">www.hudso.dev</a> -->

            
              <div class="information">
                Jamal is an undergraduate student of Computer Science at the University of Missouri. He has helped develop the full backend system of P3DB. He has also upgraded the entire database in order to fit our new Angular architecture.
                <!-- Matt is a senior graduating with a Bachelor's in Computer Science and Music in December 2020.
                He has worked on implementing designs and new features on FatPlants, as well as managing the FatPlant database. -->
              </div>
            </div>
          </div>
        </div>

        



        <!-- <div class="column">
          <div class="card">
            <img src="assets/images/chunhui.jpg" alt="Jane" class ="image" >
            <div class="container">
              
              <h2>Yiwei Lu</h2>
              <a href="mailto:384054882@qq.com">384054882@qq.com</a>
              <h2>Backend Developer and Firebase Developer</h2>
              <div class="information">
                Yiwei is a Research Assistant(Web Developer) at the University of Missouri.
                He created the One-stop search page, Tools page, and a variety of cloud functions.
              </div>
            </div>
          </div>
        </div> -->



      <!-- <div class="row"> -->
 

<!-- assistant -->

        <!-- <div class="column">
          <div class="card">
            <img src="assets/images/chunhui.jpg" alt="Jane" class ="image" >
            <div class="container">
              
              <h2>Chengzi Cao</h2>
              <a href="mailto:caochengzij@outlook.com">caochengzij@outlook.com</a>
              <h2>UI Designer</h2>
              <p >University of Missouri - Columbia</p>
          
              <div class="information">
                Chengzi is an Undergrad Research Assistant(designer) and Senior at the University of Missouri.
                She is majoring in journalism and is passionate about web design. She will graduate in August of 2020.
              </div>
            </div>
          </div>
        </div> -->
<!-- 
        <div class="column">
          <div class="card">
            <img src="assets/images/puppytest.jpg" alt="Jane" class="image" >
  
            <div class="container">
              
              <h2>TESTING</h2>
              <a href="mailto:thelenj@missouri.edu">???</a>
              <h2>??</h2>
              <p >University of Missouri - Columbia</p>
              <div class="information">
  
                cute puppy
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="column">
        <div class="card">
          <img src="assets/images/default.png" alt="Jane" class ="image" >
          <div class="container">
            
            <h2>Ziting Mao</h2>
            <a href="mailto:zmmx6@mail.missouri.edu">zmmx6@mail.missouri.edu</a>
            <h2>Main Developer</h2>
            <p >University of Missouri - Columbia</p>
        
            <div class="information">
              Ziting Mao obtained a Master's Degree of Computer Science from MU. He has been involved in several research projects in DigBio Lab, which includes the development of Deep Learning based bioinformatics tools and web development. He has made contributions to the frontend of P3DB and created the pathway image recognition tools.
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <img src="assets/images/default.png" class ="image" >
          <div class="container">
            
            <h2>Trey Shaw</h2>
            <a href="mailto:tasztm@mail.missouri.edu">tasztm@mail.missouri.edu</a>
            <h2>Main Developer</h2>
            <p >University of Missouri - Columbia</p>
          
            <div class="information">
              Trey Shaw is main developer of P3DB and a graduate of the University of Missouri Columbia.
        
              </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <img src="assets/images/default.png" class ="image" >
          <div class="container">
            
            <h2>Michael Fisher</h2>
            <a href="mailto:mef48k@mail.missouri.edu">mef48k@mail.missouri.edu</a>
            <h2>Main Developer</h2>
            <p >University of Missouri - Columbia</p>
          
            <div class="information">
              Michael Fisher is main developer of P3DB and a graduate of the University of Missouri Columbia.
              </div>
          </div>
        </div>
      </div>

    </div>

    
    <div class="header" style = "font-weight: bold">
      Acknowledgements
    </div>
  
    <!-- interns -->
    <div class="acknowledgementsRow" style = "justify-content:center;">


    <div class="acknowledgementsColumn">
      <div class="acknowledgements">
        <div class="container">
          
          <h2>Anna Xu</h2>
          <a href="mailto:anna.xu@yale.edu">anna.xu@yale.edu</a>
          <h2>Intern Developer</h2>
          <p >Yale University</p>
        
          <div class="information">
            Anna is a talented writer who helped create P3DB's tutorial page. She is a junior at Yale University. 
          </div>
        </div>
      </div>
    </div>

    <div class="acknowledgementsColumn">
      <div class="acknowledgements">
        <div class="container">
          
          <h2>Kai Chen</h2>
          <a href="mailto:dc57y@missouri.edu">dc57y@missouri.edu</a>
          <h2>Intern Developer</h2>
          <p >University of Missouri - Columbia</p>
        
          <div class="information">
            Kai is an intern developer and sophomore at Mizzou.
          </div>
        </div>
      </div>
    </div>

    <div class="acknowledgementsColumn">
      <div class="acknowledgements">
        <div class="container">
          
          <h2>Jacob Coberly</h2>
          <a href="mailto:coberlyja@mail.umkc.edu">coberlyja@mail.umkc.edu</a>
          <h2>Intern Developer</h2>
          <p >University of Missouri - Columbia</p>
          <!-- is this right?? ^^^  -->
        
          <div class="information">
            Jacob is an intern developer who aids P3DB in database management. He is a senior at Mizzou.
          </div>
        </div>
      </div>
    </div>

    <div class="acknowledgementsColumn">
      <div class="acknowledgements">

        <div class="container">
          
          <h2>Chengzi Cao</h2>
          <a href="mailto:caochengzij@outlook.com">caochengzij@outlook.com</a>
          <h2>Contributor</h2>
          <p >University of Missouri - Columbia</p>
          <div class="information">

            Chengzi helped design the UI for P3DB. She earned her degree in journalism in August 2020.
            <!-- Chengzi helped design the UI for p3db and was an Undergrad Research Assistant(designer) at the University of Missouri.
            She earned a degree in journalism and she is passionate about web design. She graduated in August of 2020.            -->    
          </div>
        </div>
      </div>
    </div>

    </div>
    <!-- contributors -->

    <div class="acknowledgementsRow" style = "justify-content: center;">



<div class="acknowledgementsColumn">
  <div class="acknowledgements">
    <div class="container">
      
      <h2>Alex Liu</h2>
      <a href="mailto:alfzv@missouri.edu">alfzv@missouri.edu</a>
      <h2>Contributor</h2>
      <p >University of Missouri - Columbia</p>
    
      <div class="information">
        Alex is a P3DB contributor and a CS undergraduate at Mizzou.
      </div>
    </div>
  </div>
</div>

<div class="acknowledgementsColumn">
  <div class="acknowledgements">
    <div class="container">
      
      <h2>Ramya Bhamidipati</h2>
      <a href="mailto:nbny6@mail.missouri.edu">nbny6@mail.missouri.edu</a>
      <h2>Contributor</h2>
      <p >University of Missouri - Columbia</p>
    
      <div class="information">
        Ramya is a previous contributor to P3DB and is a computer science master's student at Mizzou.
      </div>
    </div>
  </div>
</div>

  <div class="acknowledgementsColumn">
    <div class="acknowledgements">

      <div class="container">
        
        <h2>Zhi Zhang</h2>
        <!-- <a href="mailto:dc57y@missouri.edu">dc57y@missouri.edu</a> -->
        <h2>Contributor</h2>
        <p >University of Missouri - Columbia</p>
        <!-- is this right???^ -->
        <div class="information">

          Zhi Zhang is working towards a master's degree in computer science. He has contributed to P3DB in the past.
        </div>
      </div>
    </div>
  </div>

<div class="acknowledgementsColumn">
<div class="acknowledgements">
<div class="container">
  
  <h2>Andy Murphy</h2>
  <!-- <a href="mailto:dc57y@missouri.edu">dc57y@missouri.edu</a> -->
  <h2>Contributor</h2>
  <p >University of Missouri - Columbia</p>

  <div class="information">
    Andy Murphy is a master's student in computer science who has previously worked with P3DB.
  </div>
</div>
</div>
</div>

<div class="acknowledgementsColumn">
<div class="acknowledgements">
<div class="container">
  
  <h2>Ashwin Kumar Chippa</h2>
  <a href="mailto:acc9f@mail.missouri.edu">acc9f@mail.missouri.edu</a>
  <h2>Contributor</h2>
  <p >University of Missouri - Columbia</p>

  <div class="information">
    Ashwin is a computer science student and previous P3DB web designer working towards his master's degree at Mizzou. 
  </div>
</div>
</div>
</div>

    <!-- <div class="column">
      <div class="card">
        <div class="container">
          
          <h2>Gary Stacey</h2>
          <a href="StaceyG@missouri.edu">StaceyG@missouri.edu</a>
          <h2>Curators’ Distinguished Professor</h2>
          <p >University of Missouri - Columbia</p>
        
               <div class="information">
                Dr. Stacey is a Curators' Professor of Plant Science at the University of Missouri - Columbia, as well as the director of the Legume-Microbe Interactions Laboratory, which broadly focuses on plant and microbe development. His page on Mizzou's website can be found <a href="https://cafnr.missouri.edu/person/gary-stacey/">here</a> and his lab's website can be found <a href="https://staceylab.missouri.edu/">here.</a>
              </div>
        </div>
      </div>
    </div> -->


      </div>

      




   






    

