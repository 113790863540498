import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { Group } from 'src/app/interfaces/group';
// import { GroupService } from 'src/app/Services/group.service';
import { MatTableDataSource } from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Subgroup } from 'src/app/interfaces/subgroup';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';
import {MatPaginator} from '@angular/material/paginator';
import { SrvRecord } from 'dns';
import { Gene } from 'src/app/interfaces/gene';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@Component({
  selector: 'app-kinaseclient',
  templateUrl: './kinaseclient.component.html',
  styleUrls: ['./kinaseclient.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class KinaseclientComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
 
  constructor(private router: Router, 
              // private groupService: GroupService,
              private database : DatabaseConnService,
              // private subgroupService: SubgroupService,
              // private geneService: GeneService
              ) { }

  message?: string;
  groupmessage?: string;
  submessage?: string;
  genemessage?: string;
  genes?: Gene[];
  uniquegenemessage?: string;
  is_tab_view: boolean = true;

  groups?: string[];
  GroupColumns = ['Group'];
  expandedGroup: string | null;

  SubgroupColumns = ['Subgroups'];
  expandedSubgroup: string | null;

  // GeneColumns = ['Genes'];
  
  dataSource: MatTableDataSource<string>;
  ELEMENT_DATA;
  subgroup_data = [];
  comprehensive_data = [];

  ngOnInit(): void {
    this.getGroups();
    // this.setData();
    console.log(this.groups)
  }

  nav(route: String){
    this.router.navigate([route]);
  }

  getGroups(): void {

    // old code to be replaced

    const temp_json = 'assets/p3db_kinase.json';
    this.database.get('groups', [""])
    .subscribe(
      data => { this.groups = data['data'];
                this.groupmessage = "group data retrieved";

                for (let i = 0; i < this.groups.length; ++i)
                {
                   this.database.get('subgroup', [this.groups[i]['name']])
                  .subscribe(
                    subdata => {this.groups[i]['subgroups'] = subdata['data'];
                                this.submessage = "subdata retrieved"; },
                    suberror => { this.submessage = suberror.message; }
                  )
                }

                this.dataSource = new MatTableDataSource(this.groups);
              },
      error => { this.groupmessage = error.groupmessage;}
    )

    async function readJSONFile(filePath) {
      try {
          const response = await fetch(filePath);

          if (!response.ok) {
              throw new Error(`Failed to fetch ${filePath}: ${response.status} ${response.statusText}`);
          }

          const jsonData = await response.json();

          // console.log(jsonData);

          return jsonData;
      } catch (error) {
          console.error('Error reading JSON file:', error);
      }
    }

    readJSONFile(temp_json).then(data => {

      this.ELEMENT_DATA = {
        name: ["group_name", "subgroup_name", "gene_name", "dna_seq", "protein_seq"],
        display: ["Group name", "Subgroup name", "Gene name", "DNA sequence", "Protein sequence"],
        data: data, 
      };
    });

    // new code, implement once API is implemented

    // let subgroup_data = [];
  
    // this.database.get('groups', [""])
    //   .subscribe(
    //     data => {
    //       // get groups
    //       this.groups = data['data'];
    //       this.groupmessage = "group data retrieved";
  
    //       const subgroupPromises = [];
          
    //       // get subgroups
    //       for (let i = 0; i < this.groups.length; ++i) {

    //         subgroupPromises.push(
    //           this.database.get('subgroup', [this.groups[i]['name']])
    //             .toPromise()
    //             .then(subdata => {
    //               this.groups[i]['subgroups'] = subdata['data'];
    //               this.submessage = "subdata retrieved";
    //               subgroup_data = [...subgroup_data, ...subdata.data];
    //             })
    //             .catch(suberror => {
    //               this.submessage = suberror.message;
    //             })
    //         );
    //       }
  
    //       Promise.all(subgroupPromises).then(() => {

    //         this.subgroup_data = subgroup_data;

    //         for (let i = 0; i < this.subgroup_data.length; ++i)
    //         {
    //           let subgroup_name = this.subgroup_data[i].subgroup_name;
    //           let group_name = this.subgroup_data[i].group_name;
    //           this.getGenes(subgroup_name, group_name);
    //         }
    //         console.log(this.comprehensive_data);
    //         console.log(subgroup_data);

    //         this.ELEMENT_DATA = {
    //           name: ["group_name", "subgroup_name", "gene_name", "dna_seq", "protein_seq"],
    //           display: ["Group name", "Subgroup name", "Gene name", "DNA sequence", "Protein sequence"],
    //           data: this.comprehensive_data, 
    //         };
    //         this.dataSource = new MatTableDataSource(this.groups);
    //       });
    //     },
    //     error => {
    //       this.groupmessage = error.groupmessage;
    //     }
    //   );
  }

  getGenes(subgroup_name: string, group_name: string): void
  {
    this.database.get('gene', [subgroup_name])
    // this.geneService.get(subgroup_name)
    .subscribe(
      geneData => { this.genes = geneData['data'];
                    this.genemessage = "gene data loaded";
                    // console.log(geneData);

                    for (let i = 0; i < this.genes.length; ++i)
                    {
                      this.database.get('uniqueGene', [this.genes[i].gene_name])
                      .subscribe(
                        uniquegeneData => { this.genes[i].uniquegenes = uniquegeneData['data'];
                                            this.uniquegenemessage = "uniquegene data loaded";
                                            for (let j = 0; j < this.genes[i].uniquegenes.length; ++j)
                                            {
                                              const newData = {
                                                group_name: group_name,
                                                subgroup_name: subgroup_name,
                                                gene_name: this.genes[i].uniquegenes[j].unique_gene_name,
                                                dna_seq: this.genes[i].uniquegenes[j].dna_seq,
                                                protein_seq: this.genes[i].uniquegenes[j].protein_seq,
                                              };
                                              this.comprehensive_data.push(newData);
                                            }

                                          },
                        uniquegeneError => { this.uniquegenemessage = uniquegeneError.message; }
                      )
                    }
                  },
      geneError => { this.genemessage = geneError.message; }
    )
  }
  

}
