import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Phosphoprotein, ProteinDescription, Accession } from 'src/app/interfaces/phosphoprotein';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';

@Component({
  selector: 'app-phosphoprotein',
  templateUrl: './phosphoprotein.component.html',
  styleUrls: ['./phosphoprotein.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})

//=============================================================================
// PhosphoproteinBrowse Component
//=============================================================================
// Author(s): Michael Fisher (June 2021)
//=============================================================================
// This component is designed to provide functionality for the 
// https://dev.p3db.org/phosphoprotein-browse page. The following sections are divided
// up based on role and tasks for the functionality of the page, and have their
// respective fields and methods assigned as such
//=============================================================================
// == Database access:
// Fields: 
// public phosphoproteins?: Phosphoprotein[];
//
// methods:
// private loadPhosphoproteins(): void 
//
// == JSON Processing Functions:
// Fields:
//
// Methods:
// private unpack_phosphoData(string): Phosphoprotein[]
// private unpack_ProteinDescription(string): ProteinDescription
// private unpack_Accession(string): Accession[]
//
// == Table Formatting:
// Fields:
// public phosphoColumns: string[]
// public dataSource: MatTableDataSource<Phosphoprotein>;
// public expandedProtein?: Phosphoprotein;
// public displaySeq: number | null
//
// Methods:
// public determineExpandedSequence(Phosphoprotein, number) : void
//
// == Misc
// Fields:
// public phosphoproteinMessage?: any
//
// Methods:
// private sanitize(string): string 
//=============================================================================
   

export class PhosphoproteinBrowseComponent implements OnInit {

  constructor(private router: Router,
              private database : DatabaseConnService) { }

  ngOnInit(): void {
    this.loadPhosphoproteins();
  }

  //===========================================================================
  // Database Access
  //===========================================================================
  public phosphoproteins?: Phosphoprotein[];

  private loadPhosphoproteins(): void 
  {
    this.database.get('browse', ["1", "10"])
    .subscribe(
      phosphoData => { this.phosphoproteins = this.unpack_phosphoData(phosphoData['data']['rows']);
                       this.dataSource = new MatTableDataSource(this.phosphoproteins)
                     },
      phosphoError => { this.phosphoproteinMessage = phosphoError.message;}
    )
  }

  //===========================================================================
  // JSON Processing Functions
  //===========================================================================

  private unpack_phosphoData( phosphoData: string): Phosphoprotein[]
  {
    let phosphoproteins = [];

    for (let i = 0; i < phosphoData.length; ++i)
    {
      let phosphoprotein: Phosphoprotein = {
        protein_id: this.sanitize(phosphoData[i]['protein_id']),
        name: this.sanitize(phosphoData[i]['organism']),
        protein_description:  this.unpack_ProteinDescription(phosphoData[i]['protein_description'])
      }
      phosphoproteins.push(phosphoprotein);
    }
    return phosphoproteins;
  }

  private unpack_ProteinDescription(descriptionData: string): ProteinDescription
  {
    let proteinDescription: ProteinDescription  = {
      annotation: this.sanitize(descriptionData['annotation']),
      accessions: this.unpack_Accession(descriptionData['accessions'])
    }

    return proteinDescription;
  }

  private unpack_Accession(accessionData: string): Accession[] {
    let accession_list = [];

    for (let i = 0; i < accessionData.length; ++i)
    {
      let accession: Accession = {
        name: this.sanitize(accessionData[i]['name']),
        ref: this.sanitize(accessionData[i]['ref'])
      }

      accession_list.push(accession);
    }

    return accession_list;
  }

  //===========================================================================
  // Table Formatting:
  //===========================================================================

  public phosphoColumns = ['protein', 'organism', 'description', 'detail'];
  public dataSource: MatTableDataSource<Phosphoprotein>;
  public expandedProtein?: Phosphoprotein;
  public displaySeq: number | null

  public determineExpandedSequence(protein: Phosphoprotein, seq: number) : void {
    console.log(this.displaySeq, this.expandedProtein, protein);
    if (this.expandedProtein === protein && this.displaySeq == seq){ // press same uniqueGene
      this.expandedProtein = null;
      this.displaySeq = null;
    }
    else{
      this.expandedProtein = protein;
      this.displaySeq = seq;
    }
  }

  //===========================================================================
  // Misc
  //===========================================================================

  public phosphoproteinMessage?: any;

  private sanitize(obj: string): string { return obj ? String(obj).replace('\/', '/') : 'N/A' }

}

