import { Component, OnInit } from '@angular/core';
import { Organism } from 'src/app/interfaces/organism';
import { TaxonomyNode } from 'src/app/interfaces/taxonomy-tree';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute } from '@angular/router';
import { C } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-organism-search',
  templateUrl: './organism-search.component.html',
  styleUrls: ['./organism-search.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class OrganismSearchComponent implements OnInit {

  constructor(
    // private organismSearch: OrganismSearchService,
    private database : DatabaseConnService,
    private route: ActivatedRoute,
    // private router: Router
    ) { }

  organismId?: number;
  // specimen?: Organism;
  specimen?: TaxonomyNode;
  expandedChild: boolean;
  childColumns = ['Id', 'Name', 'Rank'];
  displayedColumns =['Id','Name','Rank'];

  organismMessage?: string;
  organismError?: string;
  searchingOrganism: boolean = false;

  verticalColumnNames = ['Column', 'Value'];
  organismDetails: any[] = [];
  organismTree : any;
  treeControl : any;
  hasChild : any;
  searchLabels = [
    {label:"Search by Taxonomy ID", value:'1', isLeaf:true, defaultValue: '3702'},
    {label:"Search by Name", value:'2', isLeaf:true, defaultValue: 'Arabidopsis'}

  ]

  ngOnInit(): void {
    
    this.organismId = this.route.snapshot.params.organismId;

    if (this.organismId) {
      this.pullSpecimenData();
    }
  }

  pullSpecimenData(): void{

    if (!this.organismId) {
      this.organismError = "Please enter an organism ID"
      return;
    }

    this.searchingOrganism = true;
    this.database.get('organism', [this.organismId.toString()])
    .subscribe(
      organismData => {      
        if (organismData['data'] && organismData['data'].length != 0) {

          this.specimen = this.unpackData(organismData['data']);
          this.expandedChild = false; 

          this.pushOrganismDetails(this.specimen.current);
          this.generateOrganismTree(this.specimen);
          this.searchingOrganism = false;
        }
        else {
          this.organismError = "No results found for that query"
          this.searchingOrganism = false;
        }
      },
      error => { 
        this.organismError = error.statusText;
        this.searchingOrganism = false; 
      }
    )
  }

  pullSpecimenDataByName(): void{

    if (!this.organismId) {
      this.organismError = "Please enter an organism ID"
      return;
    }


    this.searchingOrganism = true;
    this.database.get('organisms', ["Name" ,this.organismId.toString()])
    .subscribe(
      organismData => {      
        if (organismData['data'] && organismData['data'].length != 0) {

          this.specimen = this.formatData(organismData['data']);
          this.expandedChild = false; 

          this.pushOrganismDetails(this.specimen.current);
          this.generateOrganismTree(this.specimen);
          this.searchingOrganism = false;
        }
        else {
          this.organismError = "No results found for that query"
          this.searchingOrganism = false;
        }
      },
      error => { 
        this.organismError = error.statusText;
        this.searchingOrganism = false; 
      }
    )
  }

  pushOrganismDetails(currentOrganism: Organism): void{
    this.organismDetails = [];
    this.organismDetails.push({column1 : 'Name', column2: currentOrganism.name});
    this.organismDetails.push({column1 : 'Common Name', column2: currentOrganism.commonName});
    this.organismDetails.push({column1 : 'Lineage', column2: currentOrganism.lineage});
    this.organismDetails.push({column1 : 'Rank', column2: currentOrganism.rank});
    this.organismDetails.push({column1 : 'Type', column2: currentOrganism.type});
    this.organismDetails.push({column1 : 'Mnuemonic', column2: currentOrganism.mnemonic});
  }

  generateOrganismTree(specimen: TaxonomyNode): void{
    this.organismTree = new MatTreeNestedDataSource<Organism>();
    this.organismTree.data = [];

    if(specimen.parent != null && specimen.parent.id != '0')
    {
      this.organismTree.data.push(specimen.parent);  

      if(specimen.current != null)
      {    
        this.organismTree.data[0].child.push(specimen.current);
      }
                        
      if(specimen.child != null && specimen.child.length > 0)
      {
        this.organismTree.data[0].child[0].child = specimen.child;
      }                      
    }
    else
    {
      this.organismTree.data.push(specimen.current);  

      if(specimen.child != null && specimen.child.length > 0)
      {
        this.organismTree.data[0].child = specimen.child;
      } 
    }
                        
    this.treeControl = new NestedTreeControl<Organism>(node => node.child);                        
    this.treeControl.dataNodes = this.organismTree.data;
    this.treeControl.expandAll();

    this.hasChild = (_: number, node: Organism) => !!node.child && node.child.length > 0;
  }

  search(specimenId: any): void{
    this.organismId = specimenId;
    this.pullSpecimenData();
  }

  formatData(data: any): TaxonomyNode
  {
    //need to implement parentData
    let parentData: Organism[] = [];
    let currentData: Organism[] = [];
    let childData: Organism[] = [];
    for (let i = 0; i < data.length; i++)
    {
      let currentNode = data[i];
      let currentOrganism: Organism = {
        commonName: currentNode.CommonName,
        lineage: currentNode.Lineage,
        mnemonic:currentNode.Mneumonic,
        name: currentNode.Name,
        otherName: currentNode.Othername,
        rank: currentNode.Rank,
        type: currentNode.Type,
        id: currentNode.Identification,
        child: currentNode.Children
      }

      if (currentNode.Children != "")
      {
        currentData.push(currentOrganism);
      }
      else
      {
        childData.push(currentOrganism);
      }
    }

    if (currentData.length === 0)
    {
      currentData.push(childData[0]);
    }


    let specimen: TaxonomyNode  = {
      //parent is not implemented
      parent: this.unpackOrganism(currentData)[0],
      current: this.unpackOrganism(currentData)[0],
      child: this.unpackOrganism(childData)
    }

    return specimen
  }

  unpackData(data: any): TaxonomyNode
  {
    let currentData = data['current'];
    let parentData = data['parent'];
    let childData = data['child'];

    let specimen: TaxonomyNode  = {
      parent: this.unpackNode(parentData)[0],
      current: this.unpackNode(currentData)[0],
      child: this.unpackNode(childData)
    }

    return specimen
  }

  unpackNode(dataList: any): Organism[]
  {
    let organisms = [];

    for (let i = 0; i < dataList.length; ++i)
    {
      let organism: Organism = {
        id: dataList[i].hasOwnProperty('id') ? dataList[i]['id'] : "N/A" ,
        rank: dataList[i].hasOwnProperty('Rank') ? dataList[i]['Rank'] : "N/A",
        name: dataList[i]['Name'] ? dataList[i]['Name'] : "N/A",
        type: dataList[i]['Type'] ? dataList[i]['Type'] : "N/A",
        //otherName implementation is broken right now since it is implemented as a string[], but it looks like it might not be needed?
        // when trying to implement it correctly with 'otherName' instead of the index instead of 'OtherName', there's an error
        otherName: dataList[i]['OtherName'] ? dataList[i]['OtherName'] : "N/A",
        lineage: dataList[i]['Lineage'] ? dataList[i]['Lineage'] : "N/A",
        commonName: dataList[i]['CommonName'] ? dataList[i]['CommonName'] : "N/A",
        mnemonic: dataList[i]['Mnemonic'] ? dataList[i]['Mnemonic'] : "N/A",
        child: []
      }

      organisms.push(organism);
    }
    return organisms;
  }

  unpackOrganism(dataList: Organism[]): Organism[]
  {
    let organisms = [];

    for (let i = 0; i < dataList.length; ++i)
    {
      let organism: Organism = {
        id: dataList[i].hasOwnProperty('id') ? dataList[i]['id'] : "N/A" ,
        rank: dataList[i].hasOwnProperty('rank') ? dataList[i]['rank'] : "N/A",
        name: dataList[i]['name'] ? dataList[i]['name'] : "N/A",
        type: dataList[i]['type'] ? dataList[i]['type'] : "N/A",
        otherName: dataList[i]['otherName'] ? dataList[i]['otherName'] : ["N/A"],
        lineage: dataList[i]['lineage'] ? dataList[i]['lineage'] : "N/A",
        commonName: dataList[i]['commonName'] ? dataList[i]['commonName'] : "N/A",
        mnemonic: dataList[i]['mnemonic'] ? dataList[i]['mnemonic'] : "N/A",
        child: []
      }

      organisms.push(organism);
    }

    return organisms;
  }

  fn_search(parameter) {
    this.organismId = parameter.value;
    if (parameter.label === '1')
    {
      this.pullSpecimenData();
    }
    if (parameter.label === '2')
    {

      this.pullSpecimenDataByName();
    }

  }

}
