import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SequenceData, Highlight, Seqtable } from 'src/app/interfaces/sequence';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';
import { SharedService } from 'src/app/shared.service';

/**
 * @description
 * @author Michael Fisher
 * @export
 */


@Component({
  selector: 'app-table-sequence',
  templateUrl: './Peptide-Conservation-Search.component.html',
  styleUrls: ['./Peptide-Conservation-Search.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PeptideConservationSearch implements OnInit {
  constructor(
    private database: DatabaseConnService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private sharedService: SharedService
  ) {}
  public SequenceData?: SequenceData;

 
  ngOnInit(): void {
    // this.pullBlastData();
    this.sharedService.selectedText$.subscribe((selectedText: string) => {
      if(selectedText) {
        this.organisim_sequence = selectedText;
      } else {
        // set a default value only if selectedText is not present
        this.organisim_sequence = 'AQRLRSHKLTQVSLFQKP,PEVLKRNYGPEVDVWSAG';
      }
    });
  }

  //===========================================================================
  // Request form setting
  //===========================================================================

  results: Object = null;
  is_search : boolean = false
  organisim_sequence : string = "";



  public pullBlastData(): void {
    console.log(this.organisim_sequence)
    // let data = this.organisim_sequence;

    // if (data == '') { return; } 

    let data = "MYLLIVFLSMLSSSVAGFFGRFLGSESVSRFNLIIFLILLVFSICLFRSLKQYLGKRMTQWCYLALVCQISLFLVLLRSHILAGFGTFSADVFTVFMGTFSVTGSSGGIVNHQDGASSEWFTYTSDMVEDSASSGRTSSSVNQPIPEEQAWEREARAQEHDRISAEVETITSACENLEAAMVRKAQILLHQRGVTLGDPEDVKRALQLALHDDWEHAIDDRKRHFTVLRRNFGTARC";
    
    this.is_search = true
    
    this.database.get('blast', [data]).subscribe(
      data => {
        console.log(data);
        if (data['status'] == '1')
        {
          let ret_data = {
            highlight : data['data']['highlight'],
            index_data : []
          }

          // console.log(ret_data);
          let seq_data = data['data']['seqtable'];

          for (let i = 0; i < seq_data.length; ++i){
            seq_data[i]['colors']['sequence'] = this.generate_color_data(seq_data[i]['colors']['sequence'])

            for (let k = 0; k < seq_data[i]['sequences'].length; ++k)
            {
              // console.log(seq_data[i]['sequences'][k])
              this.hightlight_sequence(data['data']['highlight'], seq_data[i]['sequences'][k])
            }
          }

          // this.markup_sequence(data['data']['highlight'], data['data']['seqtable'])


          this.results = data['data']['seqtable'];
        }
        
      }
    )
  }

  //===========================================================================
  // Table formatting
  //===========================================================================

  public nrColumns = ['name','seq', 'index'];

  //===========================================================================
  // Sequence Formatting and highlighing
  //===========================================================================

  private generate_color_data(color_str : string){
    let color_data = color_str.split(',')
    let color_arr = []
    for (let color_index in color_data)
    {
      color_arr.push(                
      '<span data-tooltip="' + 
      color_data[color_index] +
      '" data-tooltip-position="bottom" style="background: rgb(8 66 152 /' +
      color_data[color_index] + ');"> </span>')
    }

    return this.sanitizer.bypassSecurityTrustHtml(color_arr.join(''))
  }

  private hightlight_sequence(highlight : Array<Object>, record : Object)
  {
    if (record['name'] == "placeholder") return;
    let highlight_arr = []
    for (let i = 0; i < highlight.length; ++i)
    {
      if (highlight[i]['name'] == record['name'])
      {
        highlight_arr = highlight[i]['index'].split(',')
        for (let k = 0; k < highlight_arr.length; ++k)
        {
          highlight_arr[k] = Number(highlight_arr[k])
        }
      }
    }


    let index_min = record['index'] - record['sequence'].replaceAll('-', '').length;
    let index_max = record['index'];
    let loc_dict = []


    for (let highlight_index = 0; highlight_index < highlight_arr.length; ++highlight_index)
    {
      if (highlight_arr[highlight_index] >= index_min && highlight_arr[highlight_index] <= index_max)
      {
        let protein_index = index_min;
        for (let sequence_iter = 0; sequence_iter < record['sequence'].length; ++sequence_iter)
        {
          if (highlight_arr[highlight_index] == protein_index)
          {
            loc_dict.push(sequence_iter)

          } 
          if (record['sequence'][sequence_iter] != '-') ++protein_index;
        }
      }
    }

    // console.log(loc_dict);

    let split_str = record['sequence'].split('')

    // console.log(split_str);
    for (let i = 0; i < loc_dict.length; ++i)
    {
      console.log(split_str[loc_dict[i]])
      split_str[loc_dict[i]] =  '<span class="highlightText">' + split_str[loc_dict[i]] + '</span>' 
    }

    record['sequence'] = split_str.join('');
  }

  private sanitize(obj: string): string {
    return obj ? String(obj) : 'N/A';
  }
  private check(obj: string): string {
    return obj ? String(obj) : '';
  }
}
