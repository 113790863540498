<div class="page_container">
    <h1 id="title">Contact P3DB</h1>
    <mat-card class="top_container">
        <h2 id="secondary_title">Have a question or a suggestion? Contact us using the form below!</h2>
        <p> Thank you for reaching out to us - your contribution helps make P3DB a better resource for researchers like you.</p>

    </mat-card>
    <mat-card class="contact_box">
        <div class="form-container">
            <label class ="label" for="name">Name:</label>
            <mat-form-field class = "form_field" appearance="outline">
                <mat-label>Enter your name:</mat-label>
                <input matInput [(ngModel)]="name">
            </mat-form-field>
        </div>

          <div class="form-container">
            <label class ="label" for="user_email">E-mail:</label>
            <mat-form-field class = "form_field" appearance="outline">
                <mat-label>Enter your full email:</mat-label>
                <input matInput [(ngModel)]="user_email">
            </mat-form-field>
        </div>

          <div class="form-container">
            <label class ="label" for="subject">Subject:</label>
            <mat-form-field class = "form_field" appearance="outline">
                <mat-label>Enter your email subject:</mat-label>
                <input matInput [(ngModel)]="subject">
            </mat-form-field>
        </div>

          <div class="form-container">
            <label class ="label" for="message">Message:</label>
            <mat-form-field class = "form_field" appearance="outline">
                <mat-label>Enter your message:</mat-label>
                <textarea class = "message_box" matInput [(ngModel)]="message"></textarea>
            </mat-form-field>
        </div>

        <div class = "button-container">
            <p *ngIf="email_valid == false && form_filled == true" class="status_message">Invalid email format</p>
            <p *ngIf="form_filled == false" class="status_message">One or more fields are empty</p>
            <p *ngIf="email_valid == true && submitted == true" class="status_message">Thank you for your input!</p>
        </div>

        <div class="button-container">
            
            <button mat-flat-button color="primary" class="submit_button" (click)="submit_form()">
              Submit
            </button>
        </div>

    </mat-card>

</div>