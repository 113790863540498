<mat-card class="container-card">
  <mat-card class="background-card">

    <div style="padding-bottom: 1%;">
      <mat-card>
        <h1 id="title">Peptide Conservation Search</h1>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <h2 id="exptitle">Request Form</h2>
        <p>Enter your Sequence for Processing</p>
        <form>

          <mat-form-field appearance="outline" style="margin-left: 10px;" style="width: 100%; height: auto;">
            <mat-label>Input Sequence</mat-label>
            <textarea matInput name="organism_input" [(ngModel)] = "organisim_sequence"></textarea>
          </mat-form-field>
          <button mat-button  class="button_search" (click)="pullBlastData();">
            Search
          </button>
        </form>

      </mat-card>
    </div>

      <div *ngIf = "!this.results && this.is_search">
        <mat-card>
          <h1>Loading Data...</h1>
        </mat-card>
      </div>


      <div *ngIf = "results"> 

        <mat-card style="padding-top: 1%;">
          <h2>Sequence Comparision</h2>
          <table mat-table [dataSource]="results" multiTemplateDataRows>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>NAME</th>
              <td mat-cell *matCellDef="let search_results"> 
                <div><b>Input Sequence</b></div>

                <div *ngFor = "let i of search_results['sequences']">{{i['name']}}</div>
                <div>{{search_results['marks']['name']}}</div><br>
                <div>{{search_results['colors']['name']}}</div><br>

              </td>
            </ng-container>


            <ng-container matColumnDef="seq" style="margin: auto;">
              <th mat-header-cell *matHeaderCellDef>SEQUENCE</th>
              <td mat-cell *matCellDef="let search_results">
                <div><b>{{search_results['input_sequence']['sequence']}}</b></div>
                <div *ngFor = "let i of search_results['sequences']" [innerHTML] = "i['sequence']"></div>
                <!-- <div>{{search_results['sequences'][0]['sequence']}}</div>
                <div>{{search_results['sequences'][1]['sequence']}}</div> -->
                
                <div>{{search_results['marks']['sequence']}}</div>
                <div [innerHTML]="search_results['colors']['sequence']"> </div>
              </td>
            </ng-container>

            
            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef>INDEX</th>
              <td mat-cell *matCellDef="let search_results">
                <div><b>{{search_results['input_sequence']['index']}}</b></div>
                <div *ngFor = "let i of search_results['sequences']" >{{i['index']}}</div>
                <div>{{search_results['marks']['index']}}</div><br>
                <div>{{search_results['colors']['index']}}</div><br>

              </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="nrColumns "></tr>
            <tr mat-row *matRowDef="let element; columns: nrColumns; " class="mat-column-sequDetail" style="text-align: left; font-size: large;"
              >
            </tr>
          </table>
        </mat-card>
      </div>




  </mat-card>
</mat-card>
