//by ziting
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Breakpoints } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {

  ELEMENT_DATA;

  dataType="ptm";

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getData("AT4G38970.1");
  }

  fn_search(){
    this.ELEMENT_DATA = null;
    
    let element = document.getElementById("protein_id") as HTMLInputElement;
    this.getData(element.value);
  }
    
  //handles the joining of the two ajax requests for the edge and node info
  //could be moved out of this component and into cytoscape-example
  getData(id){
    //forkJoin combines the two observables from http requests and waits for both to finish
    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'edge?code=' + id),
      this.fetchJson(environment.BASE_API_URL + 'nodes?code=' + id),
      this.fetchJson(environment.BASE_API_URL + 'details?code=' + id)
    ]).subscribe(x => {
      //map edge_info.json and node_info.json to proper cyto format

         this.mapEdgesNodes(x[0]['data']["edge"], x[1]['data']["node"],x[2]['data'][0]); //edge node details
      });
  }

  //takes filename and returns an observable of HTTP request to that file
  //can be moved outside of component
  fetchJson(filename){
    let headers = new HttpHeaders();

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  //handles the logic specific to this data representation.
  //maps data from the format in the .json files to the format needed for this component
  //can be moved outside of component
  mapEdgesNodes(edges, nodes, details){

    if (nodes.length <= 1) {
      this.ELEMENT_DATA = { elements: {nodes: [], edges: []}, style: [] };
    }

    if (nodes.length > 1) {
      for(var i = 0; i < nodes.length; i++){
        if(nodes[i]['type'] == 'protein'){
          nodes[i]['nameScore'] = nodes[i]['node_name'] + "\n" + "Score:" + details.protein.Pscore.toFixed(2);
          nodes[i].details = details.protein;
        }else{
          nodes[i].details = details.nodes[i]
        }
        
        nodes[i]['id'] = nodes[i]['node_name'];
        nodes[i]['name'] = nodes[i]['node_name'] + "(" + nodes[i]['type'].slice(0,1).toUpperCase() + nodes[i]['type'].slice(1,3) + ")";
        switch(nodes[i]['node_name'][0]){
          case "R":
            nodes[i].color = "#8694fa";
            break;
          case "E":
            nodes[i].color = "#f93333";
            break;
          case "I":
            nodes[i].color = "#feff4f";
            break;
          case "C":
            nodes[i].color = "#e3f9ad";
            break;
          case "N":
            nodes[i].color = "#ee72a7";
            break;
          case "F":
            nodes[i].color = "#c7c88a";
            break;
          case "S": 
            nodes[i].color = "#ca9fc8";
            break;
          case "G":
            nodes[i].color = "#c0c0c0";
            break;
          case "P":
            nodes[i].color = "#f1f2f3";
            break;
          case "K":
            nodes[i].color = "#baaafc";
            break;
          case "D":
            nodes[i].color = "#fb7979";
            break;

          case "L":
            nodes[i].color = "#feff79";
            break;

          case "H":
            nodes[i].color = "#d5f6fb";
            break;

          case "Q":
            nodes[i].color = "#f9c3e3";
            break;

          case "Y":
            nodes[i].color = "#7eafb9";
            break;

          case "T":
            nodes[i].color = "#f0e4ef";
            break;

          case "V":
            nodes[i].color = "#feffab";
            break;

          case "M":
            nodes[i].color = "#c3ed26";
            break;

          case "W":
            nodes[i].color = "#85b0cd";
            break;

          case "A":
            nodes[i].color = "#feffc9";
            break;
        }
        
        delete nodes[i]['node_name'];
        nodes[i] = { data : nodes[i]};
      }
      for(var i = 0; i < edges.length; i++){
        edges[i]['source'] = edges[i]['node_1'];
        delete edges[i]['node 1'];
        edges[i]['target'] = edges[i]['node_2'];
        edges[i]['width'] = Math.log2(edges[i].count)+1;
        delete edges[i]['node 2'];

        edges[i] = { data : edges[i], classes: 'tooltip'};
      }

      this.ELEMENT_DATA = {

        style: [
          {
            selector: 'node[type != "protein"]',
            css: {
              content: 'data(name)',
              // 'border-color': 'data(color)',
              'border-opacity': 1,
              'border-width': '1px',
              'background-color':'data(color)',
            },
          },
          {
            selector: 'node[type = "protein"]',
            css: {
              content: 'data(nameScore)',
              "text-wrap":"wrap",
              "text-valign":"center",
              'border-color': '#33cc33',
              'border-opacity': 1,
              'border-width': '1px',
              'shape':'triangle',
              'background-color':'#33cc33',
              'color':"black"
            },
          },
          {
            selector: 'edge',
            css: {
              content: 'data(count)',
              'target-arrow-shape': 'triangle',
              'line-color': '#666666',
              'color':'#000000',
              'width': 'data(width)',
            },
          },
        ],

        elements: {
          nodes: nodes,
          edges: edges,
        },
      };
    }
    
  }

}
