<mat-card class="container-card">
  <mat-card class="background-card">

    <div style="padding-bottom: 1%;">
      <mat-card>
        <h1 id="title">Protein Conservation Region Search </h1>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <h2 id="exptitle">Request Form</h2>
        <p id="exptitle">Enter multiple protein identifiers</p>
        <form>
          <mat-form-field appearance="outline" style="margin-left: 10px;" style="width: 100%; height: auto;">
            <mat-label>Input the Sequence Name</mat-label>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable" [removable]="removable"
                (removed)="remove(fruit)">
                {{fruit.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input placeholder="New Sequence..." [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)">

            </mat-chip-list>
          </mat-form-field>
          <button mat-button  class="button_search" (click)="pullSequenceData();">
            Search
          </button>
          <span style="color:grey; margin-left: 10px;">{{result}}</span>
        </form>

      </mat-card>
    </div>

    <div>
      <mat-card *ngif="!SequenceData">
        <h1>Loading Data...</h1>
      </mat-card>
      <mat-card *ngIf="SequenceData">
        <h2>Sequence Table </h2>
        <p>Choose below dropdown item to hide selected sequence rows. Multiple selection available</p>
        <mat-form-field appearance="outline" style="width: 30%; height: auto;">
          <mat-label>Hidden Sequence Name</mat-label>
          <mat-select [formControl]="toppings" multiple>
            <mat-select-trigger>
              {{toppings.value ? toppings.value[0] : ''}}
              <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
          </mat-select>
        </mat-form-field>
        <h2> Selected Sequence</h2>
        <div class="selected-sequence-box">
          <div class="selected-sequence-content">
            {{selectedSequence}}
          </div>
        </div>




        <!-- Add this block of code right above the <table mat-table> element -->
<div class="submit-button-container">
  <button mat-raised-button color="primary" (click)="submitSelectedText()">Submit Selected Text</button>
  <button mat-raised-button color="primary" (click)="showProteinLogo()">Show Protein Logo</button>
</div>

        <table mat-table [dataSource]="SequenceData.seqtable" multiTemplateDataRows>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>NAME</th>
            <td mat-cell *matCellDef="let protein"> {{protein.name}} </td>
          </ng-container>

          <ng-container matColumnDef="newseq">
            <th mat-header-cell *matHeaderCellDef>SEQUENCE</th>
            <td mat-cell *matCellDef="let protein">
              <div [innerHtml]="protein.newseq" (mouseup)="onTextSelected()"></div>
            </td>
          </ng-container>

          <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef>INDEX</th>
            <td mat-cell *matCellDef="let protein">{{ protein.index }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="nrColumns "></tr>
          <tr mat-row *matRowDef="let element; columns: nrColumns; " class="mat-column-sequDetail"
            [hidden]="trControl(toppings.value, element, SequenceData.group_number)">
          </tr>
        </table>
      </mat-card>
    </div>


  </mat-card>
</mat-card>
