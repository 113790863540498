<div id="carousel-wrapper">
<carousel
    cellWidth="100%"
    height="300"
    loop="true"
    autoplay="true"
    autoplayInterval="4000"
>
    <div class="carousel-cell">
        <img class="carousel-image" src="assets/images/a.jpg">
        <div class="carousel-text">
            <strong>In the NEW P<sup>3</sup>DB! you can:</strong><br><br>
                <li>Spend minutes to create a Secure Website for paper publication</li>
                <li>Share your data among your own groups</li>
                <li>Search for more Kinase Specific Data</li> 
                <li>Browse data using Network Tools</li> 
                <li>Get More Detailed Annotation for proteins and sites</li>
                <li>Leave data specific comments and get reply</li>
               <li>Have your VIP user account center</li>
                <mat-grid-list cols="2" rowHeight="2:1">
                    <mat-grid-tile>Test</mat-grid-tile>
                    <mat-grid-tile>2</mat-grid-tile>
                    <mat-grid-tile>3</mat-grid-tile>
                    <mat-grid-tile>4</mat-grid-tile>
                  </mat-grid-list>
        </div>
    </div>
    <div class="carousel-cell">
        <img class="carousel-image" src="assets/images/b.jpg">
        <div class="carousel-text">
            <strong>KiC Assay (Kinase-Client Assay)</strong><br><br>
            <li>Kinase-Client (KiC) assay is a MS-based method developed by Thelen Group for identifying kinase-client interactions and quantifying kinase activity.</li><br>
            <li>Click <a href="kicassay.php" target="_blank">here</a> for detailed protocols and papers.</li>
        </div>
    </div>
    <div class="carousel-cell">
        <img class="carousel-image" src="assets/images/c.jpg">
        <div class="carousel-text">
            <strong>Automatic Publication System</strong><br><br>
            <li>Do you want to generate the websites for your own data? Do you want to get the link for your paper publication instantly? Do you want to share your data among groups or just keep it private until published?</li>
            <li>Sign up for an account </li>
        </div>
    </div>
    <div class="carousel-cell">
        <img class="carousel-image" src="assets/images/d.jpg">
        <div class="carousel-text">
                In the NEW P<sup>3</sup>DB! you can:<br><br>
                <li>Spend minutes to create a Secure Website for paper publication</li>
                <li>Share your data among your own groups</li>
                <li>Search for more Kinase Specific Data</li>
                <li>Browse data using Network Tools</li>
                <li>Get More Detailed Annotation for proteins and sites</li>
                <li>Leave data specific comments and get reply</li>
                <li>Have your VIP user account center</li>
        </div>
    </div>
    
      
</carousel>
</div>

    