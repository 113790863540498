<mat-card class="card">
  <div id="cy" cy (click)="updateSelected()" style="width:71%; background-color:#eef2f5; display: inline-block;">
  </div>
  <mat-card style="width:25%;height: 60vh;padding: 10px ;margin-left:2% ;display: inline-block; overflow-y: scroll;">
    <!-- <div class='detail' id="detail"
      style="width:25%;height: 60vh;padding: 10px ;background-color:#eef2f5; margin-left:2% ;display: inline-block;"> -->
      <mat-card-title style="margin: 10px;">
        Information: 
      </mat-card-title>
      <mat-card-subtitle style="margin: 10px; margin-bottom: 20px;">(May need to scroll)</mat-card-subtitle>
      <mat-card-content>
        <mat-spinner *ngIf="!cytoData" style="margin: 60px auto"></mat-spinner>
        <mat-card *ngIf="selectedDetails.length < 1 && this.cytoData && this.cytoData.elements.nodes[0]" class="noBorder" >
          <mat-card-content>
            <p style="text-align: center;">No Element Selected</p>
          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="this.cytoData && !this.cytoData.elements.nodes[0]" class="noBorder">
          <mat-card-content>
            <p style="text-align: center;">Could not find PTM data for that query.</p>
          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="selectedDetails && selectedDetails.length > 0" style="margin-bottom: 20px" class="noBorder">
          <div id="detail_info">
            <div *ngFor="let item of selectedDetails">
              <div *ngIf="item.title != 'Experiment Data'" >
                <!-- {{item}} -->
                <span class="detailTitle">{{item.title}}</span> 
                <br />
                <span *ngIf="item.title == 'Pubmed'; else otherCondition " style="word-wrap: break-word; margin-left: 6px;"><a [href]="'https://pubmed.ncbi.nlm.nih.gov/' + item.detail"> {{item.detail}}</a></span> 
                <ng-template #otherCondition>
                  <span style="word-wrap: break-word; margin-left: 6px;">{{item.detail}}</span>
                </ng-template>
                <br /><br />
              </div>
            </div>
          </div>
        </mat-card>
        <div *ngFor="let item of selectedDetails">
          <mat-card-title style="margin: 10px; margin-bottom: 20px;" *ngIf="item.title == 'Experiment Data'">Experiment Data </mat-card-title>
          <mat-card *ngIf="item.title == 'Experiment Data'" class="noBorder" >
            <mat-card-content>
                <div *ngFor="let expData of item.detail | keyvalue">
                  <span class="detailTitle">
                    {{expData.key}} <br>
                  </span>
                  <span style="word-wrap: break-word; margin-left: 6px;">
                    {{expData.value}}
                  </span>
                  <br />
                </div>
            </mat-card-content>
          </mat-card>
        </div>  
      <!-- </div> -->
      </mat-card-content>
    </mat-card>
  <div *ngIf='isPtmViewer'>
    <img src="assets/images/colorScheme.png" style="height:20vh;margin-top: 2vh;">
    <p>Reference: https://forum.ugene.net/forum/YaBB.pl?num=1337064665</p>
  </div>
</mat-card>

<mat-expansion-panel class="card">

  <mat-expansion-panel-header>
    <mat-panel-title id="exptitle">
      Graph Options
    </mat-panel-title>
    <mat-panel-description>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div id="active-filters-container">
    <div *ngFor="let filt of filters" class="filter">
      {{ filt.filter }}
      <span class="material-icons cancel-x" (click)="removeFilter(filt)">
        close
      </span>
    </div>
    <div *ngFor="let filt of styles| slice:2" class="filter">
      {{ filt['selector'] }} Style
      <span class="material-icons cancel-x" (click)="removeStyle(filt)">
        close
      </span>
    </div>
  </div>

  <h3> Add a filter: </h3>
  <div id="filter-container">
    <form [formGroup]="filterForm">
      <mat-form-field appearance="fill" class="filter-field">
        <mat-label>Filter Type</mat-label>
        <mat-select formControlName="filterType">
          <mat-option [value]="'node'"> Node </mat-option>
          <mat-option [value]="'edge'"> Edge </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-field" *ngIf="filterForm.get('filterType').value === 'node'">
        <mat-label>Property</mat-label>
        <mat-select matInput formControlName="filterProp">
          <mat-option *ngFor="let key of nodeKeys" [value]="key">{{key}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-field" *ngIf="filterForm.get('filterType').value === 'edge'">
        <mat-label>Property</mat-label>
        <mat-select matInput formControlName="filterProp">
          <mat-option *ngFor="let key of edgeKeys" [value]="key">{{key}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-field">
        <mat-label>Operator</mat-label>
        <mat-select matInput formControlName="filterOperator">
          <mat-option value='='>Equals</mat-option>
          <mat-option value='<'>Less Than</mat-option>
          <mat-option value='<='>Less Than or Equal</mat-option>
          <mat-option value='>'>Greater Than</mat-option>
          <mat-option value='>='>Greater Than or Equal</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-field">
        <mat-label>Value</mat-label>
        <textarea matInput formControlName="filterValue"></textarea>
        <mat-error *ngIf="filterForm.get('filterValue').hasError('alphaNumericError')">Alphanumeric characters and spaces only.</mat-error>
      </mat-form-field>

      <button mat-flat-button color="primary" (click)="addFilter(); showSelected = false" [disabled]="!filterForm.valid"> Add Filter </button>
      <!-- <mat-slide-toggle formControlName="filterAndOr">AND/OR</mat-slide-toggle> -->
    </form>
  </div>

  <h3> Add a style: </h3>
  <div id="style-container">
    <form [formGroup]="styleForm">
      <mat-form-field appearance="fill" class="style-field">
        <mat-label>Selector Type</mat-label>
        <mat-select formControlName="selectorType">
          <mat-option [value]="'node'"> Node </mat-option>
          <mat-option [value]="'edge'"> Edge </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="style-field">
        <mat-label>Selector</mat-label>
        <textarea matInput placeholder="Ex. group > 1..." formControlName="selectorValue"></textarea>
      </mat-form-field>

      <div class="style-spacer"></div>

      <mat-form-field class="style-field">
        <mat-label>Style Field</mat-label>
        <textarea matInput placeholder="Ex. background-color, line-color" formControlName="styleType"></textarea>
      </mat-form-field>

      <mat-form-field class="style-field">
        <mat-label>Style Value</mat-label>
        <textarea matInput placeholder="Ex. red, triangle..." formControlName="styleValue"></textarea>
      </mat-form-field>

      <button mat-flat-button color="primary" (click)="addStyle();"> Add Style </button>
    </form>
  </div>


  <h3> Other Options: </h3>
  <div id="options-container">
    <mat-form-field appearance="fill">
      <mat-label>Graph Layout</mat-label>
      <mat-select (selectionChange)="changeLayout($event.value)">
        <mat-option *ngFor="let layout of layouts" [value]="layout.value">
          {{layout.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--
        <div id="toggle-container">
            <mat-slide-toggle>Show Edge Label</mat-slide-toggle>
            <mat-slide-toggle>Show Node Label</mat-slide-toggle>
        </div>
        -->

  </div>

</mat-expansion-panel>

<!-- <mat-expansion-panel class="card">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Selected Element Info
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <app-table [tableData]="selected" *ngIf="showSelected"></app-table>
</mat-expansion-panel> -->
