<mat-card id="component-container">
  <mat-card>
    <h2 id="title">Pathway Uploader</h2>
  </mat-card>

  <mat-card class='upload_card' style="text-align: center;">
    <label id="drop_zone" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (change)="onChangeEvent($event)">
      <img id="preview_image" src={{preview_image_url}} style="width:25%; display: block;margin: 0 auto;">
      <span>Drag or click to upload figure...</span>
      <input type="file" accept="image/*" style="display:none" />
    </label>
    <button mat-flat-button color="primary" class="button_predict" (click)="predict()">
      Predict
    </button>
  </mat-card>


</mat-card>
