<br>
<div>
    <mat-card class="container-card" style="padding-bottom: 2.5%;">
        <mat-card class="background-card">
            <div  style="padding-bottom: 10px;">
                <mat-card> <h1 id = "title"> Phosphoprotein Data</h1></mat-card>
            </div>
    <table mat-table
       [dataSource]="dataSource" multiTemplateDataRows>
       <!-- class="mat-elevation-z8"> -->
        <ng-container matColumnDef="protein">
            <th mat-header-cell *matHeaderCellDef> <h1> Protein </h1> </th>
            <td mat-cell *matCellDef="let protein"> 
                {{ protein.protein_description.annotation.split(';')[0] }} 
                <button *ngIf = "protein.protein_description.annotation.split(';').length > 1" 
                    mat-icon-button matSuffix
                    (click) = "determineExpandedSequence(protein, 1)">
                    <mat-icon style="font-size: 25px;">arrow_drop_down</mat-icon>
                </button>
            </td>
            
        </ng-container>

        <ng-container matColumnDef="organism">
            <th mat-header-cell *matHeaderCellDef> <h1> Organism </h1> </th>
            <td mat-cell *matCellDef="let protein"> {{ protein.name }} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> <h1> Description </h1> </th>
            <td mat-cell *matCellDef="let protein"> 
                <button mat-raised-button class="button-color"
                (click)="determineExpandedSequence(protein, 2)">
                    Protein Description  
                    <mat-icon style="font-size: 25px;">arrow_drop_down</mat-icon>
                </button> 
            </td>
        </ng-container>

        <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef> <h1> Detail </h1> </th>
            <td mat-cell *matCellDef="let protein"> 
                <button mat-raised-button class="button-color"
                [routerLink] = "['/protein-data/',  protein.protein_id]">
                    Details
                    <!-- <mat-icon style="font-size: 25px;">arrow_drop_down</mat-icon> -->
                </button></td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let protein" [attr.colspan]="phosphoColumns.length">
            <div class="example-element-detail"
                [@detailExpand]="protein == expandedProtein ? 'expanded' : 'collapsed'">

                <div class="example-element-description">

                    <div *ngIf = "displaySeq == 1 ">
                        <!-- <h4>DNA Sequence</h4> <button mat-raised-button cdkCopyToClipboard="{{uniquegene.dna_seq}}">Copy to clipboard</button> <br>
                        <br> -->
                        {{ protein.protein_description.annotation }}
                    </div>
                    <div *ngIf = "displaySeq == 2 ">
                        <!-- <h4>Protein Sequence</h4> <button mat-raised-button cdkCopyToClipboard="{{uniquegene.protein_seq}}">Copy to clipboard</button> <br>
                        <br>
                        {{ uniquegene.protein_seq }} -->
                        <table mat-table [dataSource] = "protein.protein_description.accessions" multiTemplateDataRows>

                            <!-- column defs -->
                            <ng-container matColumnDef = "Name">
                                <th mat-header-cell *matHeaderCellDef>ID</th>
                                <td mat-cell *matCellDef="let ref"> {{ ref.name }} </td>
                            </ng-container>
        

        
                            <!-- print headers -->
                            <tr mat-header-row *matHeaderRowDef='["Name"]'></tr>
        
                            <!-- print data in rows. clicking an element allows to traverse
                            down the tree -->
                            <tr mat-row *matRowDef="let row; columns: ['Name'];" 
                                class="example-element-row"></tr>
                        </table>
                    </div>

                </div>
            </div>
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef = "" -->

        <tr mat-header-row *matHeaderRowDef="phosphoColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: phosphoColumns;"
            class="example-element-row"
            [class.example-expanded-row]="expandedProtein === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    </mat-card>
    </mat-card>
</div>
<!-- <br> -->