<mat-card id="component-container">
    <mat-card>
      <h2 id="title">PTM Peptides</h2>
    </mat-card>
    <mat-card style="margin-top: 10px">
      <div>
        <nz-input-group nzSearch nzSize="large"  [nzAddOnAfter]="suffixButton">
          <input type="text" [(ngModel)]="inputValue" nz-input placeholder="input search text" style="width:1180px"/>
        </nz-input-group>
        <ng-template #suffixButton>
          <button nz-button nzType="primary" nzSize="large" nzSearch (click)="fn_search()" >Search</button>
        </ng-template>
      </div>
      <br>
    </mat-card>
    <mat-card *ngIf="showWait" id="title-container" style="margin-top: 10px; overflow-x: scroll;text-align: center;">
      <nz-spin *ngIf="showWait" nzSimple [nzSize]="'large'"></nz-spin>
    </mat-card>

    <mat-card *ngIf="listOfBlastTabularResults.length" style="margin-top: 10px; overflow-x: scroll;">
        <h3 id="title">Protein Blast Search</h3>
    </mat-card>
    <mat-card *ngIf="listOfBlastTabularResults.length" id="title-container" style="overflow-x: scroll;">
        <p3dbTable [columnKeys]="columnKeysProteinBlast" [dataSourceShow]="listOfBlastTabularResults" [searchIndex]="searchIndex"
         (handleCellClick)="handleCellClick($event)"></p3dbTable>
    </mat-card>

    <mat-card *ngIf="phosphorylation_sites.length" style="margin-top: 10px; overflow-x: scroll;">
        <h3 id="title">Phosphorylation Site in Protein</h3>
      </mat-card>
    
      <mat-card *ngIf="phosphorylation_sites.length" id="title-container">
        <div class="scrooll" *ngFor="let phosphorylation_sites of phosphorylation_sites;let i = index">
          <h4 style="color:#7eb742"># Protein:{{proteinId[i]}}</h4>
          <div>
            <div style="width: 80px; display: inline-block;text-align: center;color: #548Ddf;">
              <p>Sequence</p>
            </div>
            <span *ngFor="let item of phosphorylation_sites;let i = index" [ngClass]="fn_seqHighlight(item)"
              title="{{phosphorylation_sites[i].tooptip}}">
              <a *ngIf="item.class== 'highlight'; else elseClass" data-toggle="tooltip" data-placement="top" 
              title="value will be printed" (click)="getData(i,item.protien_id)">{{item.name}}</a>
              <ng-template #elseClass>
                {{item.name}}
              </ng-template>
            </span>
          </div>
          <div>
            <div style="width: 80px; display: inline-block;text-align: center;">
              <p>|</p>
            </div>
            <span *ngFor="let item of phosphorylation_sites" [ngClass]="{'seq_highlight01':'true'}">{{item.arrow}}</span>
          </div>
          <div>
            <div style="width: 80px;display: inline-block;text-align: center;color: #548Ddf;">
              <p>Position:</p>
            </div>
            <span *ngFor="let item of phosphorylation_sites" [ngClass]="{'seq_highlight01':'true'}">{{item.index}}</span>
          </div>
        </div>
      </mat-card>
      <script>
        $(document).ready(function(){
          $('[data-toggle="tooltip"]').tooltip();   
        });
        </script>
</mat-card>

<br><br>
<!-- <mat-card id="message-container">
    <mat-card id="title-container">
      <h1 id="title" style="text-align: center;"><b>Shoot a Question</b></h1>
    </mat-card>
    <mat-card id="title-container" style="margin-top: 10px;">
      <div class='input_protein'>
        <nz-input-group nzSearch nzSize="large">
            <input type="text" nz-input placeholder="input search text" (handleEvent)="fn_search($event)"/>
        </nz-input-group>
      </div>
      <br/>
      <br/>
    </mat-card>
    <mat-card id="title-container" style="margin-top: 10px;">
      <h2>Answer is:</h2>
      <div class="information" style="overflow-x:scroll; word-wrap: break-word; white-space: pre-wrap;">hehe</div>
    </mat-card>
  </mat-card> -->