import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  name: string = '';
  user_email: string = '';
  subject: string = '';
  message: string = '';
  email_valid: boolean = true;
  form_filled: boolean = true;
  submitted: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  isEmailValid(): boolean {
    const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.email_valid = emailRegex.test(this.user_email);
    return this.email_valid;
  }

  async waitOneSecond() {
    return new Promise(resolve => setTimeout(resolve, 1000));
  }

  async submit_form(): Promise<void> {
    this.isEmailValid();
    if (this.email_valid == false || this.name == '' || this.subject == '' || this.message == ''){
      if (this.user_email == '' || this.name == '' || this.subject == '' || this.message == '') {
        this.form_filled = false;
      } else {
        this.form_filled = true;
      }
    } else {
      await this.waitOneSecond();
      this.name = '';
      this.user_email = '';
      this.subject = '';
      this.message = '';
      this.email_valid = true;
      this.form_filled = true;
      this.submitted = true;
      window.location.href = '#'; 

    }
  }

}
