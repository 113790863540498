import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-p3db-cascader-input-search',
  templateUrl: './p3db-cascader-input-search.component.html',
  styleUrls: ['./p3db-cascader-input-search.component.css']
})
export class P3dbCascaderInputSearchComponent implements OnInit {

  constructor() { }
  public inputValue='';
  public currentLabel=[]
  public defaultValue = ''
  @Input()width='250px'
  @Input()labels: Array<any>; // format [{value:1, label:'test'},{value:2, label:'test2'}]
  @Output() handleEvent = new EventEmitter();
  inputWidth:String;
  ngOnInit(): void {
    this.currentLabel = this.labels[0].value
    this.inputValue = this.labels[0].defaultValue
    this.inputWidth='100%';
  }
  handle(){
    // console.log("the input label is : "+ this.inputValue+" and the current label is : "+this.currentLabel)
    this.handleEvent.emit({value:this.inputValue,label:this.currentLabel.toString()})
  }

  onLabelChange() {
    // console.log("the current label is :" + this.currentLabel)
    if(this.currentLabel[0]=="1"){
      console.log("the labels are : ",this.labels," and the current label is : ",this.currentLabel);
      this.inputValue=this.labels[0].defaultValue
    }else if(this.currentLabel[0]=='2'){
      console.log("the labels are : ",this.labels," and the current label is : ",this.currentLabel);
      if(this.currentLabel[1]=='1'){
        this.inputValue =this.labels[1].children[0].defaultValue
      }else if(this.currentLabel[1]=='2'){
        this.inputValue =this.labels[1].children[1].defaultValue
      }else if(this.currentLabel[1]=='3'){
        this.inputValue=this.labels[1].children[2].defaultValue
      }else if(this.currentLabel[1]=='4'){
        this.inputValue=this.labels[1].children[3].defaultValue
      }else {
        this.inputValue = this.labels[1].children[4].defaultValue
      }
    }else if(this.currentLabel[0]=='3'){
      this.inputValue = this.labels[2].defaultValue;
    }
    // if (this.currentLabel[0]=='4'){
    //   this.inputWidth='100%';
    // }else{
    //   this.inputWidth='100%'
    // }
  }
}
