import { CdkHeaderRowDef } from '@angular/cdk/table';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from 'src/app/shared.service';
import { environment } from 'src/environments/environment';
import { NgZone } from '@angular/core';
declare var logojs:any;

@Component({
  selector: 'app-protein-logo',
  templateUrl: './protein-logo.component.html',
  styleUrls: ['./protein-logo.component.scss']
})
export class ProteinLogoComponent implements OnInit {

  constructor(private http: HttpClient, private sharedService: SharedService,private ngZone: NgZone) { }
  ELEMENT_DATA;
  requestError: string;
  PROTEIN_PPM = [];
  Motif_files_list = [];
  Motiffiles = new FormControl();
  errorMessage: boolean = false;
  organisim_sequence : string = "";
  showLogo:boolean = false;

  ngOnInit(): void {
    this.sharedService.selectedText$.subscribe((selectedText: string) => {
      this.organisim_sequence = selectedText;
      //this.getData();
    });

  }
  transpose(matrix: number[][]): number[][] {
    return matrix[0].map((_, i) => matrix.map(row => row[i]));
  }

  getData() {

      let formData = { sequences: this.organisim_sequence.split(',') };
      console.log(JSON.stringify(formData));
      const headers = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*');
      // forkJoin([
      //   this.postApi(environment.BASE_API_URL + 'getfastapwm/',JSON.stringify(formData) ),
      // ])
      // console.log(environment.BASE_API_URL + 'getfastapwm/', JSON.stringify(formData));
      this.http
        .post<any>(environment.BASE_API_URL + 'getfastapwm/', JSON.stringify(formData), {
          headers: headers,
        }).subscribe((result) => {
          console.log(result);
        let res: any = Object.values(result['data']);
    console.log(res)
        this.PROTEIN_PPM = result['data'];
        if(res.length == 0){
          this.errorMessage = true;
          var elem = document.getElementById("logo");
          if(elem!=undefined){
          elem.innerHTML = "";
          }
        }else{
          let alphabets = Array.from({length: 26}, (_, i) => String.fromCharCode(65 + i));
          let prot_ppm = {}

          alphabets.forEach(a => {

            if(this.PROTEIN_PPM[a] == undefined){
              if(a!='O' && a!='J' && a!='U' && a!='X'){
              prot_ppm[a] =  new Array(res[0].length).fill(0.0);
              }
            }
            else{
              prot_ppm[a] = this.PROTEIN_PPM[a]
            }
          });
          console.log(prot_ppm);
          this.ngZone.run(() => {
          this.errorMessage = false;
          this.PROTEIN_PPM = this.transpose(Object.values(prot_ppm));
          console.log(this.PROTEIN_PPM);
          this.showLogo = true;
          setTimeout(() => {
            logojs.embedProteinLogo(document.getElementById("logo"), { ppm: this.PROTEIN_PPM });
        }, 0);

         // logojs.embedProteinLogo(document.getElementById("logo"), { ppm: this.PROTEIN_PPM });
        });
        }


      }, error => {
        console.log(error);
        //this.requestError = error.statusText;
      });
    }

  // postApi(filename, body) {
  //   let headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   const options: {
  //     headers?: HttpHeaders;
  //     observe?: 'body';
  //     params?: HttpParams;
  //     reportProgress?: boolean;
  //     responseType: 'json';
  //     withCredentials?: boolean;
  //   } = {
  //     headers: headers,
  //     responseType: 'json',
  //   };

  //   return this.http.post(filename, body, options).pipe(
  //     map((file) => {
  //       return file;
  //     })
  //   );
  // }

}
