<!-- <app-toolbar></app-toolbar> -->

<!-- <mat-card class="container-card"> -->
    <!-- <mat-card class="test"></mat-card> -->
    <!-- <mat-card class="background-card"> -->

        <div class="search-div">
            
            <mat-card class="background-card">
                <mat-card id="title-container">
                    <h1 id="title" style="text-align: center;"><b>Organism Search</b></h1>
                  </mat-card>
            <mat-card class="searchcard">
        
                <mat-card-content>
                    <div class = "input-box">
                        <app-p3db-cascader-input-search id="inputText" [labels]="searchLabels" style="font-size: 20px;" (handleEvent)="fn_search($event)"></app-p3db-cascader-input-search>
                    </div>
                    <!-- <form>
                        <mat-form-field style="width: 100%; height: auto;">
                            <input matInput type="search" [(ngModel)] = "organismId" placeholder="Organism ID" name="searchField" style="font-size: 20px;">
                            <button mat-icon-button matSuffix (click)="pullSpecimenData();">
                                <mat-icon style="font-size: 25px;">search</mat-icon>
                            </button>
                        </mat-form-field>
                    </form> -->
                    <br>
                    <p *ngIf="organismError">{{organismError}}.</p>
                    <div  *ngIf="searchingOrganism" >
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>

                </mat-card-content>
            </mat-card>
        
        <!-- </div> -->
        
        <!-- I would like this dive to contain the data pulled by the search feature -->
          <!-- Specimen information card -->
          
          <!-- <mat-card *ngIf = "specimen" class="background-card2"> -->
          <div *ngIf = "specimen"  fxLayout class="subdiv">
            <!-- <mat-card *ngIf = "specimen" class="background-card2"> -->
            <mat-card class = "TaxonomyDetail-card">
                <div *ngIf = "organismDetails">
                    <mat-card-header>
                        <h1><strong> Organism : </strong> {{ specimen.current.name }}</h1>
                        <!-- <br> -->
                        <!-- <h3><strong> ID: {{ specimen.current.id }}</strong></h3> -->
                    </mat-card-header>
                    
                    <table class="tabledetails" mat-table [dataSource]="organismDetails" class="mat-elevation-z8">
                        <ng-container matColumnDef="Column">
                            <!-- <th mat-header-cell *matHeaderCellDef> {{item.column1}} </th> -->
                            <td mat-cell *matCellDef="let element"> {{element.column1}} </td>
                          </ng-container>
        
                          <ng-container matColumnDef="Value">
                            <!-- <th mat-header-cell *matHeaderCellDef> {{item.column2}} </th> -->
                            <td mat-cell *matCellDef="let element"> {{element.column2}} </td>
                          </ng-container>
        
                           <!-- <tr mat-header-row *matHeaderRowDef="verticalColumnNames"></tr> -->
                            <tr mat-row *matRowDef="let row; columns: verticalColumnNames;"></tr>
                    </table>
                </div>
            </mat-card>
        <!-- </mat-card> -->
        </div>
        
        <div *ngIf = "specimen"  fxLayout class="subdiv">
        
            <!-- Taxonomy Tree node Information -->
        
            <mat-card class = "TaxonomyTree-card">
                <div *ngIf = "organismTree">
                    <mat-tree [dataSource]="organismTree" [treeControl]="treeControl" class="matTree" style="width: 100%;">
                        <!-- This is the tree node for leaf nodes -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                            <a mat-button (click)="search(node.id)">{{node.name}}, {{node.id}}, {{node.rank}} </a>
                        </mat-tree-node>
                        <!-- This is the tree node for expandable nodes -->
                        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                            <div class="mat-tree-node">
                              <button mat-icon-button matTreeNodeToggle
                                      [attr.aria-label]="'Toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                              </button>
                              <a mat-button (click)="search(node.id)">{{node.name}}, {{node.id}}, {{node.rank}} </a>
                            </div>
                            
                            <div [class.matTree-invisible]="!treeControl.isExpanded(node)"
                                role="group">
                              <ng-container matTreeNodeOutlet></ng-container>
                          </div>
                        </mat-nested-tree-node>
                      </mat-tree>
                </div>
            </mat-card>
        
        
        </div>
        </mat-card>
        
        </div>
        
         <br><br><br><br>
        
        