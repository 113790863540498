import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teampage',
  templateUrl: './teampage.component.html',
  styleUrls: ['./teampage.component.css']
})
export class TeampageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
