import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const baseURL = environment.BASE_API_URL;

const collections = {
  gene : { 
    collection : "genes",
    arguments : [ "subgroup_name" ]
  },
  protein_data : {
    collection : "protein",
    arguments : ["id", "ref"]
  },
  phosphosite: {
    collection : "phosphosite",
    arguments : ["phos_id", "ref"]
  },
  peptide : {
    collection : "peptide",
    arguments : ["pnrseq", "pro", "loc"]
  },
  groups: {
    collection : "groups",
    arguments : []
  },
  organism : {
    collection : "taxonomy",
    arguments : ["id"]
  },
  organisms : {
    collection : "ptmtaxonomy",
    arguments : ["types", "value"]
  },
  browse : {
    collection : "browse",
    arguments : ["start", "end"]
  },
  subgroup : {
    collection : "subgroups",
    arguments : ["group_name"]
  },
  uniqueGene : {
    collection : "unique_gene",
    arguments : ["gene_name"]
  },
  kicnetworkEdge : { 
    collection :  'new_kic_edges',
    arguments : []
  },
  kicnetworkNode : {
    collection : 'new_kic_nodes',
    arguments : [] 
  },
  idMappingKeys: {
    collection : 'id_lookup',
    arguments: []
  },
  phylogeneticTree: {
    collection : 'species_list',
    arguments: []
  },
  blast: {
    collection : 'blastp',
    arguments: ['protein_sequence']
  }
};

@Injectable({
  providedIn: 'root'
})




export class DatabaseConnService {
  private headers: HttpHeaders

  constructor(private http: HttpClient) 
  { 

  }

  get(table: string, argv : Array<string>) : Observable<any>
  {

    this.headers = new HttpHeaders();
    this.headers.set('Content-Type', 'application/json');
    let query = baseURL + collections[table]['collection'] + "?";

    for (let i = 0; i < collections[table]['arguments'].length; ++i)
    {
      query += collections[table]['arguments'][i] + "=" + argv[i];
      if ((i + 1) < collections[table]['arguments'].length)
      {
        query +=  "&";
      }
      
    }
    return this.http.get<any[]>(`${query}`, {headers : this.headers});
  }

  getUniveral(arg: string, param: string, regex = false) : Observable<any>
  {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    // this.headers.set('x-api-key','8b4c7f8047ad1efde0bf2f60f6990207094e705017bff89e63baf72ffee74b76' )

    let query = baseURL + 'mapping?' + arg + '=' + param;
    
    query += (regex ? '&regex=True' : '');

    return this.http.get<any[]>(`${query}`);
  }

  getIPAddresses(): Observable<any> {
    let query = baseURL + 'get_ip_addresses';
    return this.http.get<any>(`${query}`);
  }
}
 