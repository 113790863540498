import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-networkviewer',
  templateUrl: './networkviewer.component.html',
  styleUrls: ['./networkviewer.component.css'],
})
export class NetworkviewerComponent implements OnInit {
  ELEMENT_DATA;
  showCyto = false;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.getData();
  }
  nav(route: String) {
    this.router.navigate([route]);
  }

  //handles the joining of the two ajax requests for the edge and node info
  //could be moved out of this component and into cytoscape-example
  getData() {
    //forkJoin combines the two observables from http requests and waits for both to finish
    forkJoin([
      this.fetchJson('./assets/edge_info.json'),
      this.fetchJson('./assets/node_info.json'),
    ]).subscribe((x) => {
      //map edge_info.json and node_info.json to proper cyto format
      this.mapEdgesNodes(x[0], x[1]);
    });
  }

  //takes filename and returns an observable of HTTP request to that file
  //can be moved outside of component
  fetchJson(filename) {
    let headers = new HttpHeaders();

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  //handles the logic specific to this data representation.
  //maps data from the format in the .json files to the format needed for this component
  //can be moved outside of component
  mapEdgesNodes(edges, nodes) {
    for (var i = 0; i < nodes.length; i++) {
      nodes[i]['id'] = nodes[i]['Gene Name'];
      delete nodes[i]['Gene Name'];

      nodes[i] = { data: nodes[i] };
    }
    for (var i = 0; i < edges.length; i++) {
      edges[i]['source'] = edges[i]['node 1'];
      delete edges[i]['node 1'];
      edges[i]['target'] = edges[i]['node 2'];
      delete edges[i]['node 2'];

      edges[i] = { data: edges[i], classes: 'tooltip' };
    }

    this.ELEMENT_DATA = {
      style: [
        {
          selector: 'node',
          css: {
            content: 'data(id)',
            'border-color': 'black',
            'border-opacity': 1,
            'border-width': '2px',
          },
        },
        {
          selector: 'edge',
          css: {
            'target-arrow-shape': 'triangle',
          },
        },
      ],

      elements: {
        nodes: nodes,
        edges: edges,
      },
    };
    this.showCyto = true;
    console.log(this.ELEMENT_DATA);
  }
}
