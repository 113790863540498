//by ziting using iframe it is a page in menu, just in route
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-show3dtable',
  templateUrl: './show3dtable.component.html',
  styleUrls: ['./show3dtable.component.css'],
})
export class Show3dtableComponent implements OnInit {
  constructor(
    private activatedroute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  videoUrl: SafeResourceUrl;
  SEQ;
  POSITION;

  ngOnInit(): void {
    // console.log("qqq", window.location)
    this.SetParam();
  }

  SetParam(): void {
    let { urlg2s } = this.activatedroute.snapshot.params;
    console.log("urlg2s : ",urlg2s);
    let url =
      'https://' +
      window.location.host +
      '/assets/show3dTable.html?url=' +
      urlg2s;
    console.log(url);
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
