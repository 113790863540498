import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cite',
  templateUrl: './cite.component.html',
  styleUrls: ['./cite.component.scss']
})
export class CiteComponent implements OnInit {
  images: string[] = [
    'assets/images/p3db2009.png',
    'assets/images/p3db2012.png',
    'assets/images/p3db2014.png',
    'assets/images/p3db2020.png'
  ];
  currentIndex;


  constructor() { }

  ngOnInit(): void {
    this.currentIndex = 0;
  }

  nextSlide(): void {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
  }

  prevSlide(): void {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
  }

}
