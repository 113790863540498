import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from './pages/search/search.component';
import { HomeComponent } from './pages/home/home.component';
import { NetworkviewerComponent } from './pages/networkviewer-example/networkviewer.component';

//by ziting
import { OrthologConservationComponent } from './pages/ortholog-conservation/ortholog-conservation.component';
import { NetworkComponent } from './pages/ptmnetwork/network.component';
import { MusiteComponent } from './pages/musite/musite.component';
import { CountSummaryComponent } from './pages/count-summary/count-summary.component';
import { Show3dtableComponent } from './pages/show3dtable/show3dtable.component';
import { Show3dComponent } from './pages/show3d/show3d.component';
import { PpiNetworkComponent } from './pages/ppi-network/ppi-network.component';
import { KicnetworkComponent } from './pages/kicnetwork/kicnetwork.component';
import { PathwayEntranceComponent } from './pages/pathway-entrance/pathway-entrance.component';

// Michael Fisher
import { PhosphoproteinBrowseComponent } from './pages/phosphoprotein-browse/phosphoproteinBrowse.component';
import { KinaseGenedataComponent } from './pages/kinase-genedata/kinase-genedata.component';
import { KinaseclientComponent } from './pages/kinaseclient/kinaseclient.component';
import { OrganismSearchComponent } from './pages/organism-search/organism-search.component';
import { ProteinDataComponent } from './pages/protein-data/protein-data.component';
import { PhosphorylationComponent } from './pages/phosphorylation/phosphorylation.component';
import { PeptideDataComponent } from './pages/peptide-data/peptide-data.component';
import { PhylogeneticTreeComponent } from './pages/phylogenetic-tree/phylogenetic-tree.component';
import { PhylogeneticTreeDatasetComponent } from './pages/phylogenetic-tree-dataset/phylogenetic-tree-dataset.component';
import { UniversalSearchComponent } from './pages/universal-search/universal-search.component';
import { PeptideConservationSearch } from './pages/Peptide-Conservation-Search/Peptide-Conservation-Search.component';

//zhangzhi
import { ProteinConservationRegionSearchComponent } from './pages/Protein-Conservation-Region-Search/Protein-Conservation-Region-Search.component';
import { PathwayViewerComponent } from './pages/pathway-viewer/pathway-viewer.component';

//haowang Liu
import { TeampageComponent } from './pages/teampage/teampage.component';
import { UsermanualComponent }from './pages/usermanual/usermanual.component';


import { ContactComponent } from './pages/contact/contact.component';
import { CiteComponent } from './pages/cite/cite.component';
import { PtmPeptidesComponent } from './pages/ptm-peptides/ptm-peptides.component';
import { BlastPtmPeptidesComponent } from './pages/blast-ptm-peptides/blast-ptm-peptides.component';
import {MessageComponent} from './pages/message/message.component';
import { ProtienBlastSearchComponent } from './pages/protien-blast-search/protien-blast-search.component';
import { ModalComponent } from './components/modal/modal.component';
import { CsvDisplayComponent } from './csv-display/csv-display.component';

import { P3dbTableComponent } from './components/p3db-table/p3db-table.component';

import { MotifComponent } from './pages/motif/motif.component';
import { ProteinLogoComponent } from './pages/protein-logo/protein-logo.component';
import { IntermediatePageComponent } from './pages/intermediate-page/intermediate-page.component';
import { BatchSearchComponent } from './pages/batch-search/batch-search.component';
import { PubmedIDComponent } from './pages/pubmed-id/pubmed-id.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'kinase', component: KinaseclientComponent },
  { path: 'csv-display', component: CsvDisplayComponent },
  // Haowang Liu
  { path: 'team', component:TeampageComponent},
  { path: 'usermanual',component:UsermanualComponent},
  { path: 'contact',component:ContactComponent},
  { path: 'cite',component:CiteComponent},


  // zhi zhang
  { path: 'ProteinConservationRegionSearch', component: ProteinConservationRegionSearchComponent },

  //ziting
  {path: 'blastPtmPeptides', component:BlastPtmPeptidesComponent},
  { path: 'ptmPeptides', component: PtmPeptidesComponent},
  { path: 'show3D', component: Show3dComponent },
  { path: 'show3dTable', component: Show3dtableComponent },
  { path: 'countSummary', component: CountSummaryComponent },
  { path: 'ptmPrediction', component: MusiteComponent },
  { path: 'PTMViewer', component: NetworkComponent },
  { path: 'othologConservation', component: OrthologConservationComponent },
  { path: 'ppiNetwork', component: PpiNetworkComponent },
  { path: 'kicnetwork', component: KicnetworkComponent },
  { path: 'pathwayEntrance', component: PathwayEntranceComponent},

  //micheal
  {
    path: 'kinase-genedata/:subgroup_name',
    component: KinaseGenedataComponent,
  },
  { path: 'organism-search', component: OrganismSearchComponent },
  { path: 'organism-search/:organismId', component: OrganismSearchComponent },
  { path: 'phosphoprotein-browse', component: PhosphoproteinBrowseComponent },
  {
    path: 'protein-data',
    redirectTo: '/phosphoprotein-browse',
    pathMatch: 'full',
  },
  { path: 'protein-data/:id', component: ProteinDataComponent },
  { path: 'phosphorylation-data/:id', component: PhosphorylationComponent },
  {
    path: 'peptide-data',
    redirectTo: '/phosphoproteinBrowse',
    pathMatch: 'full',
  },
  {
    path: 'peptide-data/:pnrseq/:pro/:loc/:pos',
    component: PeptideDataComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  { path: 'phylogenetic-tree', component: PhylogeneticTreeComponent },
  
  // { path: 'IDMapping', component: UniversalSearchComponent },
  { path: 'PeptideConservationSearch', component: PeptideConservationSearch},
  { path: 'phylogenetic-tree/:id',component: PhylogeneticTreeDatasetComponent},
  { path: 'UniversalSearch', component : UniversalSearchComponent},

    //zhanzghi

  // { path: 'sequence', component: ProteinConservationRegionSearchComponent},
  { path: 'pathway-viewer', component: PathwayViewerComponent},
  { path: 'message', component: MessageComponent },
  {path: 'proteinblastsearch', component: ProtienBlastSearchComponent},
  //Harsha
    { path: 'peptide', component: PeptideConservationSearch },
    { path: 'motif',component:MotifComponent},
    { path: 'ProteinLogo',component:ProteinLogoComponent},
    {path:'batchSearch', component: BatchSearchComponent},
    // {path: 'hello',component: IntermediatePageComponent},

    { path: 'pubmed-id/:pubmedID', component: PubmedIDComponent },
    { path: 'pubmed-id', component: PubmedIDComponent }, // Base route without parameter

    // put future paths above this one because otherwise it'll be overridden - also potentially the path because it currently accepts everything, make it more specific
    {path: ':id',component: IntermediatePageComponent},
    

 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
