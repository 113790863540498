import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { forkJoin, queueScheduler } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
 

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  messageContent:String;
  questionString:String;
  inputValue: String;
  
  constructor(private httpClient:HttpClient) {}
  
  searchLabels = [
    {label:"Normal Question", value:'1', isLeaf:true}
  ]

  ngOnInit(): void {

  }


  getMessageData(question){
    
    var sentence = question.value;
    var words = sentence.split(" ");
    var joinedSentence = words.join("%20");
    console.log(environment.BASE_API_URL+ "chatgpt/?content="+joinedSentence);
    forkJoin([
        this.fetchJson(
          environment.BASE_API_URL+ "chatgpt/?content="+joinedSentence
        ),
      ]).subscribe((result) => {
         this.messageContent = result[0]["data"]["choices"][0]["message"]["content"];
      })
    }

  fetchJson(file) {
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };
    return this.httpClient.get(file, options).pipe(
      map((file_name) => {
        return file_name;
      })
    );
  }

  fn_search(parameter) {
    const input = document.getElementById('question') as HTMLInputElement;
    const value = parameter
    this.getMessageData(value);
  }
}
