import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router, ActivatedRoute } from '@angular/router';
import { PageviewsService } from 'src/app/Services/pageviews.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  view_count: string = '';


  constructor(private router: Router) {

   }

   @ViewChild(MatMenuTrigger) browse: MatMenuTrigger;
   @ViewChild(MatMenuTrigger) tool: MatMenuTrigger;
   @ViewChild(MatMenuTrigger) data: MatMenuTrigger;
  ngOnInit(): void {

  }
  nav(route: String){
    this.router.navigate([route]);
  }
  onMouseBrowseEnter(){
    this.browse.openMenu();
  }
  onMouseBrowseLeave(){
    this.browse.closeMenu();
  }
  onMouseToolEnter(){
    this.tool.openMenu();
  }
  onMouseToolLeave(){
    this.tool.closeMenu();
  }
  onMouseDataEnter(){
    this.data.openMenu();
  }
  onMouseDataLeave(){
    this.data.closeMenu();
  }

//   private loadScript(scriptUrl: string): void {
//     const script = document.createElement('script');
//     script.src = scriptUrl;
//     script.async = true;
//     script.id = 'mapmyvisitors';
//     const referenceElement = document.getElementById('mapmyvisitors');
//       if (referenceElement) {
//       referenceElement.parentNode.insertBefore(script, referenceElement);
//     } else {
//       document.head.appendChild(script);
//     }  
// }

}
