import { Component, OnInit } from '@angular/core';
import { PathwayViewerService } from '../../Services/pathway-viewer.service';
import { ActivatedRoute, Router } from '@angular/router';
//import { elementAt } from 'rxjs/operators';
import { ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PathwayDialogComponent } from './pathway-dialog/pathway-dialog.component';
import { HttpClient } from '@angular/common/http';
import { O, P } from '@angular/cdk/keycodes';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-pathway-viewer',
  templateUrl: './pathway-viewer.component.html',
  styleUrls: ['./pathway-viewer.component.css']
})
export class PathwayViewerComponent implements OnInit {

  constructor(private pathwayService: PathwayViewerService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private http: HttpClient,
    private formBuilder: FormBuilder) { }

  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  
  ctx;
  canvasListener: any;
  clickRects: any;
  bm:ImageBitmap;
  hoveredRects = [];

  showingUniprot = false;
  selectedGraph = null;
  graphTable = null;
  img = null;
  loadingImage = false;
  imageError = "";

  filesObj = null;
  fileName = "No file selected";

  selectedComponent = null;
  loadingComponent = false;
  rawImagePath: string = null;

  displayedColumns = ["title", "fpLink", "uniProtLink", "geneName", "dataSet", "modified"];
  
  markError = "";
  loadingMarks = false;
  passwordForm = this.formBuilder.group({
    password: ['', Validators.required]
  });
  
  ngOnInit(): void {

  }

  onFileChanged(e) {
    this.filesObj = e.target.files;

    if (this.filesObj && this.filesObj[0]) {
      this.fileName = this.filesObj[0].name;
    }
  }

  uploadFile() {
    this.loadingImage = true;
    this.imageError = "";
    
    // store locally
    if (this.filesObj && this.filesObj[0]) {
      var imgReader = new FileReader();

      imgReader.readAsDataURL(this.filesObj[0]); // read file as data url

      imgReader.onload = (event) => { // called once readAsDataURL is completed
        
        let formData = new FormData();
        formData.append("image", this.filesObj[0]);

        this.http.post(this.pathwayService.BASE_API_URL + "prediction/", formData, {headers: {'Content-Type': "application/json"}}).subscribe((data:any) => {
          console.log(data);
          
          if (data != null && data.data != null) {
            this.selectedGraph = data.data.prediction.genes;
            this.rawImagePath = data.data.image_path;
            let nameArr = this.rawImagePath.split('/');
            let imgName = nameArr[nameArr.length - 1];

            this.renderImage(this.pathwayService.BASE_API_URL + "download/" + imgName);
          }
          else {
            this.imageError = "There was a problem generating data for that image. Please try again later...";
            this.loadingImage = false;
          }
        },
        err => {
          this.imageError = "Could not connect to the image analysis tool.";
          this.loadingImage = false;
        }); 
      }
    }
    else {
      this.imageError = "No image selected to upload."
      this.loadingImage = false;
    }
  }

  renderImage(imgSrcPath){
    var graphHash = {};
    this.graphTable = [];

    this.img = new Image();
    this.img.onload = () => this.drawMap();
    this.img.src = imgSrcPath;

    // remove img size element
    this.selectedGraph.shift();

    this.selectedGraph.forEach(element => {
      // check the dictionary, if its not there, then
          // we need to grab this for the table
          if (graphHash[element.gene_name] != 1) {
            // add it to the dictionary
            graphHash[element.gene_name] = 1;

            // this may not be initialized
            let timesMarkedForReview = 0;
            if (element.timesMarkedForReview != null)
              timesMarkedForReview = element.timesMarkedForReview;

            // later on, we'll use the id api to grab more info
            this.graphTable.push({
              gene_name : element.gene_name,
              coords : element.coordinates,
              timesMarkedForReview: timesMarkedForReview
            });
          }
    });
  }

  // this function sets up the behavior for the canvas once
  // the image has fully loaded
  drawMap() {
    this.canvas.nativeElement.width = this.img.width;
    this.canvas.nativeElement.height = this.img.height;

    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.clickRects = [];

    createImageBitmap(this.img,0,0,this.img.width,this.img.height).then(bm => {
      this.bm = bm;
      this.ctx.drawImage(this.bm,0,0);

      this.selectedGraph.forEach(graphEntry => {
        var points = graphEntry.coordinates;

        if (points.length == 4) {

          // we need width/height to draw on the canvas, lets calculate
          var rectWidth = points[2];
          var rectHeight = points[3];

          // adjust them for the rescaled canvas (only for pointer)
          var adjRectWidth = (rectWidth / this.img.width) * this.canvas.nativeElement.scrollWidth;
          var adjRectHeight = (rectHeight / this.img.height) * this.canvas.nativeElement.scrollHeight;

          var pointX = (points[0] / this.img.width) * this.canvas.nativeElement.scrollWidth;
          var pointY = (points[1] / this.img.height) * this.canvas.nativeElement.scrollHeight;

          // add new point to list and to the ctx (non-adjusted)
          var newPath = new Path2D();
          var newPicPath = new Path2D();

          newPicPath.rect(points[0], points[1], rectWidth, rectHeight);
          newPath.rect(pointX, pointY, adjRectWidth, adjRectHeight);

          //path = mouse detection path
          //picPath = path to draw the highlights
          this.clickRects.push(
            { 
              path: newPath,
              picPath: newPicPath,
              title: graphEntry.title,
              gene_name: graphEntry.gene_name,
              fpLink: graphEntry.fpLink,
              uniProtLink: graphEntry.uniProtLink,
              coords: {
                x:points[0], 
                y:points[1], 
                w:rectWidth, 
                h:rectHeight
              }
            });
        }
        this.loadingImage = false;
      });

      // now let's define the hover behavior for each of them
      this.canvasListener = this.renderer.listen(this.canvas.nativeElement, 'mousemove', (e) => {
        
        // clear the rectangle's we're hovering over
        this.hoveredRects = [];

        // clear the painted rects
        this.ctx.drawImage(this.bm,0,0);

        this.ctx.beginPath();

        this.clickRects.forEach((rect, i) => {
          // add all the rects under the mouse to the array
          if(this.ctx.isPointInPath(rect.path, e.offsetX, e.offsetY)) {
            this.hoveredRects.push(rect);
          }
        });
        
        // paint all the rects the mouse is touching
        this.hoveredRects.forEach(rect => {
          this.ctx.fillStyle = "rgba(219,112,147,0.3)";
          this.ctx.fill(rect.picPath);
        });
      });

      // now let's define the click behavior for each of them
      this.canvasListener = this.renderer.listen(this.canvas.nativeElement, 'click', (e) => {
        var hasOpenedDialog = false;
        
        this.clickRects.forEach(rect => {
          if (this.ctx.isPointInPath(rect.path, e.offsetX, e.offsetY)) {
            
            if (this.hoveredRects.length == 1){
              this.openLink(rect);
            }
            else if (this.hoveredRects.length != 0 && !hasOpenedDialog) {
              hasOpenedDialog = true;
              this.openDialog();
            }
          }
        });
      });
    });    
  }

  drawMapForTitle(title:string) {
    this.ctx.drawImage(this.bm,0,0);

    this.clickRects.forEach(rect => {
      
      if (rect.gene_name == title) {
        this.ctx.beginPath();
        this.ctx.fillStyle = "rgba(219,112,147,0.3)";
        this.ctx.fill(rect.picPath);
      }
    });
  }

  clearMap() {
    this.ctx.drawImage(this.bm,0,0);  
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      hoveredRects: this.hoveredRects
    }

    this.dialog.open(PathwayDialogComponent, dialogConfig);
  }

  // openLink is now used to select a component rather than navigate
  openLink(rect) {

    this.loadingComponent = true;
    // lazily loaded - we pull uniprot/p3db ids given a gene symbol
    // this is much quicker than doing it at runtime
    if (rect.uniProtId == null) {
      this.pathwayService.getIdsFromGeneSymbol(rect.gene_name).subscribe(data => {

        if (data.data.length > 0) {
          rect.uniProtId = data.data[0].UniprotID,
          rect.p3dbId = data.data[0].p3db_id,
          rect.title = data.data[0].ProteinName,
          rect.EMBL = data.data[0].EMBL,
          rect.GeneSymbol = [].concat(data.data[0].GeneSymbol)
        }
        else {
          rect.uniProtId = "",
          rect.p3dbId = "",
          rect.title = rect.gene_name + ": Not Found" 
        }

        this.selectedComponent = rect;
        this.loadingComponent = false;
      }),
      err => {
        console.log("Cannot connect to p3db.");
        this.loadingComponent = false;
      }
    }
    else {
      this.selectedComponent = rect;
      this.loadingComponent = false;
    }
  }

  checkItem(prot) {
    if (prot.isCurrentlyChecked)
      prot.timesMarkedForReview--;
    else
      prot.timesMarkedForReview++;

    prot.isCurrentlyChecked = !prot.isCurrentlyChecked;
  }

  submitCheckedData() {
    this.loadingMarks = true;
    this.markError = '';

    // prepare data for JSON
    let data = {
      user: this.passwordForm.get('password').value,
      image_path: this.rawImagePath,
      components: []
    };

    // components and marks
    this.graphTable.forEach(component => {
      data.components.push({
        gene_name : component.gene_name,
        coordinates : component.coords,
        timesMarkedForReview: component.timesMarkedForReview
      });
    });

    this.pathwayService.postMarksForReview(data).subscribe(res => {
      this.markError = "Successfully posted items for review.";
      this.loadingMarks = false;
    },
    err => {
      this.markError = "Could not send data to the server.";
      this.loadingMarks = false;
    });
  }
}
