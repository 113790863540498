<!-- <nz-input-group nzSearch nzAddOnBefore="Http://" nzAddOnAfter="suffixButton">
    <input type="text" nz-input [(ngModel)]="inputValue" />
    <ng-template #suffixButton>
        <button nz-button nzType="primary" nzSize="large" nzSearch>Search</button>
      </ng-template>
  </nz-input-group> -->

  <nz-input-group nzSearch nzSize="large" [nzAddOnBefore]="addOnBeforeTemplate" [nzAddOnAfter]="suffixButton">
    <input type="text" [(ngModel)]="inputValue" nz-input placeholder="input search text" />
  </nz-input-group>
  <ng-template #addOnBeforeTemplate>
    <nz-select [(ngModel)]="currentLabel" [nzOptions]="labels">
        <nz-option *ngFor="let label of labels" [nzLabel]="label" [nzValue]="label.label"></nz-option>
    </nz-select>
  </ng-template>
  <ng-template #suffixButton>
    <button nz-button nzType="primary" nzSize="large" nzSearch (click)="handle()" >Search</button>
  </ng-template>

  