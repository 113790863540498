import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { SequenceData, Highlight, Seqtable } from 'src/app/interfaces/sequence';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Output, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';

/**
 * @description
 * @author Zhi Zhang
 * @export
 * @interface SeqName
 */
export interface SeqName {
  name: string;
}

@Component({
  selector: 'app-table-sequence',
  templateUrl: './Protein-Conservation-Region-Search.component.html',
  styleUrls: ['./Protein-Conservation-Region-Search.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProteinConservationRegionSearchComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private sharedService : SharedService,
    private router: Router
  ) {}
  public SequenceData?: SequenceData;
  selectedSequence: string = " ";

  onTextSelected(){
    const selectedText = window.getSelection().toString();

    if (!selectedText) return;

    const selectedNode = window.getSelection().anchorNode;
    const selectedCell = selectedNode.parentElement.closest("td");
    const parentRow = selectedCell.parentElement;
    const rowIndex = Array.from(parentRow.parentElement.children).indexOf(parentRow);
    const sequenceColumnIndex = 1; // Assuming that the "SEQUENCE" column is the second column in the table

    const allSequenceCells = Array.from(document.querySelectorAll("td:nth-child(" + (sequenceColumnIndex + 1) + ")"));
    const selectedCellText = allSequenceCells[rowIndex].textContent;
    const startIndex = selectedCellText.indexOf(selectedText);
    const endIndex = startIndex + selectedText.length;

    const correspondingTexts = allSequenceCells.map(cell => {
      const cellText = cell.textContent;
      return cellText.slice(startIndex, endIndex);
    });


    const selectedTextIndex = correspondingTexts.indexOf(selectedText);

    const group_number = this.SequenceData.seqtable[selectedTextIndex].group_num;

    const requiredSequences: string[] = [];

    for (let i = 0; i < this.SequenceData.seqtable.length; i++) {
      if (this.SequenceData.seqtable[i].group_num === group_number
            && (this.toppings.value == null || !this.toppings.value.includes(this.SequenceData.seqtable[i].name))
            && this.SequenceData.seqtable[i].name != "") {
        requiredSequences.push(correspondingTexts[i]);
      }
    }

    // var requiredSequences = this.SequenceData.seqtable.filter(s => s.group_num == group_number).map(seq => seq.);

    this.sharedService.setSelectedText(requiredSequences.join(','));

    this.sharedService.selectedText$.subscribe((selectedText: string) => {
      selectedText = selectedText.replace(',',',\n');
      this.selectedSequence = selectedText;
    });
  }

  submitSelectedText() {


    // Navigate to the Peptide component
    this.router.navigate(['/peptide']); // Replace '/peptide' with the correct route path
  }

  showProteinLogo(){
    this.router.navigate(['/ProteinLogo']);
  }



  ngOnInit(): void {
    // this.loadSquence();
  }

  //===========================================================================
  // Request form setting
  //===========================================================================

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  result = '';
  //{“codes”: [“AT1G74740.1", “AT3G28450.1”]}
  fruits = [
    { name: 'AT1G74740.1' },
    { name: 'AT3G28450.1' },
    { name: 'AT3G24320.1'}
  ];

  add(event: MatChipInputEvent): void {
    this.result = '';
    const input = event.input;
    const value = event.value;

    // Add
    if ((value || '').trim()) {
      this.fruits.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: SeqName): void {
    this.result = '';
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  //===========================================================================
  // Data loading in SEARCH Button
  //===========================================================================

  public pullSequenceData(): void {
    let codes = [];
    for (let o of this.fruits) {
      codes.push(o.name);
    }

    let formData = { codes: codes };

    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');

    //this.database.get('codes', [codes.toString()])
    console.log(environment.BASE_API_URL + 'clustal/', JSON.stringify(formData));
    this.http
      .post<any>(environment.BASE_API_URL + 'clustal/', JSON.stringify(formData), {
        headers: headers,
      })
      .subscribe(
        (seqData) => {
          console.log(seqData);
          //{“codes”: [“AT1G74740.1", “AT3G28450.1”]}
          if (seqData['data'] == null) {
            this.result = 'Data not found';
          } else {
            // console.log(seqData)
            console.log('harsha');
            this.SequenceData = this.unpackSequenceData(seqData['data']);
            this.dataProcessSequence(this.SequenceData);
            console.log(this.SequenceData);
          }
        },
        (phosphoError) => {
          this.result = 'Data Access Failed!';
          console.log('something wrong in data access');
          console.log(phosphoError);
        }
      );
  }

  //===========================================================================
  // filter dsetting for table
  //===========================================================================

  toppings = new FormControl();
  toppingList: string[] = [];

  public trControl(data: any, tr: any, glength: any): boolean {
    // page init, nothing hide.
    if (data == null) {
      return false;
    }
    // dropdown item selected, hide corresponding row.
    if (data.length != 0) {
      if (data.length == glength) {
        return true;
      }
      if (data.includes(tr.name)) {
        // true means hide,
        return true;
      }
      return false;
    }
    return false;
  }

  // ==========================================================================
  // JSON processing functions and SequenceData(object) Formatting
  //===========================================================================
  private unpackSequenceData(data: any): SequenceData {
    let sequenceData: SequenceData = {
      name: data['name'],
      group_number: data['group_number'],
      highlight: this.unpackHighlighte(data['highlight']),
      seqtable: this.unpackSeqtable(data['seqtable']),
    };
    return sequenceData;
  }

  private unpackHighlighte(data: any): Highlight[] {
    let hlsequences = [];

    for (let i = 0; i < data.length; ++i) {
      let hl: Highlight = {
        name: this.sanitize(data[i]['name']),
        index: this.sanitize(data[i]['index']),
      };
      hl['counter'] = 0;
      if (hl.index != 'N/A') {
        hl['hlList'] = hl.index
          .split(',')
          .map(Number)
          .sort((a, b) => a - b);
      }

      hlsequences.push(hl);
    }
    return hlsequences;
  }

  private unpackSeqtable(data: any): Seqtable[] {
    let sequences = [];
    let indexedNumber = '0';

    for (let i = 0; i < data.length; ++i) {

      for(let j = 0; j < data[i]['sequences'].length; j++){
        let htmlSequence = this.sanitize(data[i]['sequences'][j]['sequence']);

        let st: Seqtable = {
          name: this.check(data[i]['sequences'][j]['name']),
          sequence: htmlSequence,
          type: this.sanitize(data[i]['sequences'][j]['type']),
          index: this.check(data[i]['sequences'][j]['index']),
          group_num: i
        };

        sequences.push(st);
      }

      let colorSequence = data[i]['colors']['sequence']
      let colorArray = colorSequence.split(',').map(Number);

      let colorHtmlSequence = '';

      for (let j of colorArray) {
        colorHtmlSequence +=
          '<span  data-tooltip="' +
          j +
          '" data-tooltip-position="bottom" style="background: rgb(8 66 152 /' +
          j +
          ');" > </span>';
      }

      let sqt: Seqtable = {
        name: '',
        sequence: colorHtmlSequence,
        type: '',
        index: '',
        group_num: i
      };

      sequences.push(sqt);

    }

    return sequences;
  }

  // private unpackSeqtable(data: any): Seqtable[] {
  //   let sequences = [];
  //   let indexedNumber = '0';
  //   for (let i = 0; i < data.length; ++i) {
  //     let htmlSequence = this.sanitize(data[i]['sequence']);

  //     // rewirte the sequence by type color
  //     let colortype = this.sanitize(data[i]['type']);
  //     if (colortype == 'color') {
  //       let colorArray = htmlSequence.split(',').map(Number);

  //       htmlSequence = '';

  //       for (let j of colorArray) {
  //         htmlSequence +=
  //           '<span  data-tooltip="' +
  //           j +
  //           '" data-tooltip-position="bottom" style="background: rgb(8 66 152 /' +
  //           j +
  //           ');" > </span>';
  //       }
  //     }

  //     let st: Seqtable = {
  //       name: this.check(data[i]['name']),
  //       sequence: htmlSequence,
  //       type: this.sanitize(data[i]['type']),
  //       index: this.check(data[i]['index']),
  //     };

  //     sequences.push(st);
  //   }

  //   return sequences;
  // }

  //===========================================================================
  // Table formatting
  //===========================================================================

  public nrColumns = ['name', 'newseq', 'index'];

  //===========================================================================
  // Sequence Formatting and highlighing
  //===========================================================================

  private dataProcessSequence(data: any): SequenceData {
    let sequenceData = data;
    let hlIndex = this.SequenceData.highlight;
    this.toppingList = [];
    // for select get sequence id.
    for (let o of hlIndex) {
      this.toppingList.push(o.name);
    }

    // counter for hlIndex[counter]['hlList'], get corresponding highlight arry and index.
    let counter = 0;
    // groupNumber = how many sequence it have + color + mark - 1 (cuz start with 0)
    let groupNumber = parseInt(this.SequenceData.group_number) + 1;

    let tag1 = '<span class="highlightText">';
    let tag2 = '</span>';
    for (let o of this.SequenceData.seqtable) {
      if (counter == groupNumber) {
        counter = -1;
      }
      if (o['type'] == 'seq') {
        if (hlIndex[counter] && hlIndex[counter].index != 'N/A') {
          let wordcounter = hlIndex[counter]['counter'];
          let htSeqArray = hlIndex[counter]['hlList'];
          let htmlSequence = o.sequence;
          let htSeqArrayFixed = [];

          for (let i = 0; i < htmlSequence.length; i++) {
            if (htmlSequence[i] != '-') {
              wordcounter++;
              if (htSeqArray.includes(wordcounter)) {
                htSeqArrayFixed.push(i);
              }
            }
          }
          let colorCount = 0;
          for (let j of htSeqArrayFixed) {
            let pos = j + (tag1.length + tag2.length) * colorCount;
            htmlSequence =
              htmlSequence.substring(0, pos) +
              tag1 +
              htmlSequence.charAt(pos) +
              tag2 +
              htmlSequence.substring(pos + 1);
            colorCount++;
          }
          // because substring function returned a new object.
          o.sequence = htmlSequence;
          hlIndex[counter]['counter'] = o.index;
        }
      }
      // adding inline style to sequence,
      o['newseq'] = this.sanitizer.bypassSecurityTrustHtml(o.sequence);
      counter++;
    }

    return sequenceData;
  }

  private sanitize(obj: string): string {
    return obj ? String(obj) : 'N/A';
  }
  private check(obj: string): string {
    return obj ? String(obj) : '';
  }
}
