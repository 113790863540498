import { Component, Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { concat, forkJoin, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { Breakpoints } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';
import { CytographAdditionalData, ExtendableNode } from 'src/app/interfaces/cytograph-additional-data';


@Injectable({
  providedIn:'root'
})
@Component({
  selector: 'app-kicnetwork',
  templateUrl: './kicnetwork.component.html',
  styleUrls: ['./kicnetwork.component.css']
})

export class KicnetworkComponent implements OnInit {



constructor(
  private http: HttpClient
  )
  {
     this.http.get('').subscribe((data:any) => {



  });
  }
  ELEMENT_DATA;
  showCyto = false;

  // we'll pass this onto the cytoscape network component
  experimentFilter:CytographAdditionalData = {kicExperimentId: "all"};




  //handles the joining of the two ajax requests for the edge and node info
  //could be moved out of this component and into cytoscape-example
  // getData() {
  //   //forkJoin combines the two observables from http requests and waits for both to finish
  //   forkJoin([
  //     this.database.get('kicnetworkEdge', []),
  //     this.database.get('kicnetworkNode', []),
  //   ]).subscribe((x) => {
  //     //map edge_info.json and node_info.json to proper cyto format
  //     this.mapEdgesNodes(x[1]['data'], x[0]['data']); //edge node details
  //   });
  // }


  //https://p3db.ddns.net:5000/api/new_kic_nodes/
  //https://p3db.ddns.net:5000/api/new_kic_nodes/
  getData() {

    // const nodeData$ = this.http.get('assets/nodedata.json');
    // const edgeData$ = this.http.get('assets/edges_data.json');

    const nodeData$ = this.http.get('assets/new_node.json');
    const edgeData$ = this.http.get('assets/new_edge.json');
    let nested1, nested2; // declare variables here
    console.log('check');

    forkJoin([nodeData$, edgeData$]).subscribe(([nodeData, edgeData]) => {
      const x = [nodeData, edgeData];
      const c = this.mapEdgesNodes(x[0]['data'], x[1]['data']); //edge node details
      console.log(c);
    });
  }



    ngOnInit(): void {
    this.getData();
  }

  //p3db4-angular/src/app/pages/kicnetwork
  fetchJson(filename) {
    let headers = new HttpHeaders();

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };


    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  mapEdgesNodes(nodes, edges) {
    // let nodes, edges;

    for (let i = 0; i < nodes.length; i++) {
      // nodes[i]['name'] = nodes[i]['node_name'];
      nodes[i]['id'] = nodes[i]['tair_id'];
      // nodes[i]['Sayeera']=nodes[i]['sayeera']; if we want to add this field to entire nodes dataset
      nodes[i]['width'] = nodes[i]['substrate_count'] * 5 + 25;

      nodes[i].network_type = "kic";
      nodes[i] = { data: nodes[i] };

      // quick test data injection for demo REMOVE BEFORE PUSHING
/*      if (nodes[i].data['tair_id'] == "AT5G67380") {
        var testNode1: ExtendableNode = {nodeTitle: "TestNode 1", data: {test1: "Data", test2: "More Data", test3: "Even more data"}};
        var testNode2: ExtendableNode = {nodeTitle: "TestNode 2", data: {test1: "Data 2", test2: "More Data 2", test3: "Even more data 2"}};


        var testNode3 : ExtendableNode = {
            nodeTitle: "TestNode 3",
            data:{
              test1 : "Data 3",
              nestedNode: {
                nodeTitle: "NestedNode",
                data: {
                  test1 : "Nested Node",
                  test2 : "More Data nested",
                  test3 : "Even more data nested"
                }
              } as ExtendableNode
            }
        };
        nodes[i].data.extNode1 = testNode1;
        nodes[i].data.extNode2 = testNode2;
      }
      if (nodes[i].data['tair_id'] == "AT3G23340") {
        var testNode3: ExtendableNode = {nodeTitle: "TestNode 3", data: {test1: "Data 3", test2: "More Data 3", test3: "Even more data 3"}};
        var testNode4: ExtendableNode = {nodeTitle: "TestNode 4", data: {test1: "Data 4", test2: "More Data 4", test3: "Even more data 4"}};


        var testNode5 : ExtendableNode = {
            nodeTitle: "TestNode 5",
            data:{
              test1 : "Data 5",
              nestedNode: {
                nodeTitle: "NestedNode",
                data: {
                  test1 : "Nested Node",
                  test2 : "More Data nested",
                  test3 : "Even more data nested"
                }
              } as ExtendableNode
            }
        };
        nodes[i].data.extNode1 = testNode3;
        nodes[i].data.extNode2 = testNode4;
      }
      */
    }
    for (let i = 0; i < edges.length; i++) {
      edges[i]['source'] = edges[i]['Kinase'];
      edges[i].network_type = "kic";
      delete edges[i]['Kinase'];
      edges[i]['target'] = edges[i]['substrate'];
      edges[i]['width'] = Math.log2(edges[i].Phosphorylated_Percentage) + 1;

      // if(edges[i].Phosphorylated_Percentage>30){
      //   edges[i]['line_style']="dashed";
      //   edges[i]['curve_style']="straight";
      //   edges[i]['line_color']="red"
      // }
      // else{
      //   edges[i]['line_style']="solid";
      //   edges[i]['curve_style']="haystack";
      //   edges[i]['line_color']="white"
      // }

      //
      delete edges[i]['substrate'];

      edges[i] = { data: edges[i], classes: 'tooltip' };
    }
    // cy.$('#edge').style('line-style', 'dashed');
    this.ELEMENT_DATA = {


      style: [
        {
          selector: 'node',
          css: {
             content: 'data(tair_id)',
            'border-color': '#99ccff',
            'border-opacity': 1,
            'border-width': '1px',
            'background-color': '#99ccff',
            // 'shape':'diamond',
          },
        },
        {
          selector: 'edge',
          css: {
            content:'data(Phosphorylated_Percentage)',
            width: 'data(width)',
            'target-arrow-shape': 'triangle',
            'line-color': '#401a4a',
            // 'line-style':'data(line_style)',
            // 'curve-style':'data(curve_style)',
            color: '#000000',

          },

        },
      ],

      elements: {
        nodes: nodes,
        edges: edges,
      },

    };

    console.log(this.ELEMENT_DATA)
    let family_list = {
      'Ser/Thr Protein Kinase Superfamily': '#ff0000',
      'AME/AFC Family': '#ff3399',
      'Casein Kinase Family': '#3366ff',
      'RLCK Family': '#00cc00',
      'CDPk Super Family': '#ff3399',
      'Lectin Receptor Kinase family': '#FFFF33'
    };

    let experiment_color_list = {
      'Nagib 2007(Thelen lab)': '#445941',
      'Nagib 2021(Thelen lab)': '#414259',
      'Gabriel 2024(Thelen lab&Stacy lab)': '#594141'
    };

    for (let e in experiment_color_list) {
      // for (let j = 1; j <= 100; j++) {
        this.ELEMENT_DATA.style.push({
          selector: `edge[Experiments_ID="${e}"]`,
          css: {
            'line-color': `${experiment_color_list[e]}`,
            // 'opacity': j / 100,
          },
        });
      // }
    }

    for (let e in family_list) {
      this.ELEMENT_DATA.style.push({
        selector: `node[family = "${e}" ]`,
        css: {
          content: 'data(tair_id)',
          'border-color': `${family_list[e]}`,
          'border-opacity': 1,
          width: 'data(width)',
          height: 'data(width)',
          'border-width': '1px',
          'background-color': `${family_list[e]}`,
        },
      });
    }
    console.log("Element Data is : ",this.ELEMENT_DATA);
    this.showCyto = true;
  }
}
