import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blast-ptm-peptides',
  templateUrl: './blast-ptm-peptides.component.html',
  styleUrls: ['./blast-ptm-peptides.component.css'],
})
export class BlastPtmPeptidesComponent implements OnInit {
  constructor(private http: HttpClient) { }
  listOfData= [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park'
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park'
    }
  ];
  columns=[
    'Name','Age', 'Address', 'email'
  ];
  fasta_header
  PTM_PEPTIDES_DATA;
  pepfeatures;
  PTM_SITES_DATA;
  showWait = false;
  SEQUENCE_DATA;
  
  test = [
    // {name:"a", class:"highlight", index:""},
    // {name:'b', class:'none', index:""}
  ];
  positions;

  ngOnInit(): void {
    // this.getData();
  }
//https://p3db.ddns.net:5000/api/getptmpeptides/?locus_id=AT1G01110.2
  getData(val){
    this.showWait = true;
    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'getptmpeptides?locus_id='+val),
      this.fetchJson(environment.BASE_API_URL + 'getptmsites/?locus_id='+val),
      this.fetchJson(environment.BASE_API_URL + 'getproteindetails/?types=LocusID&value='+val),
      this.fetchJson(environment.BASE_API_URL + 'getpepfeatures/?locus_id='+val),

    ])
    .subscribe(result => {
      this.showWait = false;
      this.pepfeatures = result[3]['data']
      this.dealInfo("ptmPeptides", result[0]['data']);
      this.dealInfo("ptmSites", result[1]['data']);
      this.fasta_header = result[2]['data'][0].fasta_header
      let ptmSites = result[1]['data']
      let sites = []
      ptmSites.forEach(element => {
        sites.push(element.site_in_protein)
      });
      let sequence = result[2]['data'][0].sequence
      this.dealPtmSites(sites, sequence)
    });
  }
  fetchJson(filename){
    let headers = new HttpHeaders();
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  dealPtmSites(sties, sequence){
    console.log('sites', sties);
    console.log('sequence', sequence);
    let ptm_index = 0;
    let index_highLight = sties.map((element) => {
      console.log(element.Position);
      return element - 1;
    });
    let array_positions = sties.map((element) => {
      console.log(element.Position);
      return element;
    });
    this.positions = array_positions.toString();
    let array_seq = [...sequence];
    console.log(index_highLight);
    array_seq.forEach((element, index) => {
      if (index_highLight.includes(index)) {
        this.test.push({
          name: element,
          class: 'highlight',
          index:'',
          arrow: '',
          tooptip: "",
        });
        ptm_index++;
      } else {
        this.test.push({
          name: element,
          class: 'none',
          index: (index + 1) % 10 == 0 ? String(index + 1) : '',
          arrow: (index + 1) % 10 == 0 ? '|' : '',
          tooptip: '',
        });
      }
    });
  }
  // dealData(data) {
  //   let ptm_index = 0;
  //   let ptmscore = 'Cutoff=0.5';
  //   console.log(data.Results);
  //   let index_highLight = data.Results.map((element) => {
  //     console.log(element.Position);
  //     return element.Residue - 1;
  //   });
  //   let array_positions = data.Results.map((element) => {
  //     console.log(element.Position);
  //     return element.Residue;
  //   });
  //   this.positions = array_positions.toString();
  //   let array_seq = [...data.querySeq];
  //   console.log(index_highLight);
  //   array_seq.forEach((element, index) => {
  //     if (index_highLight.includes(index)) {
  //       this.test.push({
  //         name: element,
  //         class: 'highlight',
  //         // index: String(index + 1),
  //         // arrow: '|',
  //         index: '',
  //         arrow: '',
  //         tooptip: "",
  //       });
  //       ptm_index++;
  //     } else {
  //       this.test.push({
  //         name: element,
  //         class: 'none',
  //         index: (index + 1) % 10 == 0 ? String(index + 1) : '',
  //         arrow: (index + 1) % 10 == 0 ? '|' : '',
  //         tooptip: '',
  //       });
  //     }
  //   });
  // }

  dealInfo(type, data){
    if(type === "ptmPeptides"){
      data.forEach( item => {
        item.peptide_feature = ""
        this.pepfeatures.forEach( features => {
          if(item.internal_peptide_id == features.internal_peptide_id){
            item.peptide_feature += features.feature_name + " : " + features.value  + '\n'
          }
        })
      })
      this.PTM_PEPTIDES_DATA = {
        name: ["detected_peptide","peptide_feature","internal_protein_id","peptide","orthogs","peptide_in_protein","site_in_peptide"],
        display: ["Detected Peptide","Peptide Features","# Internal Protein","Peptide","Orthogs","Peptide In Protein","Site In Peptide"],
        data:data,
      };
    }else{
      this.PTM_SITES_DATA = {
        name: ["site_in_protein","internal_protein_id","internal_site_id","ptm_id","ptm_keyword"],
        display: ["Site In Protein","# Internal Protein","# Internal Site","# PTM","PTM Keyword"],
        data:data,
      };
    }
  }

  fn_search(){
    this.PTM_PEPTIDES_DATA = undefined;
    this.PTM_SITES_DATA = undefined;
    this.fasta_header=undefined;
    let element = document.getElementById('protein_id') as HTMLInputElement;
    // alert(element.value)
    if(element.value){
      this.getData(element.value);
    }else{
      alert('Please input protein!');
    }
  }
  sequence_search(){
    this.PTM_PEPTIDES_DATA = undefined;
    this.PTM_SITES_DATA = undefined;
    this.fasta_header=undefined;
    let element = document.getElementById('sequence') as HTMLInputElement;
    // alert(element.value)
    if(element.value){
      this.getSequenceData(element.value);
    }else{
      alert('Please input protein!');
    }
  }

  getSequenceData(val){
    this.showWait = true;
    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'blastppep/?protein_sequence='+val),
    ])
    .subscribe(result => {
      this.showWait = false;
     this.dealSequenceData(result[0]['data'])
    });
  }

  dealSequenceData(data){
    console.log(data);
    data.forEach( item => {
      item.peptide_feature = ""
      this.pepfeatures.forEach( features => {
        if(item.internal_peptide_id == features.internal_peptide_id){
          item.peptide_feature += features.feature_name + " : " + features.value  + '\n'
        }
      })
    })
    this.PTM_PEPTIDES_DATA = {
      name: ["detected_peptide","peptide_feature","internal_protein_id","peptide","orthogs","peptide_in_protein","site_in_peptide"],
      display: ["Detected Peptide","Peptide Features","# Internal Protein","Peptide","Orthogs","Peptide In Protein","Site In Peptide"],
      data:data,
    };
  }

  fn_seqHighlight(item) {
    let style = {
      seq_highlight01: true,
      seq_highlight02: false,
    };
    if (item.class == 'highlight') {
      style = {
        seq_highlight01: true,
        seq_highlight02: true,
      };
    }
    return style;
  }

}
