<div>
  <app-toolbar></app-toolbar>
</div>
<div id="image">
  <mat-card id="component-container" *ngIf="showCyto">
    <mat-card>
      <h2 id="title">Cytoscape Graph Example</h2>
    </mat-card>

    <app-cytoscape [cytoData]="ELEMENT_DATA"></app-cytoscape>

  </mat-card>
  <app-footer></app-footer>
</div>
