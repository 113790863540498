import { Component, OnInit } from '@angular/core';

import { Router } from "@angular/router";
import { MobileService } from '../../services/mobile.service';
import { MatSidenav } from '@angular/material/sidenav';
import { trigger, transition, style, animate } from '@angular/animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // control: FormControl = new FormControl('');
  constructor(public mobileService: MobileService,private router: Router) { }
slideIndex = 1;


plusSlides(n) {
  this.showSlides(this.slideIndex += n);
}

currentSlide(n) {
  this.showSlides(this.slideIndex = n);
}

showSlides(n) {
  console.log(n)
  let i;
  let slides = document.getElementsByName("mySlides");
  console.log(slides)
  let dots = document.getElementsByClassName("dot");
  if (n > slides.length) {this.slideIndex = 1}    
  if (n < 1) {this.slideIndex = slides.length}
  for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";  
  }
  for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
  }
  slides[this.slideIndex-1].style.display = "block";  
  dots[this.slideIndex-1].className += " active";
}

  ngOnInit():void{

  }


  get isMobile(): boolean {
    return this.mobileService.isMobile();
  }

  nav(route: String){
    this.router.navigate([route]);
  }
}
