import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeoLocation } from '../interfaces/geo-location';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeolocatorService {

  constructor(private http: HttpClient) { }

  getLocationDetails(ip: string): Observable<GeoLocation> {

    //'https://ipapi.co/'+ ip + '/json'

    return this.http.get<GeoLocation>('http://ip-api.com/json/' + ip);
  }
}
