<mat-card class="container-card">
    <mat-card class="background-card">
        <div style="padding-bottom: 1%;">
            <mat-card>
                <h1>Phosphorylation Data</h1>
            </mat-card>
        </div>
        <div>
            <mat-card *ngif = "!phosphorylationData">
                <h1>Loading Data...</h1>
            </mat-card>
            <mat-card >
                <table style="font-size: large;">
                    <h2>Details</h2>
                    <tr>
                        <td>Description</td><td>{{ phosphorylationData.description }}</td>
                    </tr>
                    <tr>
                        <td>Organism</td><td>{{ phosphorylationData.organism }}</td>
                    </tr>
                    <tr>
                        <td>Position</td><td>{{ phosphorylationData.location }}</td>
                    </tr>
                    <tr>
                        <td>No. of Spectra</td><td>{{ phosphorylationData.numSpectra }}</td>
                    </tr>
                    <!-- <tr>
                        <td>Reference</td><td>{{ proteinData.preferenceLink }}</td>
                    </tr> -->
                </table>
                <br>
                <br>
                <h2>Sequence</h2>
                <table style="font-size: large;">
                    <tr>
                        <td style="width: 20%;">Sequence</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(1)">
                                Display
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 20%;">Accesions</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(2)">
                                Display
                            </button>
                        </td>
                    </tr>
                </table>                
            </mat-card>
        </div>

        <div style="padding-top: 1%;" *ngIf = "displayField == 1">
            <mat-card>
                <h1>Sequence</h1>
                <table mat-table
                    [dataSource]="[phosphorylationData]" multiTemplateDataRows>
                    <!-- class="mat-elevation-z8"> -->
                        <ng-container matColumnDef="sequence">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let protein">Surrounding Sequence</td>
                        </ng-container>

                        <ng-container matColumnDef="sequenceData">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let protein"> {{protein.surroundingSequence}} </td>
                            <!-- <td mat-cell *matCellDef="let protein"> <div [innerHTML]= "highlightLocation(protein.surroundingSequence, false)"></div> </td> -->
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="sequenceColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: sequenceColumns;"
                            class="mat-column-sequDetail">
                        </tr>
                </table>
                <br>
                <br>
                <h1>NR Peptides</h1>
                <table mat-table
                    [dataSource]="phosphorylationData.nrSequence" multiTemplateDataRows>
                        <ng-container matColumnDef="sequence">
                            <th mat-header-cell *matHeaderCellDef> Sequence</th>
                            <td mat-cell *matCellDef="let protein"> <div [innerHTML]= "highlightLocation(protein.sequence)"></div></td>
                        </ng-container>

                        <ng-container matColumnDef="locationInPeptide">
                            <th mat-header-cell *matHeaderCellDef> Location in Sequence</th>
                            <td mat-cell *matCellDef="let protein"> {{ protein.locationInPeptide }} </td>
                        </ng-container>

                        <ng-container matColumnDef="link">
                            <th mat-header-cell *matHeaderCellDef> Details</th>
                            <td mat-cell *matCellDef="let protein"> 
                                <button mat-raised-button
                                class="button-color"
                                [routerLink] = "['/peptide-data/',  protein.link.pnrseq, protein.link.pro, protein.link.loc, protein.locationInPeptide]">
                                    Details
                                </button> 
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="nrColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: nrColumns;"
                            class="mat-column-sequDetail">
                        </tr>
                </table>
            </mat-card>
        </div>

        <div style="padding-top: 1%; text-align: center;"  *ngIf = "displayField == 2">
            <mat-card style="margin: auto;">
                <h1 style="text-align: left;">
                    Accessions
                </h1>
                <div style="display: inline-block;">
                    <table mat-table style="text-align: left;"
                        [dataSource]="proteinColumn1" multiTemplateDataRows
                        >
                            <ng-container matColumnDef="recordCol1">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let protein"> 
                                    {{ protein}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="['recordCol1']"></tr>
                            <tr mat-row *matRowDef="let element; columns: ['recordCol1'];"
                                class="example-element-row"></tr>
                    </table>
                </div>

                <div style="display: inline-block;">
                    <table mat-table style="text-align: right;"
                        [dataSource]="proteinColumn2" multiTemplateDataRows
                        style="display: inline-block;">
                            <ng-container matColumnDef="recordCol1">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let protein"> 
                                    {{ protein}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="['recordCol1']"></tr>
                            <tr mat-row *matRowDef="let element; columns: ['recordCol1'];"
                                class="example-element-row"></tr>
                    </table>
                </div>
            </mat-card>
        </div>


        
        
    </mat-card>
</mat-card>

