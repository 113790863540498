//by ziting
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ortholog-conservation',
  templateUrl: './ortholog-conservation.component.html',
  styleUrls: ['./ortholog-conservation.component.css']
})
export class OrthologConservationComponent implements OnInit {

  constructor(private http: HttpClient) { }
  ELEMENT_DATA;

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'conservation?'),
    ]).subscribe(result => {
        console.log("fetch",result[0]);
        this.dealInfo(result[0]['data']);
      });
  }

  fetchJson(filename){
    let headers = new HttpHeaders();

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }


  dealInfo(data){
    console.log(data);
    this.ELEMENT_DATA = {
      name: ["uniprot_id","conservation_value","conserved_pho_sites","locus_id","orthogs","pep_len","peptide","pho_site_on_full_sequence","pho_site_on_peptide","pho_sites_on_MSA"],
      display: ["uniprot_id","conservation_value","conserved_pho_sites","locus_id","orthogs","pep_len","peptide","pho_site_on_full_sequence","pho_site_on_peptide","pho_sites_on_MSA"],
      data:data,
    };
  }

}
