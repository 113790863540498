import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Gene } from 'src/app/interfaces/gene';
// import { GeneService } from 'src/app/Services/gene.service';
import { MatTableDataSource } from '@angular/material/table';
import { Uniquegene } from 'src/app/interfaces/uniquegene';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';


@Component({
  selector: 'app-kinase-genedata',
  templateUrl: './kinase-genedata.component.html',
  styleUrls: ['./kinase-genedata.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('sequExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class KinaseGenedataComponent implements OnInit {

  constructor(private router: Router, 
              private database : DatabaseConnService,
              private activerouter: ActivatedRoute) { }
  

  genes?: Gene[];
  geneColumns = ['Genes'];
  
  genemessage?: string;
  expandedGene: Gene | null;



  uniquegeneColumns = ['Gene', 'DNA Sequence', 'Protein Sequence'];
  uniquegenemessage?: string;
  expandedUniquegene: Uniquegene | null;
  expandedSequence: Uniquegene | null;
  
  message?: string

  displaySeq: number | null

  dataSource: MatTableDataSource<Gene>;

  ngOnInit(): void {

    this.getGenes(this.activerouter.snapshot.params.subgroup_name);
    // console.log(this.activerouter.snapshot.params.subgroup_name);
    // console.log(this.geneColumns);
    // console.log(this.expandedSequence);
  }

  print()
  {
    console.log(this.displaySeq);
    console.log(this.expandedSequence);

  }
  nav(route: String){
    this.router.navigate([route]);
  }

  getGenes(subgroup_name: string): void
  {
    this.database.get('gene', [subgroup_name])
    // this.geneService.get(subgroup_name)
    .subscribe(
      geneData => { this.genes = geneData['data'];
                    this.genemessage = "gene data loaded";
                    console.log(this.genes);
                    console.log(this.genes[0]);
                    console.log(this.genes[0].uniquegenes)

                    for (let i = 0; i < this.genes.length; ++i)
                    {
                      this.database.get('uniqueGene', [this.genes[i].gene_name])
                      .subscribe(
                        uniquegeneData => { this.genes[i].uniquegenes = uniquegeneData['data'];
                                            this.uniquegenemessage = "uniquegene data loaded";},
                        uniquegeneError => { this.uniquegenemessage = uniquegeneError.message; }
                      )
                    }
                    console.log(this.genes[0].uniquegenes);


                    this.dataSource = new MatTableDataSource(this.genes);
                  },
      geneError => { this.genemessage = geneError.message; }
    )
  }

  determineExpandedSequence(uniquegene: Uniquegene, seq: number) {
    console.log(this.displaySeq, this.expandedSequence, uniquegene);
    if (this.expandedSequence === uniquegene && this.displaySeq == seq){ // press same uniqueGene
      this.expandedSequence = null;
      this.displaySeq = null;
    }
    else{
      this.expandedSequence = uniquegene;
      this.displaySeq = seq;
    }
  }
}