import { Component, OnInit,AfterViewInit, ViewChild } from '@angular/core';
import { type } from 'os';
import { from } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';
import { ActivatedRoute } from '@angular/router';


import { MatTableDataSource } from '@angular/material/table';
import { MatSortModule, MatSort, Sort } from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { rgb } from 'd3-color';

// import { MatProgressBarModule} from '@angular/material'

// import { d3} from d3;
declare const Newick: any;
declare const d3: any;

@Component({
  selector: 'app-phylogenetic-tree',
  templateUrl: './phylogenetic-tree.component.html',
  styleUrls: ['./phylogenetic-tree.component.css']
})
export class PhylogeneticTreeComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  newickError: string;

  constructor(private http: HttpClient, 
    private db: DatabaseConnService,
    private route : ActivatedRoute) 
    {
      let orgData = []

      this.db.get('phylogeneticTree', []).subscribe(
        data =>{ orgData.push(data['data']); 
          this.organismData = orgData[0];
          this.dataSource = new MatTableDataSource(this.organismData)
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, error => {
          this.newickError = error.statusText;
        }
      )

      let newick_file = this.http.get('/assets/newick_species.txt', {responseType: 'text'});
      newick_file.subscribe(
        data => {

          // console.log(data); 
          this.newick = Newick.parse(data)
          this.buildNewickNodes(this.newick, null);

          let doc = document.getElementById('radial-tree-card')
          // console.log(doc.getBoundingClientRect()); 

          let width = doc.getBoundingClientRect()['width'];
          let height = doc.getBoundingClientRect()['height'];

          width = 1000;
          d3.phylogram.buildRadial('#radialtree', this.newick, {
            width: width,
            skipLabels: false
          })

          this.loadHoverEvent();
      
      });
    }

  newickNodes = [];

  selected_element : string;
  selected_data : string;
  model: boolean = false;
  newick: string = null;

  organismColumns = ["Id", "Name", 'CommonName',"Link"]
  public dataSource: MatTableDataSource<string>;
  // paginator: MatPaginator;
  public organismData: string[];


  species_dict: object = {
    "Arabidopsis thaliana": "3702", 
    "Avena sativa": "4498", 
    "Brachypodium distachyon": "15368", 
    "Brassica napus": "3708", 
    "Capsicum annuum": "4072", 
    "Chlamydomonas reinhardtii": "3055", 
    "Cicer arietinum": "3827", 
    "Citrus sinensis": "2711", 
    "Daucus carota": "4039", 
    "Encephalartos altensteinii": "3300", 
    "Glycine max": "3847", 
    "Gossypium hirsutum": "3635", 
    "Hordeum vulgare": "4513", 
    "Hordeum vulgare subsp. vulgare": "112509", 
    "Jatropha curcas": "180498", 
    "Lilium longiflorum": "4690", 
    "Lotus japonicus": "34305", 
    "Medicago sativa": "3879", 
    "Medicago truncatula": "3880", 
    "Mesostigma viride": "41882", 
    "Nephroselmis olivacea": "31312", 
    "Nicotiana tabacum": "4097",
    "Oltmannsiellopsis viridis": "51324", 
    "Oryza sativa Indica Group": "39946",
    "Oryza sativa subsp. indica": "39946", 
    "Oryza sativa Japonica Group": "39947",
    "Oryza sativa subsp. japonica": "39947", 
    "Ostreococcus tauri": "70448", 
    "Phaseolus vulgaris": "3885", 
    "Physcomitrella patens subsp. patens": "145481", 
    "Physcomitrium patens": "3218", 
    "Pisum sativum": "3888", 
    "Populus trichocarpa": "3694", 
    "Ricinus communis": "3988", 
    "Scenedesmus fuscus": "3073", 
    "Selaginella moellendorffii": "88036", 
    "Sinapis alba": "3728", 
    "Solanum lycopersicum": "4081", 
    "Solanum tuberosum": "4113", 
    "Sorghum bicolor": "4558", 
    "Spinacia oleracea": "3562", 
    "Stigeoclonium helveticum": "55999", 
    "Tetradesmus obliquus": "3088", 
    "Triticum aestivum": "4565", 
    "Tupiella akineta": "160070", 
    "Vitis vinifera": "29760", 
    "Zea mays": "4577"
  }


  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } 
  }

  update_selected_row(element)
  {
    this.selected_data = element
  }

  update_selected(e) {
    // console.log('something something darkside', e, typeof(e)); 
    this.selected_element = e
    // .data(e);
  }

  send_data()
  {
    return;
    // this.route.navigateByUrl('/dynamic', { state: { id:1 , name:'Angular' } });
  }

  loadHoverEvent() {
    var vis = d3.select('#radialtree');

    var elems = vis.selectAll('g.leaf.node text').on('mouseover', (data, i) => {
      var currentElem = elems[0][i];
      var svgParent = currentElem.parentElement;
      currentElem.style.fontSize = "15px";
      currentElem.style.cursor = "pointer";

      var textBox = currentElem.getBBox();

      var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      rect.setAttribute("x", textBox.x);
      rect.setAttribute("y", textBox.y);
      rect.setAttribute("rx", "5");
      rect.setAttribute("ry", "5");
      rect.setAttribute("id", "bgRect");
      rect.setAttribute("width", textBox.width);

      if (parseInt(currentElem.getAttribute('dx')) < 0){
        var newX = -parseInt(currentElem.getAttribute('dx')); 
        rect.setAttribute("x", newX.toString());
      }
        
      rect.setAttribute("height", textBox.height);
      rect.setAttribute("fill", "#93E9BE");
      svgParent.insertBefore(rect, currentElem);

      var grandParent = svgParent.parentElement;
      svgParent.remove();
      grandParent.appendChild(svgParent);
    });

    var elems = vis.selectAll('g.leaf.node text').on('mouseout', (data, i) => {
      var currentElem = elems[0][i];
      var svgParent = currentElem.parentElement;
      
      document.getElementById("bgRect").remove();
      currentElem .style.fontSize = "10px";
      svgParent.style.zIndex = '0';
    });
  }

  click(selector) 
  { 
    console.log('clicked');
    var vis = d3.select('#' + selector);
    vis.selectAll('g.leaf.node text').on('click', 
      data => 
      {
        
        for (let i = 0; i < this.organismData.length; ++i)
        {
          if (data['name'] == this.organismData[i]['Name'])
          {
            this.selected_data = this.organismData[i]
            break;
          }
          else if (data['name'] in this.species_dict) {
              let key = this.species_dict[data['name']];
              if (key == this.organismData[i]['id'])
              {
                this.selected_data = this.organismData[i]
                break;
              }
          }
        }
      });
      console.log(this.selected_data);

  }


    // let doc = document.getElementById('radialtree');
    // let nodes = doc.querySelectorAll('g.leaf.node text');
    // for (let i = 0; i < nodes.length; ++i)
    // {
    //   nodes[i].
    // }
    // console.log(doc);
    // console.log(nodes);
    // console.log('clicked'); 
    // let x = d3.phylogram.click('#radialtree');
    // console.log(x);
  // }
  
  buildNewickNodes(node, callback) {
    this.newickNodes.push(node)
    if (node.branchset) {
      for (var i=0; i < node.branchset.length; i++) {
        // console.log(node.branchset[i]);

        this.buildNewickNodes(node.branchset[i], null)
      }
    }
  }



  

}
