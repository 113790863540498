import { Component, OnInit } from '@angular/core';
import { forkJoin, range } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-intermediate-page',
  templateUrl: './intermediate-page.component.html',
  styleUrls: ['./intermediate-page.component.css']
})
export class IntermediatePageComponent implements OnInit {

  showWait = false;
  idType="LocusID";
  listOfDataProteinIDs = [];
  listOfDataPeptides=[];
  phosphorylation_sites = [];
  proteinId = [];
  id;
  pdbid;

  columnKeysProteinIDs = {
    'P3DB ID': {"val":'p3db_id',"tooltip":"p3db ID"},
    'Protein Name': {"val":'ProteinName',"tooltip":"protein Name"},
    'Species Name': {"val":'Species Name',"tooltip":"species name"},
    'Uniprot ID': {"val":'UniprotID',"tooltip":"uniprot ID"},
    'UniParc ID': {"val":'UniParcID', "tooltip":'uni parc ID'},
    RefSeq: {"val":'RefSeq',"tooltip":"ref seq"},
    'Locus ID': {"val":'LocusID',"tooltip":"locus Id"},
    'Gene Symbol': {"val":'GeneSymbol',"tooltip":"gene symbol"},
    'Gene ID': {"val":'GeneID',"tooltip":"Gene ID"},
    EnsemblPlants: {"val":'EnsemblPlants',"tooltip":"emsembl plants"},
    EMBL: {"val":'EMBL', "tooltip":"embl"},
    STRING: {"val":'STRING',"tooltip":"string"},
    'BioGR ID': {"val":'BioGRID',"tooltip":"bio grid"},
    IntAct: {"val":'IntAct',"tooltip":"intact"},
    'Species ID': {"val":'SpeciesID',"tooltip":"specied id"}
  };

  columnKeysPeptides = {
    'Detected Peptide': {'val':'detected_peptide','tooltip':'detected peptide'},
    'Peptide Features': {"val":'peptide_feature',"tooltip":"peptide feature"},
    Peptide: {"val":'peptide',"tooltip":"peptide"},
    'Peptide In Protein': {"val":'peptide_in_protein',"tooltip":"Peptide in Protein"},
    'Site In Protein': {"val":'site_in_peptide',"tooltip":"site in protein"},
    'P3DB ID': {"val":'internal_protein_id',"tooltip":'Internal protein'},
    'Locus ID': {"val":'fastaHeader',"tooltip":"protein name"}
  };

  constructor(private http: HttpClient,private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.log("yeah yeah")
    this.route.params.subscribe(params => {
      // this.idType = params['idType'];
      let parameter = params['id'];
      let splitArray = parameter.split("_")
      if(splitArray[0].toLowerCase() == "locusid"){
        this.idType="LocusID";
      }else if(splitArray[0].toLowerCase()=="uniparcid"){
        this.idType="UniParcID";
      }else if(splitArray[0].toLowerCase()=="uniprotid"){
        this.idType="UniprotID";
      }else if(splitArray[0].toLowerCase()=="biogrid"){
        this.idType="BioGRID";
      }
      this.id = splitArray[1];
      console.log("path is : ",this.id)
      
        this.getDataByProtein(this.id);
        this.getProteinID(this.id);
     
      // this.getDataByProtein(this.pdbid);
    });
    
  }

  // getDataByProtein(val) {
  //   this.noData = false;
  //   this.showWait = true;
  //   forkJoin([
  //     this.fetchJson(
  //       environment.BASE_API_URL +
  //         'get_intproteinid_details/?int_prot_id=' +
  //         val 
  //     ),
  //   ]).subscribe((result) => {
  //     console.log(environment.BASE_API_URL +
  //       'get_intproteinid_details/?int_prot_id=' +
  //       val);
  //     let data = result[0]['data'];
      
      
  //     if(Object.entries(data['paper_info']).length == 0 ){
  //       this.noData = true;
  //       this.showWait = false;
  //       // window.alert("No search results ....");
  //     }else{
  //       let locusID = data['protein_detail']['fasta_header'].split("|")[0].trim().split(".")[0];
  //       this.dealInfo(
  //         data.peptides,
  //         data.peptide_features,
  //         data.paper_info,
  //         data.protein_detail
  //       );
  //       let sites = [];
  //       data.protein_detail.ptm_sites.forEach((element) => {
  //         sites.push(element.site_in_protein);
  //       });
  //       console.log("sites : ",sites);
  //       this.dealPtmSites(
  //         sites,
  //         data.protein_detail.sequence,
  //         data.protein_detail.internal_protein_id
  //       );
  //       console.log("locusid is : ",locusID);
  //       this.idType = "LocusID";
  //       this.getProteinDetails(locusID,val);
  //       this.showWait = false;
  //     }
      
  //   });
  // }

  fetchJson(filename) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };
    
    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }



  getProteinID(val) {
    //https://p3db.ddns.net:5000/api/mapping/?LocusID=AT1G01&regex=True
    this.showWait = true;
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL + 'mapping/?'+this.idType+'=' + val + '&regex=True'
      ),
    ]).subscribe((result) => {
      let data = result[0]['data'];
      console.log("Api is : ",environment.BASE_API_URL + 'mapping/?'+this.idType+'=' + val + '&regex=True');
      // console.log("Data is  : ",data);
      this.listOfDataProteinIDs = data;
      let i=0;
      this.listOfDataProteinIDs.forEach((item) => {
        i=i+1
        item.icon = item['Phosphorylation Protein(Yes/No)'];
      });

      this.showWait = false;
      console.log("list of protein Id's : ",this.listOfDataProteinIDs);
      if(this.listOfDataProteinIDs != null){
        let id = this.listOfDataProteinIDs[0]['p3db_id'];
        this.getDataByProtein(id);
      }
    });
    
  }

  getDataByProtein(val) {
    this.showWait = true;
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL +
          'get_intproteinid_details/?int_prot_id=' +
          val
          
      ),
      
    ]).subscribe((result) => {
      console.log(environment.BASE_API_URL +
        'get_intproteinid_details/?int_prot_id=' +
        val);
      let data = result[0]['data'];
      this.showWait = false;
      let locusID = data['protein_detail']['fasta_header'].split("|")[0].trim().split(".")[0];
      this.dealInfo(
        data.peptides,
        data.peptide_features,
        data.paper_info,
        data.protein_detail
      );
      let sites = [];
      data.protein_detail.ptm_sites.forEach((element) => {
        sites.push(element.site_in_protein);
      });
      console.log("sites : ",sites);
      this.dealPtmSites(
        sites,
        data.protein_detail.sequence,
        data.protein_detail.internal_protein_id
      );
      this.idType = "LocusID";
        this.getProteinDetails(locusID,val);
        this.showWait = false;
    });
  }

  dealInfo(peptides, features, paperInfo, protein_detail) {
    this.listOfDataPeptides=[]
    for (let item in peptides) {
      let internalProteinId = peptides[item].internal_protein_id;
      let feature = '';
      if(features[item]==null){
        feature = "N/A "+'\n';
      }else{
        features[item].forEach((item) => {
          feature += item.feature_name + ' : ' + item.feature_value + '\n';
        });
      }
      
      console.log("ads : ",protein_detail )
      let hasArray = false;
      for (const key in protein_detail) {
        if (Array.isArray(protein_detail[key])) {
          hasArray = true;
        }
        break;
      }

      if(Object.keys(protein_detail).length === 0){
        peptides[item].fastaHeader = "N/A";
      peptides[item].highlightProteinName = "false";
      peptides[item].peptide_feature = feature;
      peptides[item].description = 'Protein Details:' + '\n';
      peptides[item].description +=
        'Fasta Header: ' + "N/A " + '\n';
      peptides[item].description +=
        'External Id Type: ' + "N/A " + '\n';
      peptides[item].description += 'ID: ' + " N/A" + '\n';
      peptides[item].description += 'Length: ' + " N/A" + '\n';
      peptides[item].description +=
        'Organism Id: ' + " N/A" + '\n' + '\n';
      peptides[item].description += "Paper's Information:" + '\n';
      if (paperInfo[peptides[item].datasource]) {
        peptides[item].description +=
            'Title:' + (paperInfo[peptides[item].datasource].title || "N/A") + '\n';
        peptides[item].description +=
            'Link:' + (paperInfo[peptides[item].datasource].link || "N/A") + '\n';
        peptides[item].description +=
            'Year:' + (paperInfo[peptides[item].datasource].year || "N/A") + '\n';
    } else {
        peptides[item].description += 'Title: N/A\n';
        peptides[item].description += 'Link: N/A\n';
        peptides[item].description += 'Year: N/A\n';
    }
      peptides[item].id = peptides[item].internal_peptide_id;
      this.listOfDataPeptides.push(peptides[item]);
      }else{
        
        if(hasArray){
          
          let fasta_header = protein_detail[peptides[item].internal_protein_id][0].fasta_header;
  
          if (fasta_header.includes('|')) {
              fasta_header = fasta_header.split("|")[0];
          }      
        peptides[item].fastaHeader = fasta_header;
        peptides[item].highlightProteinName = "false";
        peptides[item].peptide_feature = feature;
        peptides[item].description = 'Protein Details:' + '\n';
        peptides[item].description +=
          'Fasta Header: ' + protein_detail[internalProteinId][0].fasta_header + '\n';
        peptides[item].description +=
          'External Id Type: ' + protein_detail[internalProteinId][0].external_id_type + '\n';
        peptides[item].description += 'ID: ' + protein_detail[internalProteinId][0].id + '\n';
        peptides[item].description += 'Length: ' + protein_detail[internalProteinId][0].length + '\n';
        peptides[item].description +=
          'Organism Id: ' + protein_detail[internalProteinId][0].organism_id + '\n' + '\n';
        peptides[item].description += "Paper's Information:" + '\n';
        peptides[item].description +=
          'Title:' + paperInfo[peptides[item].datasource].title + '\n';
        peptides[item].description +=
          'Link:' + paperInfo[peptides[item].datasource].link + '\n';
        peptides[item].description +=
          'Year:' + paperInfo[peptides[item].datasource].year + '\n';
        peptides[item].id = peptides[item].internal_peptide_id;
        this.listOfDataPeptides.push(peptides[item]);
        }
        else{
          let fasta_header = protein_detail.fasta_header;
  
          if (fasta_header.includes('|')) {
              fasta_header = fasta_header.split("|")[0];
          } 
        peptides[item].fastaHeader = fasta_header;
        
          peptides[item].peptide_feature = feature;
        peptides[item].description = 'Protein Details:' + '\n';
        peptides[item].description +=
          'Fasta Header: ' + protein_detail.fasta_header + '\n';
        peptides[item].description +=
          'External Id Type: ' + protein_detail.external_id_type + '\n';
        peptides[item].description += 'ID: ' + protein_detail.id + '\n';
        peptides[item].description += 'Length: ' + protein_detail.length + '\n';
        peptides[item].description +=
          'Organism Id: ' + protein_detail.organism_id + '\n' + '\n';
        peptides[item].description += "Paper's Information:" + '\n';
        peptides[item].description +=
          'Title:' + paperInfo[peptides[item].datasource].title + '\n';
        peptides[item].description +=
          'Link:' + paperInfo[peptides[item].datasource].link + '\n';
        peptides[item].description +=
          'Year:' + paperInfo[peptides[item].datasource].year + '\n';
        peptides[item].id = peptides[item].internal_peptide_id;
        this.listOfDataPeptides.push(peptides[item]);
        }
      }

      
      
      console.log("List of Data Peptides : ",this.listOfDataPeptides);
    }
  }

  dealPtmSites(sties, sequence, id) {
    let phosphorylation_sites = [];
    this.phosphorylation_sites = []
    let ptm_index = 0;
    let index_highLight = sties.map((element) => {
      return element - 1;
    });
    let array_seq = [...sequence];
    array_seq.forEach((element, index) => {
      if (index_highLight.includes(index)) {
        phosphorylation_sites.push({
          name: element,
          class: 'highlight',
          index: '',
          arrow: '',
          tooptip: '',
          protien_id:id
        });
        ptm_index++;
      } else {
        phosphorylation_sites.push({
          name: element,
          class: 'none',
          index: (index + 1) % 10 == 0 ? String(index + 1) : '',
          arrow: (index + 1) % 10 == 0 ? '|' : '',
          tooptip: '',
          protien_id:id
        });
      }
    });
    this.proteinId.push(id);
    this.phosphorylation_sites.push(phosphorylation_sites);
    console.log("List of Phosphorylation sites : ",this.phosphorylation_sites);
  }

  fn_seqHighlight(item) {
    let style = {
      seq_highlight01: true,
      seq_highlight02: false,
    };
    if (item.class == 'highlight') {
      style = {
        seq_highlight01: true,
        seq_highlight02: true,
      };
    }
    return style;
  }

  getProteinDetails(locusid,proteinid){

    // this.noData = false;
    //https://p3db.ddns.net:5000/api/mapping/?LocusID=AT1G01&regex=True
    this.showWait = true;
   
    
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL + 'mapping/?'+this.idType+'=' + locusid + '&regex=True'
      ),
    ]).subscribe((result) => {
      let data = result[0]['data'];
      console.log("data is in protein name : ",data);
      if(data.length == 0){
        console.log("Api is : ",environment.BASE_API_URL + 'mapping/?'+this.idType+'=' + locusid + '&regex=True');
        this.showWait = false;
        // this.noData = true;
        
        // window.alert("No search results ....");
      }else{
        console.log("Api is : ",environment.BASE_API_URL + 'mapping/?'+this.idType+'=' + locusid + '&regex=True');
        console.log("protein id : ",proteinid + " and data is : ",data);
        data.forEach((item) => {
          if(item.p3db_id == proteinid){
            console.log("item is : ",item)
            this.listOfDataProteinIDs.push(item);
          }
        })
        
        // console.log("Data is  : ",data);
        // this.listOfDataProteinIDs = data;
        let i=0;
        this.listOfDataProteinIDs.forEach((item) => {

          i=i+1
          item.icon = item['Phosphorylation Protein(Yes/No)'];
        });
        
        this.showWait = false;
      }
      
    
      
      console.log("list of protein Id's : ",this.listOfDataProteinIDs);
    });
  }

  
}
