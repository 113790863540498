//by ziting
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Breakpoints } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-ppi-network',
  templateUrl: './ppi-network.component.html',
  styleUrls: ['./ppi-network.component.scss'],
})
export class PpiNetworkComponent implements OnInit {
  constructor(private http: HttpClient) {}
  ELEMENT_DATA;
  DETAILS;
  dataType = 'ppi';
  targetProtein = 'Q39255';
  showCyto = false;

  ngOnInit(): void {
    this.getData('Q39255');
  }

  fn_search() {
    let element = document.getElementById('protein_id') as HTMLInputElement;
    this.getData(element.value);
  }

  //handles the joining of the two ajax requests for the edge and node info
  //could be moved out of this component and into cytoscape-example
  getData(id) {
    // console.log(environment.BASE_API_URL + 'networkEdgeJSON.php?node=' + id)
    //forkJoin combines the two observables from http requests and waits for both to finish
    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'network_node?node=' + id),
      this.fetchJson(environment.BASE_API_URL + 'network_edge?node=' + id),
    ]).subscribe((x) => {
      //map edge_info.json and node_info.json to proper cyto format
      console.log(x);
      this.mapEdgesNodes(x[0]['data']['node'], x[1]['data']['edge']); //edge node details
    });
  }

  fetchJson(filename) {
    console.log(filename);
    let headers = new HttpHeaders();

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        console.log(file);
        return file;
      })
    );
  }

  mapEdgesNodes(nodes, edges) {
    console.log('node', nodes);
    console.log('edge', edges);
    // let nodes, edges;
    for (let i = 0; i < nodes.length; i++) {
      // nodes[i]['name'] = nodes[i]['node_name'];
      nodes[i]['id'] = nodes[i]['node_name'];
      nodes[i].network_type = "ppi";
      nodes[i] = { data: nodes[i] };
      
    }
    for (let i = 0; i < edges.length; i++) {
      edges[i].network_type = "ppi";
      edges[i]['source'] = edges[i]['node1'];
      delete edges[i]['node1'];
      edges[i]['target'] = edges[i]['node2'];
      // edges[i]['width'] = Math.log2(edges[i].count)+1;
      delete edges[i]['node2'];

      edges[i] = { data: edges[i], classes: 'tooltip' };
    }

    //design default data and style
    this.ELEMENT_DATA = {
      style: [
        {
          selector: 'node',
          css: {
            content: 'data(node_name)',
            'border-color': '#99ccff',
            'border-opacity': 1,
            'border-width': '1px',
            'background-color': '#99ccff',
          },
        },
        {
          selector: 'node[id = "Q39255"]',
          css: {
            content: 'data(node_name)',
            'border-color': '#cc0000',
            'border-opacity': 1,
            'border-width': '1px',
            'background-color': '#cc0000',
          },
        },
        {
          selector: 'edge',
          css: {
            // content: 'data(node_name)',
            // 'border-color': 'data(color)',
            'target-arrow-shape': 'triangle',
            'line-color': '#666666',
            color: '#000000',
            // 'background-color':'data(color)',
          },
        },
      ],

      elements: {
        nodes: nodes,
        edges: edges,
      },
    };
    this.showCyto = true;
  }
}
