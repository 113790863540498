<mat-card id="component-container" style="padding-bottom: 1px; margin-top: 5%; margin-bottom: 3%;">
  <mat-card>
    <h2 id="title">PPI Network</h2>
  </mat-card>

  <mat-card class='input_card' style="text-align: center;">
    <div class='ttttest'>
      PROTEIN SEARCH:
      <mat-form-field appearance="outline" class='input_protein' style="margin-left: 10px;">
        <!-- <mat-label>Outline form field</mat-label> -->
        <input matInput id="protein_id" placeholder="AT4G38970.1">
      </mat-form-field>

      <button mat-flat-button color="primary" class="button_search" (click)="fn_search()">
        SEARCH
      </button>
    </div>

  </mat-card>

  <mat-card class="card" style="margin-top: 0px; margin-bottom: 10px;">
    <pre style="overflow-x:hidden;">
  Note:
  User may submit a query uniprot ID to search the PPI information.
  The red node indicates the input protein.
  The blue node indicates the protein that has PPI with the input protein.
        </pre>
    <app-cytoscape-network [cytoData]="ELEMENT_DATA"></app-cytoscape-network>
  </mat-card>
</mat-card>
