import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Phosphorylation, Link, nrSequence, Reference } from 'src/app/interfaces/phosphorylation';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';

@Component({
  selector: 'app-phosphorylation',
  templateUrl: './phosphorylation.component.html',
  styleUrls: ['./phosphorylation.component.css'],
  encapsulation: ViewEncapsulation.None,
})

//=============================================================================
// ProteinData Component
//=============================================================================
// Author(s): Michael Fisher (June 2021)
//=============================================================================
// This component is designed to provide functionality for the 
// https://dev.p3db.org/protein-data/:id page. The following sections are divided
// up based on role and tasks for the functionality of the page, and have their
// respective fields and methods assigned as such
//=============================================================================
// == Database access:
// Fields:
// public phosphorylationData?: Phosphorylation;
//
// Methods:
// private pullPhosphorylation(): void 
//
// == JSON Processing Functions
// Fields:
//
// Methods:
// private unpackPhosphorylation(any): Phosphorylation 
// private unpackNRSequence(any): nrSequence[] 
// private unpackLink(any): Link 
// private unpackReferences(any): Reference[] 
//
// == Sequence Formatting and highlighing :
// Fields:
// private surroundingSeq?: string;
// private activeElement?: number;
//
// Methods:
// public highlightLocation(string, boolean = true): string 
//
// == Table Fromatting
// Fields:
// public proteinColumn1 : string[]
// public proteinColumn2 : string[]
// public sequenceColumns : string[]
// public nrColumns: string[]
// public displayField?: Number | null
//
// Methods:
// private generateAccessionColumns(): void
//
// == Misc
// Fields:
// public phosphoMessage?: string;
// private id?: string;
//
// Methods:
// public toggle(number): void 
// private sanitize(string): string 
//=============================================================================

export class PhosphorylationComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              // private router: Router,
              private database : DatabaseConnService,
              // private phosphorylationService : PhosphorylationService
              ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id
    this.surroundingSeq = this.route.snapshot.params.surroundingSeq;
    this.activeElement = Number(this.route.snapshot.params.activeElement);
    this.pullPhosphorylation();
  }

  //===========================================================================
  // Database access
  //===========================================================================
  public phosphorylationData?: Phosphorylation;

  private pullPhosphorylation(): void {
    this.database.get('phosphosite', [this.id, ""])
    .subscribe(
      proteinData => { console.log(proteinData),
                        this.phosphorylationData = this.unpackPhosphorylation(proteinData['data']);
                       this.generateAccessionColumns()
                       console.log(this.phosphorylationData)},
      error => { this.phosphoMessage = error.message;}
    )
  }

  // ==========================================================================
  // JSON processing functions
  //===========================================================================

  private unpackPhosphorylation(data: any): Phosphorylation {
    let phosphorylation: Phosphorylation = {
      accessions : data['accessions'],
      nrSequence: this.unpackNRSequence(data['nr_sequence']),
      proteinID: this.sanitize(data['protein_id']),
      description: this.sanitize(data['description']),
      organism: this.sanitize(data['organism']),
      location: this.sanitize(data['location']),
      surroundingSequence: this.sanitize(data['surrounding_sequence']),
      numSpectra: this.sanitize(data['num_of_spectra']),
      references : this.unpackReferences(data['references'])
    }

    return phosphorylation;
  }

  private unpackNRSequence(data: any): nrSequence[] {
    let sequences = [];

    for (let i = 0; i < data.length; ++i)
    { 
      let nrsequence: nrSequence = {
        sequence: this.sanitize(data[i]['sequence']),
        locationInPeptide: this.sanitize(data[i]['location_in_peptide']),
        link: this.unpackLink(data[i]['link'])
      }
      sequences.push(nrsequence);
    }


    return sequences;
  }

  private unpackLink(data: any): Link {
    let link: Link = {
      pnrseq: this.sanitize(data['pnrseq']),
      pro: this.sanitize(data['pro']),
      loc: this.sanitize(data['loc'])
    }

    return link;
  }

  private unpackReferences(data: any): Reference[] {
    let references = [];

    for (let key in data)
    {
      let ref : Reference = {
        num : this.sanitize(key),
        data : this.sanitize(data[key])
      }

      references.push(ref);
    }
    
    return references;
  }

  //===========================================================================
  // Sequence Formatting and highlighing 
  //===========================================================================
  private surroundingSeq?: string;
  private activeElement?: number;

  public highlightLocation(data: string, tag = true): string {
    let new_string = '';

    if (tag)
    {
      for (let i = 0; i < data.length; ++i)
      {
        if (data[i + 1] == '#')
        {
          new_string += ('<span class="highlightText">' + data[i] + '</span>');
          ++i;
        } 
        else
        {
          new_string += data[i];
        }
      }
    }
    else 
    {
      new_string = data.slice(0, this.activeElement) + 
                   '<span class="highlightText">' + data[this.activeElement] + '</span>' +
                   data.slice(this.activeElement + 1,data.length);
    } 

    return new_string;
  }

  //===========================================================================
  // Table formatting
  //===========================================================================
  public proteinColumn1 = [];
  public proteinColumn2 = [];
  public sequenceColumns = ['sequence', 'sequenceData'];
  public nrColumns = ['sequence', 'locationInPeptide', 'link'];
  public displayField?: Number | null;

  private generateAccessionColumns(): void {
    for (let i = 0; i < this.phosphorylationData.accessions.length; ++i)
    {
      if (i % 2 == 0) this.proteinColumn1.push(this.phosphorylationData.accessions[i]);
      else this.proteinColumn2.push(this.phosphorylationData.accessions[i]);
    }
  }

  //============================================================================
  // Misc 
  //============================================================================
  public phosphoMessage?: string;
  private id?: string;

  public toggle(option: number): void 
  { 
    if (option == this.displayField) this.displayField = null;
    else this.displayField = option;
  }
  private sanitize(obj: string): string { return obj ? String(obj).replace('\/', '/') : 'N/A'; }

}
