import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-count-summary',
  templateUrl: './count-summary.component.html',
  styleUrls: ['./count-summary.component.css'],
})
export class CountSummaryComponent implements OnInit {

  ELEMENT_DATA = null;
  displayChart: boolean = false;
  requestError: string;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.fetchJson(environment.BASE_API_URL + 'datasets/')
      .subscribe(
        jsonData => {
          for (let i = 0; i < jsonData['data'].length; i++ )
            {
              let linkName = "pubmed-id/" + jsonData['data'][i].PubmedID;
              jsonData['data'][i]['link'] = linkName;
            }

          this.ELEMENT_DATA = {
            name: ["Inverstigator", "Organism", "OrganismID", "PTM", "PubmedID", "Tissue", "Title", "Year", "internal_peptide_id_count", "internal_protein_id_count"],
            display: ["Investigator", "Organism", "Organism ID", "PTM", "Pubmed ID", "Tissue", "Title", "Year", "Peptide Counts", "Protein Counts"],
            data: jsonData['data'],
          };
        },
        error => {
          this.requestError = error;
        }
      );
  }

  fetchJson(filename: string): Observable<any> {
    let headers = new HttpHeaders();
    const options = {
      responseType: 'json' as const,
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      }),
      catchError((error) => {
        console.error('Error fetching JSON:', error);
        this.requestError = 'An error occurred while fetching data';
        return throwError(() => error);
      })
    );
  }
}
