import { ContentObserver } from '@angular/cdk/observers';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-protien-blast-search',
  templateUrl: './protien-blast-search.component.html',
  styleUrls: ['./protien-blast-search.component.css']
})
export class ProtienBlastSearchComponent implements OnInit {

  constructor(private httpClient:HttpClient) { }
  searchIndex = 0;
  inputValue:string;
  listOfBlastTabularResults=[];
  phosphorylation_sites = [];
  proteinId = [];
  showWait = false;
  columnKeysProteinBlast ={
    'P3DB ID' : {"val":'internal_protein_id',"tooltip":"internal protein id"},
    'Subject ID' : {"val":'subject_id', "tooltip":"subject id "},
    'Alignment Length' :{"val":'alignment_length',"tooltip":"alignment length"},
    'Bit Score' : {"val": 'bit_score', "tooltip":"bit score"},
    'E value' : {"val":'e_value',"tooltip":"E value"},
    'Gap Openings' : {"val":'gap_openings', "tooltip":"gap openings"},
    'Mismatches' : {"val":'mismatches', "tooltip":"mismatches"},
    'Percentage Identity' : {"val":'percentage_identity',"tooltip":"percentage identity"},
    'Query End' :{"val": 'query_end',"tooltip":"query end"},
    'Query Id' : {"val":'query_id',"tooltip":"query id "},
    'Query Start' : {"val":'query_start',"tooltip":"query start"},
    'Subject End' :{"val":'subject_end', "tooltip":"subject end "},
    'Subject Start' : {"val":'subject_start', "tooltip":"subject start"}
    
  }
  @Output() handleEvent = new EventEmitter();
  ngOnInit(): void {
  }

  fetchJson(file) {
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };
    return this.httpClient.get(file, options).pipe(
      map((file_name) => {
        return file_name;
      })
    );
  }
  handle(){
    this.handleEvent.emit({value:this.inputValue,label:"4"})
  }

  fn_search() {
    console.log("input value is : ",this.inputValue);
    this.getDataBySequenceBlast(this.inputValue);
  }

  dealPtmSites(sties, sequence, id) {
    let phosphorylation_sites = [];
    this.phosphorylation_sites = []
    let ptm_index = 0;
    let index_highLight = sties.map((element) => {
      return element - 1;
    });
    let array_seq = [...sequence];
    array_seq.forEach((element, index) => {
      if (index_highLight.includes(index)) {
        phosphorylation_sites.push({
          name: element,
          class: 'highlight',
          index: '',
          arrow: '',
          tooptip: '',
          protien_id:id
        });
        ptm_index++;
      } else {
        phosphorylation_sites.push({
          name: element,
          class: 'none',
          index: (index + 1) % 10 == 0 ? String(index + 1) : '',
          arrow: (index + 1) % 10 == 0 ? '|' : '',
          tooptip: '',
          protien_id:id
        });
      }
    });
    this.proteinId.push(id);
    this.phosphorylation_sites.push(phosphorylation_sites);
    console.log("List of Phosphorylation sites : ",this.phosphorylation_sites);
  }

  getDataBySequenceBlast(val) {
    this.showWait = true;
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL + 'blastppepmatch/?peptide=' + val
      ),
      
    ]).subscribe((result) => {
      
      let data = result[0]['data'];
      console.log("ptm blast data is :",data);
      // The below for loop is to populate the blast_tabular_results into listOfBlastTabularResults list.
      for(let element of data.blast_tabular_results) {
        this.listOfBlastTabularResults.push(element);
      }
      // for (let item in data.protein_detail) {
      //   let sites = [];
      //   data.protein_detail[item][0].ptm_sites.forEach((element) => {
      //     sites.push(element.site_in_protein);
      //   });
      //   this.dealPtmSites(
      //     sites,
      //     data.protein_detail[item][0].sequence,
      //     data.protein_detail[item][0].internal_protein_id
      //   );
      // }
      this.showWait = false;
    });
    console.log("list of blast tabular Resuilts is : ", this.listOfBlastTabularResults);
  }
}