<mat-card class="container-card">
    <mat-card class="background-card">
        <div style="padding-bottom: 1%;">
            <mat-card>
                <h1>Peptide Data</h1>
            </mat-card>
        </div>

        <div>
            <mat-card *ngif = "!peptideData">
                <h1>Loading Data...</h1>
            </mat-card>
            <mat-card *ngIf = "peptideData">
                <table style="font-size: large;">
                    <h2>Details</h2>
                    <tr>
                        <td>Description</td><td>{{ peptideData.description }}</td>
                    </tr>
                    <tr>
                        <td>Organism</td><td>{{ peptideData.organism }}</td>
                    </tr>
                    <tr>
                        <td>No. of Spectra</td><td>{{ peptideData.numSpectra }}</td>
                    </tr>
                    <tr>
                        <td>NR Spectra</td><td> <div [innerHtml] = peptideData.nrSequence></div></td>
                    </tr>
                </table>
                <br>
                <br>
                <table style="font-size: large;">
                    <h2>Peptide Information</h2>
                    <tr>
                        <td style="width: 20%;">Phosphosites</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(1)">
                                Display
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <td style="width: 20%;">Accesions</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(2)">
                                Display
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <td style="width: 20%;">References</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(3)">
                                Display
                            </button>
                        </td>
                    </tr>

                </table>
                
            </mat-card>
        </div>

        <div style="padding-top: 1%;"  *ngIf = "displayField == 1">
            <mat-card>
                <h1>Phosphosites</h1>
                <table mat-table
                    [dataSource]="peptideData.phosphosites" multiTemplateDataRows>
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef> <h1> Position </h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.locationInProtein }} 

                            </td>
                            
                        </ng-container>

                        <ng-container matColumnDef="aminoAcid">
                            <th mat-header-cell *matHeaderCellDef> <h1> Amino Acid </h1> </th>
                            <td mat-cell *matCellDef="let protein"> {{ protein.aminoAcid }} </td>
                        </ng-container>

                        <ng-container matColumnDef="phosphosite">
                            <th mat-header-cell *matHeaderCellDef> <h1> Phosphosite Data </h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                <button mat-raised-button class="button-color"
                                [routerLink] = "['/phosphorylation-data/', protein.phosphositeIDLink]">
                                    Details
                                </button></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="phosphositeColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: phosphositeColumns;"
                            class="example-element-row"></tr>
                </table>
            </mat-card>
        </div>

        <div style="padding-top: 1%; text-align: center;"  *ngIf = "displayField == 2">
            <mat-card style="margin: auto;">
                <h1 style="text-align: left;">
                    Accessions
                </h1>
                <div style="display: inline-block;">
                    <table mat-table style="text-align: left;"
                        [dataSource]="proteinColumn1" multiTemplateDataRows
                        >
                            <ng-container matColumnDef="recordCol1">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let protein"> 
                                    {{ protein}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="['recordCol1']"></tr>
                            <tr mat-row *matRowDef="let element; columns: ['recordCol1'];"
                                class="example-element-row"></tr>
                    </table>
                </div>

                <div style="display: inline-block;">
                    <table mat-table style="text-align: right;"
                        [dataSource]="proteinColumn2" multiTemplateDataRows
                        style="display: inline-block;">
                            <ng-container matColumnDef="recordCol1">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let protein"> 
                                    {{ protein}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="['recordCol1']"></tr>
                            <tr mat-row *matRowDef="let element; columns: ['recordCol1'];"
                                class="example-element-row"></tr>
                    </table>
                </div>
            </mat-card>
        </div>

        <div style="padding-top: 1%;"  *ngIf = "displayField == 3">
            <mat-card>
                <h1>References</h1>
                <table mat-table
                    [dataSource]="peptideData.references" multiTemplateDataRows>
                        <ng-container matColumnDef="ref">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.data}} 

                            </td>
                            
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="['ref']"></tr>
                        <tr mat-row *matRowDef="let element; columns: ['ref'];"
                            class="example-element-row"></tr>
                </table>
            </mat-card>
        </div>
        
    </mat-card>
</mat-card>
