import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { PageviewsService } from 'src/app/Services/pageviews.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  show_citation = false;
  view_count: string = '';

  constructor(private clipboard: Clipboard) { }

  ngOnInit(): void {



  }

  // for some reason, giving the console error of "a form field element should have an id or name attribute" but still works properly
  copyCitation(event: Event) {
    this.clipboard.copy('Yao Q, Ge H, Wu S, Zhang N, Chen W, Xu C, Gao J, Thelen JJ, Xu D. (2013) P3DB 3.0: From plant phosphorylation sites to protein networks.');
    this.show_citation = false;
   }

  openCitationLink() {
    this.show_citation = false;
    // window.open("http://www.ncbi.nlm.nih.gov/pubmed/24243849", '_blank');
}


}
