import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import{ HttpClientModule } from '@angular/common/http';
import {MatDividerModule} from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { cytoscape } from 'cytoscape/dist/cytoscape.esm.js';
import { NgTickerModule } from 'ng-ticker';
import { MatChipsModule} from '@angular/material/chips';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { HomeComponent } from './pages/home/home.component';
import { HomeSearchComponent } from './components/home-search/home-search.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CiteComponent } from './pages/cite/cite.component';
import { EventComponent } from './components/event/event.component';
import { SearchComponent } from './pages/search/search.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NetworkviewerComponent } from './pages/networkviewer-example/networkviewer.component';
import { MatTableModule } from '@angular/material/table';
import { TableComponent } from './components/table-countSummary/table.component';
import { CytoscapePTMComponent } from './components/cytoscape-ptmnetwork/cytoscape.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import {MatMenuModule} from '@angular/material/menu';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import "@fontsource/bungee-shade";
import { FooterComponent } from './components/footer/footer.component';
import {MatListModule} from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';

import {MatTreeModule} from '@angular/material/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Show3dComponent } from './pages/show3d/show3d.component';
import { CountSummaryComponent } from './pages/count-summary/count-summary.component';
import { MusiteComponent } from './pages/musite/musite.component';
import { MusitedeepPredictionComponent } from './components/musitedeep-prediction/musitedeep-prediction.component';
import { NetworkComponent } from './pages/ptmnetwork/network.component';
// import { PhosphoSiteNetworkComponent } from './pages/phospho-site-network/phospho-site-network.component';

//ziting
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { OrthologConservationComponent } from './pages/ortholog-conservation/ortholog-conservation.component';
import { PpiNetworkComponent } from './pages/ppi-network/ppi-network.component';

//michael
import { PhosphoproteinBrowseComponent } from './pages/phosphoprotein-browse/phosphoproteinBrowse.component';
import { KinaseGenedataComponent } from './pages/kinase-genedata/kinase-genedata.component';
import { KinaseclientComponent } from './pages/kinaseclient/kinaseclient.component';
import { OrganismSearchComponent } from './pages/organism-search/organism-search.component';
import { ProteinDataComponent } from './pages/protein-data/protein-data.component';
import { PhosphorylationComponent } from './pages/phosphorylation/phosphorylation.component';
import { PeptideDataComponent } from './pages/peptide-data/peptide-data.component';
import { CytoscapeNetworkComponent } from './components/cytoscape-network/cytoscape-network.component';
import { KicnetworkComponent } from './pages/kicnetwork/kicnetwork.component';
import { PhylogeneticTreeComponent } from './pages/phylogenetic-tree/phylogenetic-tree.component';
import { UniversalSearchComponent } from './pages/universal-search/universal-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PeptideConservationSearch } from './pages/Peptide-Conservation-Search/Peptide-Conservation-Search.component';

//zhangzhi
import { ProteinConservationRegionSearchComponent } from './pages/Protein-Conservation-Region-Search/Protein-Conservation-Region-Search.component';
import { GlobeComponent } from './components/globe/globe.component';
import { PathwayEntranceComponent } from './pages/pathway-entrance/pathway-entrance.component';
import { PathwayViewerComponent } from './pages/pathway-viewer/pathway-viewer.component';
import { PathwayDialogComponent } from './pages/pathway-viewer/pathway-dialog/pathway-dialog.component';
// import { TableSequenceComponent } from './pages/table-sequence/table-sequence.component';
import { TeampageComponent } from './pages/teampage/teampage.component';
import { UsermanualComponent } from './pages/usermanual/usermanual.component';

import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { PtmPeptidesComponent } from './pages/ptm-peptides/ptm-peptides.component';


import { NzButtonModule } from 'ng-zorro-antd/button';
import { BlastPtmPeptidesComponent } from './pages/blast-ptm-peptides/blast-ptm-peptides.component';
import { P3dbTableComponent } from './components/p3db-table/p3db-table.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzInputModule } from 'ng-zorro-antd/input';
import { P3dbInputComponent } from './components/p3db-input-search/p3db-input.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzMessageService } from 'ng-zorro-antd/message';
import { StorageService } from './Services/storage.service';
import { DatabaseConnService } from './Services/database-conn.service';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);
import { en_US, NzI18nService,NZ_I18N } from 'ng-zorro-antd/i18n';
import { P3dbCascaderInputSearchComponent } from './components/p3db-cascader-input-search/p3db-cascader-input-search.component';
import { MessageComponent } from './pages/message/message.component';
import { ProtienBlastSearchComponent } from './pages/protien-blast-search/protien-blast-search.component';
import { ModalComponent } from './components/modal/modal.component';

import { SharedService } from './shared.service';
import { MotifComponent } from './pages/motif/motif.component';
import { ProteinLogoComponent } from './pages/protein-logo/protein-logo.component';
import { PhylogeneticTreeDatasetComponent } from './pages/phylogenetic-tree-dataset/phylogenetic-tree-dataset.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CsvDisplayComponent } from './csv-display/csv-display.component';
import { MatSlider, MatSliderModule } from '@angular/material/slider';
import { StructureViewerComponent } from './pages/structure-viewer/structure-viewer.component';
import { IntermediatePageComponent } from './pages/intermediate-page/intermediate-page.component';
import { BatchSearchComponent } from './pages/batch-search/batch-search.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PubmedIDComponent } from './pages/pubmed-id/pubmed-id.component';


@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    // ScrollingTextComponent,
    HomeComponent,
    HomeSearchComponent,
    CarouselComponent,
    CiteComponent,
    EventComponent,
    SearchComponent,
    NetworkviewerComponent,
    KinaseclientComponent,
    TableComponent,
    CytoscapePTMComponent,
    FooterComponent,


    KinaseGenedataComponent,

    OrganismSearchComponent,

    Show3dComponent,

    CountSummaryComponent,

    MusiteComponent,

    MusitedeepPredictionComponent,

    NetworkComponent,

    OrthologConservationComponent,

    PpiNetworkComponent,

    PhosphorylationComponent,

    PeptideDataComponent,

    PhosphoproteinBrowseComponent,
    ProteinDataComponent,
    CytoscapeNetworkComponent,
    KicnetworkComponent,
    PhylogeneticTreeComponent,
    UniversalSearchComponent,
      ProteinConservationRegionSearchComponent,
      PeptideConservationSearch,
      // TableSequenceComponent,
      GlobeComponent,
      PathwayEntranceComponent,
      PathwayViewerComponent,
      PathwayDialogComponent,
      // TableSequenceComponent,
      TeampageComponent,
      UsermanualComponent,
      PtmPeptidesComponent,
      BlastPtmPeptidesComponent,
      P3dbTableComponent,
      P3dbInputComponent,
      P3dbCascaderInputSearchComponent,
      MessageComponent,
      ProtienBlastSearchComponent,
      MotifComponent,
      ModalComponent,
      ProteinLogoComponent,
      PhylogeneticTreeDatasetComponent,
      ContactComponent,
      CsvDisplayComponent,
      StructureViewerComponent,
      IntermediatePageComponent,
      BatchSearchComponent,
      PubmedIDComponent,
    // PhosphoSiteNetworkComponent,

  ],
  imports: [
    NzButtonModule,
    NzSpinModule,
    NzDropDownModule,
    NzIconModule,
    NzToolTipModule,
    NzTableModule,
    NzPaginationModule,
    NzInputModule,
    NzSelectModule,
    NzCascaderModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // FlexLayoutModule,
    FormsModule,
    IvyCarouselModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCardModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    HttpClientModule,
    MatGridListModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonToggleModule,
    MatSelectModule,
    AppRoutingModule,
    NgbModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatDividerModule,
    MDBBootstrapModule,
    NgMatSearchBarModule,
    MatMenuModule,
    FlexLayoutModule,
    MatTabsModule,
    MatTreeModule,
    ClipboardModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSnackBarModule
  ],
  providers: [StorageService,NzMessageService,{provide:NZ_I18N, useValue: en_US},SharedService,DatabaseConnService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
 }

