import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'p3dbInputSearch',
  templateUrl: './p3db-input.component.html',
  styleUrls: ['./p3db-input.component.css']
})
export class P3dbInputComponent implements OnInit {

  constructor() { }
  public inputValue='';
  public currentLabel=''
  @Input()labels: Array<any>; // format [{value:1, label:'test'},{value:2, label:'test2'}]
  @Output() handleEvent = new EventEmitter();
  ngOnInit(): void {
    this.currentLabel = this.labels[0].value
  }
  handle(){
    this.handleEvent.emit({value:this.inputValue,label:this.currentLabel})
  }

}
