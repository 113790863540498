<mat-card id="component-container" >
    <mat-card>
      <h2 id="title">PTM Peptides(blast)</h2>
    </mat-card>
    <mat-card class='input_card' style="text-align: center; margin-top: 10px;">
      <div class='ttttest'>
        SEQUENCE SEARCH:
        <mat-form-field appearance="outline" class='input_protein' style="margin-left: 10px;">
          <!-- <mat-label>Outline form field</mat-label> -->
          <input matInput id="sequence" placeholder="AT1G01110.2">
        </mat-form-field>
        <button mat-flat-button color="primary" class="button_search" (click)="sequence_search()">
          Search
        </button>
        <!-- <app-p3db-table [columns]="columns" [dataSource]="listOfData"></app-p3db-table>
        <button nz-button nzType="primary">Primary</button>
        <br>
        <button nz-button nzType="default">Primary</button>  -->
  
      </div>
  
    </mat-card>
    <mat-card *ngIf="showWait" id="title-container" style="margin-top: 10px; overflow-x: scroll;">
          <mat-spinner *ngIf="showWait" style="margin-left: 45%;"></mat-spinner>
    </mat-card>
  
  
    <mat-card *ngIf="fasta_header" style="margin-top: 10px; overflow-x: scroll;">
      <h3 id="title">Protein Details</h3>
    </mat-card>
    <mat-card *ngIf="fasta_header" id="title-container" >
      {{fasta_header}}
    </mat-card>
  
    <mat-card *ngIf="PTM_PEPTIDES_DATA" style="margin-top: 10px; overflow-x: scroll;">
      <h3 id="title">Phosphorylation Peptides in Protein</h3>
    </mat-card>
    <mat-card *ngIf="PTM_PEPTIDES_DATA" id="title-container">
      <app-table *ngIf="PTM_PEPTIDES_DATA" [tableData]="PTM_PEPTIDES_DATA">
      </app-table>
      <!-- <app-table *ngIf="!showWait" [tableData]="PTM_SITES_DATA"></app-table> -->
    </mat-card>
  
    <mat-card *ngIf="PTM_SITES_DATA" style="margin-top: 10px; overflow-x: scroll;">
      <h3 id="title">Phosphorylation Site in Protein</h3>
    </mat-card>
    <mat-card *ngIf="PTM_SITES_DATA" id="title-container" >
      <div class="scrooll">
        <div style="display: inline-block;">
          <!-- <span style="color:red">* Mouse over characters to view details</span> -->
          <br />
          <!-- <br /> -->
          <div>
            <div style="width: 80px; display: inline-block;text-align: center;color: #548Ddf;">
              <p>Sequence:</p>
            </div>
            <span *ngFor="let item of test;let i = index" [ngClass]="fn_seqHighlight(item)"
              title="{{test[i].tooptip}}">{{item.name}}</span>
          </div>
    
          <div>
            <div style="width: 80px; display: inline-block;text-align: center;">
              <p>|</p>
            </div>
            <span *ngFor="let item of test" [ngClass]="{'seq_highlight01':'true'}">{{item.arrow}}</span>
          </div>
    
          <div>
            <div style="width: 80px;display: inline-block;text-align: center;color: #548Ddf;">
              <p>Position:</p>
            </div>
            <span *ngFor="let item of test" [ngClass]="{'seq_highlight01':'true'}">{{item.index}}</span>
          </div>
        </div>
      </div>
    </mat-card>
    
  </mat-card>
  <br><br>