import { Component, OnInit } from '@angular/core';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-universal-search',
  templateUrl: './universal-search.component.html',
  styleUrls: ['./universal-search.component.css']
})
export class UniversalSearchComponent implements OnInit {

  constructor(private dbaccess: DatabaseConnService) { }

  select_options : Array<string> = [];
  selected_option: string;
  query: string = 'P08824';
  results: any;
  search_records: Array<any>;
  identifierControl = new FormControl(this.query);

  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];

  datatable = ['headers', 'data']


  ngOnInit(): void {
    this.dbaccess.get('idMappingKeys', []).subscribe(
      data => { 
        for (let i in data['types'])
        {
          this.select_options.push(i);
        }
        this.selected_option = this.select_options[10];
      }
    );
  }

  user_search(): void
  {
    this.dbaccess.getUniveral(this.selected_option, this.query).subscribe(
      data => { this.results = data['data'][0]; 

                let transform = []
                for (let i in this.results)
                {
                  transform.push({'key' : i, 'value' : this.results[i]})
                }
                this.results = transform

      }
    );

  }

  lazy_load(event):void{
    if (this.query === '' || event.key == "ArrowUp"
    || event.key == "ArrowDown" || event.key == "ArrowLeft" || event.key == "ArrowDown") { return; }

    this.dbaccess.getUniveral(this.selected_option, this.query, true).subscribe(
      data => { this.search_records = data['data']; }
    );
  }

  select_option(option: string): void
  {
    this.selected_option = option;
  }

}
