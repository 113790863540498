import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { style } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSliderModule } from '@angular/material/slider';


@Component({
  selector: 'app-musitedeep-prediction',
  templateUrl: './musitedeep-prediction.component.html',
  styleUrls: ['./musitedeep-prediction.component.css'],
})
export class MusitedeepPredictionComponent implements OnInit {
  modeloptions = [
    'Phosphoserine_Phosphothreonine',
    'Phosphotyrosine',
    'N-linked_glycosylation',
    'O-linked_glycosylation',
    'Ubiquitination',
    'SUMOylation',
    'N6-acetyllysine',
    'Methylarginine',
    'Methyllysine',
    'Pyrrolidone_carboxylic_acid',
    'S-palmitoyl_cysteine',
    'Hydroxyproline',
    'Hydroxylysine',
  ];

  test = [
    // {name:"a", class:"highlight", index:""},
    // {name:'b', class:'none', index:""}
  ];
  positions;
  showResult = false;
  showWait = false;
  download_result;
  sliderValue: number = .5; 

  sequence;
  toppings = new FormControl({ value: 'Mushroom', disabled: false });
  toppingList: string[] = [
    'Extra cheese',
    'Mushroom',
    'Onion',
    'Pepperoni',
    'Sausage',
    'Tomato',
  ];

  constructor(private http: HttpClient, private router: Router) {}
  ELEMENT_DATA;


  ngOnInit(): void {}

  fn_predictSeq() {
    this.test = [];
    let element = document.getElementById('seq') as HTMLInputElement;
    // console.log(element.value);
    if (element.value != '') {
      this.fetchInfo(element.value);
    } else {
      alert('Please input sequence!');
    }
  }

  fn_example() {
    // console.log('example');
    let element = document.getElementById('seq') as HTMLInputElement;
    element.value = 'ETGQSVNDPGNMSFVKETVDKLLKGYDIRLRPDFGGPP';
  }

  fetchInfo(seq) {
    // console.log('start');
    let model = this.modeloptions[0] + ';' + this.modeloptions[3];
    let seq2 =
      'MERSPAVCCQDPRAELVERVAAISVAHLEEAEEGPEPASNGVDPPPRARAASVIPGSASRPTPVRPSLSARKFSLQERPAGSCLEAQVGPYSTG';
    this.sequence = seq;
    let url = '/api/musitedeep/' + model + '/' + seq;
    let headers = new HttpHeaders();

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    this.showWait = true;
    this.showResult = false;

    this.http.get(url, options).subscribe((data: any) => {
      // console.log(data)
      this.showResult = true;
      this.showWait = false;
      this.dealData(data);
      this.download_result = data;
    });
  }

  fn_show3D() {
    //let url2 = "https://g2s.genomenexus.org/api/alignments/residueMapping?sequence="+this.sequence+"&positionList="+4;
    //let out = "";
    this.router.navigate([
      'show3dTable',
      {
        urlg2s:
          'https://g2s.genomenexus.org/api/alignments/residueMapping?sequence=' +
          this.sequence +
          '&positionList=' +
          this.positions,
      },
    ]);
    // window.open("https://www.musite.net/show3dtable/2021-03-22T21:24:55.826Zawbtab4v2wg2021-06-09T04:20:08.904Z")
  }

  fn_download() {
    let datastr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(this.download_result));
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', datastr);
    downloadAnchorNode.setAttribute('download', 'Result.json');
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  dealData(data) {
    let ptm_index = 0;
    let ptmscore = 'Cutoff=0.5';
    // console.log(data.Results);
    let index_highLight = data.Results.map((element) => {
      return element.Residue - 1;
    });
    let array_positions = data.Results.map((element) => {
      return element.Residue;
    });
    this.positions = array_positions.toString();
    let array_seq = [...data.querySeq];
    // console.log(index_highLight);
    array_seq.forEach((element, index) => {
      // console.log('index: ', index);
      if (index_highLight.includes(index)) {
        this.test.push({
          name: element,
          class: 'highlight',
          index: String(index + 1),
          arrow: '|',
          tooltip: data.Results[ptm_index][ptmscore],
        });
        ptm_index++;
      } else {
        this.test.push({
          name: element,
          class: 'none',
          // index: (index + 1) % 10 == 0 ? String(index + 1) : '',
          index: '',
          // arrow: (index + 1) % 10 == 0 ? '|' : '',
          arrow: '',

          tooltip: '',
        });
      }
    });
    // console.log(this.test);
    // parse the data
    const parsedData = [];

    data.Results.forEach(obj => {
      // parsing the 'Cutoff=0.5' element
      if (obj["Cutoff=0.5"].includes(';')) {
        const splitValues = obj["Cutoff=0.5"].split(';');
        splitValues.forEach(value => {
          if (value.includes(':')) {
            const [modification_name, probability] = value.split(':');
            const newObj = { 
              ...obj, 
              modification_name: modification_name.trim(),
              probability: probability.trim()
            };
            delete newObj["Cutoff=0.5"];
            parsedData.push(newObj);
          } else {
            parsedData.push(obj);
          }
        });
      } else {
        parsedData.push(obj);
      }
    });

    // console.log(parsedData);

    this.ELEMENT_DATA = {
      name: ["Residue", "PTMscores", "modification_name", "probability"],
      display: ["Position", "Amino Acid", "Modification Name", "Probability"],
      data: parsedData,
    };

  }

  fn_seqHighlight(item) {
    // console.log(item);
    let color = this.get_color_from_probability(item);
    let style = {
      'background-color': color, 
      'display' : 'inline-block',
      'width': '15px',
      'margin-left': '5px',
      'text-align': 'center', 
      'font-weight' : 'normal',

    };
    if (item.class == 'highlight') {
      let color = this.get_color_from_probability(item);
      style = {
        'background-color': color, 
        'display' : 'inline-block',
        'width': '15px',
        'margin-left': '5px',
        'text-align': 'center', 
        'font-weight' : 'bold',

      };
    }
    return style;
  }

  get_color_from_probability(item){
    if (item.tooltip)
    {
      let filteredString = item.tooltip.replace(/[^\d.;]/g, '');

    let numbers = filteredString.split(';').map(Number);

    // console.log('numbers: ', numbers);

    if (numbers.some(number => number > this.sliderValue)) {
      // let sum = numbers.reduce((acc, curr) => acc + curr, 0);
      // let average = sum / numbers.length;
      let maxNumber = Math.max(...numbers);

      
      let red = 255;
      let green = 255 - (maxNumber*255); 
      let string = 'rgb(' + red + ', ' + green + ', ' + '0' + ')';
      // let string = 'rgb(' + red + ', ' + green + ', ' + green + ')';
  
      /// NEED TO TEST
  
        return string;    
      } 
      else {
        return 'transparent';
  }

    }
    else {
    return 'transparent';
  }
  }
}
