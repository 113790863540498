<!-- <mat-card id="component-container" style="padding-bottom: 1px; margin-top: 5%; margin-bottom: 3%;">
    <mat-card>
      <h2 id="title">Post a Question</h2>
    </mat-card>
  
    <mat-card class='input_card' style="text-align: center;">
      <div class='ttttest'>
        <label>Question:</label>
        <app-p3db-cascader-input-search>Question:</app-p3db-cascader-input-search>
        <mat-form-field appearance="outline" class='input_protein' style="margin-left: 10px;">
          <input matInput id="question" [(ngModel)]="inputValue" placeholder="enter the question here:">
        </mat-form-field>
  
        <button mat-flat-button color="primary" class="button_search" (click)="fn_search()">
          SEARCH
        </button>
      </div>
    </mat-card>
    <mat-card class="card" style="margin-top: 0px; margin-bottom: 10px;">
        <pre style="overflow-x:scroll; word-wrap: break-word; white-space: pre-wrap;">
            {{messageContent}}    
        </pre>
    </mat-card>
</mat-card>  -->

<mat-card id="message-container">
  <mat-card id="title-container">
    <h1 id="title" style="text-align: center;"><b>AI Q&A</b></h1>
  </mat-card>
  <mat-card id="title-container" style="margin-top: 10px;">
    <div class='input_protein'>
      <app-p3db-cascader-input-search id=question [labels]="searchLabels" (handleEvent)="fn_search($event)"></app-p3db-cascader-input-search>
    </div>
    <br/>
    <nz-input-group nzSearch nzSize="large">
      <input type="text" [(ngModel)]="inputValue" nz-input placeholder="input search text" id="message-input"/>
    </nz-input-group>
    <br/>
  </mat-card>
  <mat-card id="title-container" style="margin-top: 10px;">
    <h2>Answer is:</h2>
    <div class="information" style="overflow-x:scroll; word-wrap: break-word; white-space: pre-wrap;">{{messageContent}}</div>
  </mat-card>
</mat-card>
<br/>



<!-- <div class="search-div">
  <mat-card class="background-card">
  <mat-card class="message_card">
    <h2 id="title" style="text-align: center;">Shoot a Question</h2>
  </mat-card>
  <mat-card class='message_card' style="text-align: center;margin-top: 10px">
    <div class='input_protein'>
      <app-p3db-cascader-input-search id=question [labels]="searchLabels" (handleEvent)="fn_search($event)"></app-p3db-cascader-input-search>
    </div>
    <br>
    <input class="input_protein" type="text" placeholder="Enter text"/>
  </mat-card>
  <mat-card class="message_card" style="margin-top: 10px; overflow-x: scroll;">
    <h3>Answer is : </h3>
    <div class="information" style="overflow-x:scroll; word-wrap: break-word; white-space: pre-wrap;">{{messageContent}}</div>
  </mat-card> -->
  <!-- <mat-card *ngIf="protein_details" id="title-container">
    <pre>
    External Id Type:{{protein_details.external_id_type}}
    Id:{{protein_details.id}}
    Fasta Header:{{protein_details.fasta_header}}
    Internal Protein Id:{{protein_details.internal_protein_id}}
    Length:{{protein_details.length}}
    Organism Id:{{protein_details.organism_id}}
    </pre>
  </mat-card>

  <mat-card *ngIf="listOfDataProteinIDs.length" style="margin-top: 10px; overflow-x: scroll;">
    <h3 id="title">Protein IDs</h3>
  </mat-card>
  <mat-card *ngIf="listOfDataProteinIDs.length" id="title-container" style="overflow-x: scroll;">
    <p3dbTable [columnKeys]="columnKeysProteinIDs" [dataSourceShow]="listOfDataProteinIDs" [searchIndex]="searchIndex"
    [hasIcon]="true" [iconTip]="'This is Phosphorylation Protein'" (handleCellClick)="handleCellClick($event)"></p3dbTable>
  </mat-card>

  <mat-card *ngIf="listOfDataPeptides.length" style="margin-top: 10px; overflow-x: scroll;">
    <h3 id="title">Phosphorylation Peptides in Protein</h3>
  </mat-card>
  <mat-card *ngIf="listOfDataPeptides.length" id="title-container">
    <p3dbTable [columnKeys]="columnKeysPeptides" [dataSourceShow]="listOfDataPeptides" [searchIndex]="searchIndex"
      [expandable]="true" (handleCellClick)="handleCellClick($event)"></p3dbTable>
  </mat-card>

  <mat-card *ngIf="phosphorylation_sites.length" style="margin-top: 10px; overflow-x: scroll;">
    <h3 id="title">Phosphorylation Site in Protein</h3>
  </mat-card>

  <mat-card *ngIf="phosphorylation_sites.length" id="title-container">
    <div class="scrooll" *ngFor="let phosphorylation_sites of phosphorylation_sites;let i = index">
      <h4 style="color:#7eb742"># Protein:{{proteinId[i]}}</h4>
      <div>
        <div style="width: 80px; display: inline-block;text-align: center;color: #548Ddf;">
          <p>Sequence</p>
        </div>
        <span *ngFor="let item of phosphorylation_sites;let i = index" [ngClass]="fn_seqHighlight(item)"
          title="{{phosphorylation_sites[i].tooptip}}">{{item.name}}</span>
      </div>
      <div>
        <div style="width: 80px; display: inline-block;text-align: center;">
          <p>|</p>
        </div>
        <span *ngFor="let item of phosphorylation_sites" [ngClass]="{'seq_highlight01':'true'}">{{item.arrow}}</span>
      </div>
      <div>
        <div style="width: 80px;display: inline-block;text-align: center;color: #548Ddf;">
          <p>Position:</p>
        </div>
        <span *ngFor="let item of phosphorylation_sites" [ngClass]="{'seq_highlight01':'true'}">{{item.index}}</span>
      </div>
    </div> -->
  <!-- </mat-card> -->
<!-- </mat-card>
</div> -->
