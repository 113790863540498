<mat-card class="mat-elevation-z8">

  <mat-card-header>
    <mat-card-title>Datasets</mat-card-title>
  </mat-card-header>

  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef> {{ column }}</th>
        <td mat-cell *matCellDef="let element"> {{ element[column] }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</mat-card>
