
<!-- <img  src="assets/images/Search_bg.JPG"> -->


<div id="image" class="text">       
   <h1>Navigation bar</h1>
    <mat-card class="search">
        <!-- <mat-card-header>
            Test
        </mat-card-header> -->
    
        <mat-card-content>
            <mat-card class="searchbar">
                <mat-card class="searchinsert">
                    <!-- <mat-card-header>Hi</mat-card-header> -->
                    <mat-card-content>
            
                        <mat-form-field>
                            <mat-label>Search</mat-label>
                            <input matInput placeholder="Ex. Pizza">
                          </mat-form-field>
                    </mat-card-content>
                    </mat-card>
                <mat-card class="searchtab">
                    <!-- <mat-card-header>Hi</mat-card-header> -->
                    <mat-card-content>
            
                        <button mat-flat-button color="primary">search</button>
                        <button mat-flat-button color="primary">example</button>
                    </mat-card-content>

            </mat-card>
   

        </mat-card>
            </mat-card-content>
    </mat-card>
    </div> 
