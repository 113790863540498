//by ziting
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute,Params,Router }from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";
import { MatTableDataSource } from '@angular/material/table';
import { G2SEntry } from "../../Services/G2SEntry";
import { MatDialog } from '@angular/material/dialog';
import { StructureViewerComponent } from '../structure-viewer/structure-viewer.component';
@Component({
  selector: 'app-show3d',
  templateUrl: './show3d.component.html',
  styleUrls: ['./show3d.component.css']
})
export class Show3dComponent implements OnInit {
  blastSelected: boolean = false;
  G2SDataSource: MatTableDataSource<G2SEntry>;
  noPdb: boolean = true;
  defaultPdb: any;
  g2sLoading:boolean = false;
  showCard:boolean;
  tableColumns = ['entryId', 'gene', 'uniprotAccession', 'taxId', 'uniprotStart', 'uniprotEnd','uniprotDescription','organismScientificName', '3DViewer'];

  constructor(private http: HttpClient, private router:Router, private sanitizer: DomSanitizer,public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  fn_predict(){
    let element_seq = ( document.getElementById("seq") as HTMLInputElement );
    let element_pos = ( document.getElementById("position") as HTMLInputElement );
    if(element_seq.value != "" || element_pos.value != "" ){
      this.fetchInfo(element_seq.value, element_pos.value);
    }else{
      alert("Please input sequence and position!")
    }
  }

  fn_example(){
    let element =( document.getElementById("seq") as HTMLInputElement);
    element.value = "ETGQSVNDPGNMSFVKETVDKLLKGYDIRLRPDFGGPP";
    let element_pos = ( document.getElementById("position") as HTMLInputElement );
    element_pos.value = "4,10";
  }

  fn_uniprot(){
    let element_uniprot = (document.getElementById("uniprot") as HTMLInputElement);
    if(element_uniprot.value != ""){
      this.searchG2S(element_uniprot.value)
    }else{
      alert("Please input sequence and position!")
    }
    this.showCard=true;
  }

  fn_loadUniprot(){
    let load_uniprot = (document.getElementById("uniprot") as HTMLInputElement);
    load_uniprot.value = "Q9LK99";
  }

  fetchInfo(seq, position){
    this.router.navigate(["show3dTable",{ urlg2s:"https://g2s.genomenexus.org/api/alignments/residueMapping?sequence="+ seq +"&positionList=" + position }]);

  }

  searchG2S(uniprot_id) {
    this.g2sLoading = true;
    console.log(`https://alphafold.ebi.ac.uk/api/prediction/${uniprot_id}?key=AIzaSyCeurAJz7ZGjPQUtEaerUkBZ3TaBkXrY94`)
    this.http.get(`https://alphafold.ebi.ac.uk/api/prediction/${uniprot_id}?key=AIzaSyCeurAJz7ZGjPQUtEaerUkBZ3TaBkXrY94`).subscribe((result: any) => {
      this.G2SDataSource = new MatTableDataSource(result);
      console.log("the data is : ",result);
      if (result != undefined && result.length >= 1) {
        this.defaultPdb = this.sanitizer.bypassSecurityTrustResourceUrl("/assets/display.html?pdbId=" + result[0].pdbUrl);
        this.noPdb = false;
      }
      console.log("the value of noPdb : ",this.noPdb)
      this.g2sLoading = false;
    }, error => {
      this.g2sLoading = false;
    });
  }


  showViewer(pdbId: string, pdbLinkBase: string) {
    this.dialog.open(StructureViewerComponent, {
      width: '1000px',
      height: '700px',
      data: { pdbId: pdbId, pdbLinkBase: pdbLinkBase }
    });
  }

}
