import { Component, OnInit,Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  
  dialogWidth: number = 600;
  proteinName:String
  messageContent:String
  showWait=false;
  showDialogBox='block'
  constructor(@Inject(MAT_DIALOG_DATA) public data, private httpClient: HttpClient, private dialogRef: MatDialogRef<ModalComponent>) { 
    this.proteinName = data.protien
    this.getMessageData(data.protein);
    console.log("Protein Name is : ",this.proteinName);
  }

  ngOnInit(): void {
  }

  updateDialogWidth(newWidth: number) {
    this.dialogWidth = newWidth;
  }

  closeDialog(){
    this.dialogRef.close();
    this.showDialogBox = 'none';
  }

  getMessageData(value){
    this.proteinName=value
    this.showWait=true;
    const newWidth = 1000;
    forkJoin([
        this.fetchJson(
          environment.BASE_API_URL+ "chatgpt/?content="+"what%20is%20gene"
          //  environment.BASE_API_URL+ 'chatgpt/?content=As a biology researcher, Im interested in understanding the molecular mechanism and functional significance of the gene AT1G3. Could you provide me with detailed information about how AT1G3 is activated, its downstream signaling pathways, and its roles in cellular processes such as cell growth, differentiation, and response to stress? Please include any relevant research findings or insights in your response.'
        ),
      ]).subscribe((result) => {
          this.showWait = false;
          this.updateDialogWidth(newWidth);
          this.messageContent = result[0]["data"]["choices"][0]["message"]["content"];
      })
    }

  fetchJson(file) {
    console.log("In fetch json")
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };
    return this.httpClient.get(file, options).pipe(
      map((file_name) => {
        console.log("In Map :",file_name)
        return file_name;
      })
    );
  }

}
