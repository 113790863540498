import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-usermanual',
  templateUrl: './usermanual.component.html',
  styleUrls: ['./usermanual.component.css']
})
export class UsermanualComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  panelOpenState = false;


}


export class AppComponent  {
  title = 'Angular Search Using ng2-search-filter';
  searchText;
  heroes = [
    { id: 11, name: 'Mr. Nice', country: 'India' },
    { id: 12, name: 'Narco' , country: 'USA'},
    { id: 13, name: 'Bombasto' , country: 'Califonia'},
    { id: 14, name: 'Celeritas' , country: 'Texas' },
    { id: 15, name: 'Magneta' , country: 'Russia'},
    { id: 16, name: 'RubberMan' , country: 'China'},
    { id: 17, name: 'Dynama' , country: 'Germany'},
    { id: 18, name: 'Dr IQ' , country: ''},
    { id: 19, name: 'Magma' , country: 'South Africa'},
    { id: 20, name: 'Tornado' , country: 'Sri Lanka , live in here,but is just likje '}
  ];

  shabis=[
    { AI:1, B:2,C:3},
    { AI:2, B:3,C:4},
    { AI:3, B:4,C:5}
  ];
}

