<mat-card id="component-container" style="padding-bottom: 1px;">
  <mat-card>
    <h2 id = "title">Motif Files</h2>
  </mat-card>
  <mat-card class='input_card'>
    <p>Choose below dropdown item to select Motiffile</p>
    <mat-form-field appearance="outline" class="form-field" style="width: 100%; margin: auto;">
      <mat-label>select Motiffile</mat-label>
      <mat-select [formControl]="Motiffiles" (selectionChange)="load_logojs()">
        <mat-option *ngFor="let file of Motif_files_list" [value]="file">{{file}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div id="logo" style="width: 40%; margin: auto; margin-top: 5% !important; margin-bottom: 5% !important;"></div>
    <p *ngIf="errorMessage">Due to the lack of substrates, the MEME cannot generate motif</p>
    <app-table *ngIf="ELEMENT_DATA && !requestError" [tableData]="ELEMENT_DATA"></app-table>  
  </mat-card>
</mat-card>
