<mat-card class="container-card">
    <mat-card class="background-card">
        <div style="padding-bottom: 10px;">
            <mat-card class="heading">
                <h1 id="title"> Kinase Families</h1>
            </mat-card>
        </div>
        <div style="padding-bottom: 10px;">
            <mat-card class="heading">
                <mat-slide-toggle *ngIf = "is_tab_view" [(ngModel)]="is_tab_view">View as table</mat-slide-toggle>
                <mat-slide-toggle *ngIf = "!is_tab_view" [(ngModel)]="is_tab_view">View as tabs</mat-slide-toggle>

            </mat-card>
        </div>

        <div *ngIf="is_tab_view">

            <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                <ng-container matColumnDef="{{column}}" *ngFor="let column of GroupColumns">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let group"> {{group.name}}</td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let group" [attr.colspan]="GroupColumns.length">
                        <div class="example-element-detail" [@detailExpand]="group == expandedGroup ? 'expanded' : 'collapsed'">
                            <!-- <div class="example-element-diagram">

                 <div class="example-element-name"> {{group.group_name}} </div> -->
                            <!-- </div> -->
                            <div class="example-element-description">
                                <mat-card class="subgroupdetails">
                                    <table class="subtable" mat-table [dataSource]="group.subgroups">
                                        <ng-container matColumnDef="{{ subgroupColumn }}" *ngFor="let subgroupColumn of SubgroupColumns">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <h3><strong>{{ subgroupColumn }} </strong></h3>
                                            </th>
                                            <td mat-cell *matCellDef="let subgroup" routerLink="/kinase-genedata/{{ subgroup['subgroup_name'] }}"> {{ subgroup['subgroup_name'] }}
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="SubgroupColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: SubgroupColumns;" class="example-element-row"></tr>
                                        <!-- <tr routerLink="/kinase" *ngFor = "let subgroup of group.subgroups">
                                <a href = 'kinase-genedata/{{ subgroup.name }}' >{{ subgroup.name }} </a>
                            </tr> -->

                                    </table>
                                </mat-card>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef = "" -->
                <!-- <tr mat-header-row *matHeaderRowDef="GroupColumns"></tr> -->
                <tr mat-row matRipple *matRowDef="let element; columns: GroupColumns;" class="example-element-row" [class.example-expanded-row]="expandedGroup === element" (click)="expandedGroup = expandedGroup === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row">
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>

        <div *ngIf="!is_tab_view">
            <!-- <h2 style="text-align:center;">Testing</h2> -->
            <app-table [tableData]="ELEMENT_DATA"></app-table>
        </div>

    </mat-card>


</mat-card>