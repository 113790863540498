<mat-card class="component-container">
    <mat-card class="background-card">
        <div style="padding-bottom: 10px;">
            <mat-card>
                <h1 id="title">Phylogenetic Tree Dataset</h1>
            </mat-card>
        </div>

        <mat-card *ngIf="showWait" id="title-container" style=" overflow-x: scroll;text-align: center;">
        <nz-spin *ngIf="showWait" nzSimple [nzSize]="'large'"></nz-spin>
        </mat-card>


        <div style="padding-bottom: 10px;">
            <mat-card *ngIf="listOfDataPeptides.length" style="overflow-x: scroll;">
                <h3 id="title">Phosphorylation Peptides in Protein</h3>
            </mat-card>
        </div>
        <div style="padding-bottom: 10px;">
            <mat-card style="margin-top: 40px; overflow-x: scroll;" *ngIf="listOfDataPeptides.length" id="title-container">
                <p3dbTable [columnKeys]="columnKeysPeptides" [dataSourceShow]="listOfDataPeptides" [searchIndex]="searchIndex"
                    [expandable]="true" ></p3dbTable>
            </mat-card>
        </div>


        <div style="padding-bottom: 10px;">
            <mat-card >
                <h2 id="exptitle">{{speciesTitle}} </h2>

            </mat-card>
        </div>

        <div *ngIf="jsonData != null && jsonData.length > 0" style="padding-bottom: 10px;">
            <mat-card style="margin-top: 40px; overflow-x: scroll;">
                <!-- <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

                    <ng-container matColumnDef="fasta_header">
                        <th *matHeaderCellDef>
                            <h1 id="exptitle"> Fasta Header </h1>
                        </th>
                        <td id="increase" mat-cell *matCellDef="let organism">
                            {{organism.fasta_header.split("|")[0]}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th *matHeaderCellDef>
                            <h1 id="exptitle"> Intenal Protein ID </h1>
                        </th>
                        <td id="increase" mat-cell *matCellDef="let organism">
                            {{organism.internal_protein_id}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns:displayedColumns;"
                        (click)="update_selected_row(element)" class="example-element-row">
                    </tr>
                </table> -->

            
                <table class="my-table">
                    <thead>
                      <tr>
                        <th>Protein ID</th>
                        <th>Internal Protein ID</th>
                        <!-- Add more headers if needed -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of jsonData">
                        <td>{{ item.fasta_header.split("|")[0] }}</td>
                        <td><a class="link_anchor" (click)="onLinkClick(item.internal_protein_id)">{{ item.internal_protein_id }}<mat-icon class="external-link">launch</mat-icon></a></td>
                        <!-- Add more cells if your objects have more properties -->
                      </tr>
                    </tbody>
                  </table>
                  <nz-pagination class="iPagination" [nzShowTotal]="totalTemplate" [(nzPageIndex)]="pageIndex" [nzTotal]="total"
                    [nzPageSizeOptions]="nzPageSizeOptions" [(nzPageSize)]="pageSize" nzShowSizeChanger
                    (nzPageSizeChange)="nzPageSizeChange($event)" (nzPageIndexChange)="nzPageIndexChange($event)"></nz-pagination>
                    <ng-template #totalTemplate let-total> Total {{ total }} items </ng-template>
            </mat-card>
        </div>
        <div *ngIf="showWait==false && (jsonData == null || jsonData.length == 0)">
            <mat-card>
                <h1 style="text-align: center;">The phosphorylation data for this species will be updated soon...</h1>
            </mat-card>
        </div>
    </mat-card>
</mat-card>