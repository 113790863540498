
<div class="dialog-box" [style.width.px]="dialogWidth" [style.display]="showDialogBox">
    <b><h1>{{proteinName}}</h1></b>
    <div class="dialog-box-header"><h4>The result is : </h4></div>
    

    <div class="dialog-box-content">
        <mat-card *ngIf="showWait" id="title-container" style="margin-top: 10px; overflow-x: scroll;text-align: center;">
            <nz-spin *ngIf="showWait" nzSimple [nzSize]="'large'"></nz-spin>
        </mat-card>
        <p>{{messageContent}}</p>
    </div>
    <br/>
    <div class="dialog-box-footer">
        <button class="btn btn-primary" (click)="closeDialog()">Close</button>
    </div>
</div>
  










<!-- <button class="btn btn-primary">
    Press
</button> -->
<!-- 
 <div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modal Title</h5>
          <button type="button" class="close" (click)="closeModal()">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>This is the modal body.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
        </div>
      </div>
    </div>
  </div> -->
  
