<!-- <p>usermanual works!</p> -->





<!-- <div class="header">
  <div class= "title">
    Tutorial
  </div>
</div> -->
<mat-card id="component-container" style="margin-top: 5%; margin-bottom: 3%;" >
  <mat-card id="title-container">
    <h1 id="title">Tutorial</h1>

  </mat-card>
      <mat-card id="tutorial-container" style="margin-top: 10px;">

<div class="row">

  <mat-accordion>
    <mat-expansion-panel id = "tab-style" class="mat-elevation-z0" style = "border-bottom: solid 2px white;">
      <mat-expansion-panel-header class="mat-elevation-z0" >
        
        <mat-panel-title >
          <mat-icon style = "margin-top: 22px;">home</mat-icon>
          <h1 id = "tab-text" style = "margin-left: 16px; margin-top: 18px;" >Home </h1> 
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p></p>



      <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;"(opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <!-- <mat-expansion-panel-header class="{{panelOpenState ? 'Pannel1' : 'Pannel2'}}"> -->

          <mat-panel-title>
            <h1 id = "secondary-text" > Menu Bar </h1>          
          </mat-panel-title>
          <mat-panel-description>
            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>• The top menu bar provides easy access to all features and tools offered by P3DB.
<br>
          • Click on the “Home” link or the P3DB logo anytime to return to the homepage.</p>
      </mat-expansion-panel>



      <mat-expansion-panel id = "secondary-tab"(opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h1 id = "secondary-text" > Quick Search </h1>          
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>• The “Quick Search” link on right side can be used to quickly jump to individual card pages for any of the
          five entities including genes/proteins, miRNA, metabolites, PI and traits.</p>
      </mat-expansion-panel>
    </mat-expansion-panel>

  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel id = "tab-style" class="mat-elevation-z0" style = "border-bottom: solid 2px white;">
      <mat-expansion-panel-header class="mat-elevation-z0" >
        <mat-panel-title >
          <mat-icon style = "margin-top: 22px;">travel_explore</mat-icon>

          <h1 id = "tab-text" style = "margin-left: 16px; margin-top: 18px;"> Browse Tab </h1>

        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p></p>

      <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;"(opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

          <mat-panel-title>
            <h1 id = "secondary-text" > All </h1>          
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>• Clicking this tab will take you to P3DB's Phosphoprotein Data page. Each protein is listed with its corresponding organism and contains a detailed description.
          </p>
      </mat-expansion-panel>

      <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;"(opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

          <mat-panel-title>
            <h1 id = "secondary-text" > Datasets </h1>          
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>• P3DB uses plant phosphorylation data from different studies. In the datasets module, you can view the list of literature used in our database. You can use the search bar at the top of the table, or click on each of the table headers to sort by the investigator name, organism name, organism ID, Pubmed ID, and title.
          </p>
      </mat-expansion-panel>

      <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;"(opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
  
          <mat-panel-title>
            <h1 id = "secondary-text" > Kinase </h1>          
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>• Kinases are enzymes that catalyze the transfer of phosphate groups between molecules, which is the key action in phosphorylation. This page shows all known plant-based kinases, which are categorized in family groups. Additional information includes the TAIR identifier and related sequences for each kinase in the table. 
          </p>
      </mat-expansion-panel>
  
      <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;"(opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
  
          <mat-panel-title>
            <h1 id = "secondary-text" > Phylogenetic Tree </h1>          
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>• Including every organism used in P3DB, this is a constructed phylogenetic tree generated through phyloT (https://phylot.biobyte.de/). Using whole genome alignment, a phylogenetic tree shows the genetic relationship between organisms (the closer linked the organisms, the more genetically related/similar they are). Users can also click on each organism to display their information. 
          </p>
      </mat-expansion-panel>

    </mat-expansion-panel>
  </mat-accordion>


    <mat-accordion>
      <mat-expansion-panel id = "tab-style" class="mat-elevation-z0" style = "border-bottom: solid 2px white;" >
        <mat-expansion-panel-header  >
          <mat-panel-title>
            <mat-icon style = "margin-top: 22px;">build</mat-icon>
            <h1 id = "tab-text" style = "margin-left: 16px; margin-top: 18px;" >Tool Tab </h1>

          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p></p>

        <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h1 id = "secondary-text" > 3D Structure Viewer</h1>          
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>• The page visualizes helps visualize the results of a protein sequence inputted into the PTM Prediction page. </p>
        </mat-expansion-panel>

        <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h1 id = "secondary-text" > Organism Search</h1>          
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>• The organism page takes in the taxonomy ID or the name of an organism and outputs related information. This information includes a table of basic facts as well as a biological hierarchy showing where the organism fits in. The hierarchy includes other organisms and is clickable so users can jump to their organism page as well. The database includes around 45 species. </p>
        </mat-expansion-panel>
    
        <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;"(opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
    
            <mat-panel-title>
              <h1 id = "secondary-text" > PTM Prediction</h1>          
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>• PTM stands for Post Translational Modification. After an mRNA strand is translated into amino acids, there are various modifications that can occur before a protein becomes mature—phosphorylation is one type of Post Translational Modification. 
            This tool takes the input of a protein sequence of the user’s choice and uses our lab’s deep learning method (Musite-Deep) to predict the phosphorylation sites. The sites will be highlighted. Additionally, the results can also be visualized in 3D through the 3D structure viewer.
            
            </p>
        </mat-expansion-panel>
  
        <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;"(opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
    
            <mat-panel-title>
              <h1 id = "secondary-text" > PTM Viewer  </h1>          
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>• This tool takes in a protein as input and displays a 3D diagram showing the possible modifications the protein can go through. In the middle, the green triangle is the input protein and the nodes indicate an amino acid modification. The different colors represent different amino acids, and the edges have a number that represent how many experimental verified peptides can support this modification. This tool also uses an equation to calculate a score for the protein, which can be found in the green triangle. The score shows how far the protein can be phosphorylated.. The equation is PhoScore = log(Special Count * Site Count). 
  
            
            </p>
        </mat-expansion-panel>
  
        <mat-expansion-panel id = "secondary-tab" style = "border-bottom: solid 0px white;"(opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
    
            <mat-panel-title>
              <h1 id = "secondary-text" > Universal Search </h1>          
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>• This tool takes in an ID of a gene and outputs the other IDs the gene goes by. Across different platforms genes will go by different identifiers. For example, one site might use Locus ID and another might use UniProt ID). This tool is useful so that one can quickly find all the IDs of a gene. 
            </p>
        </mat-expansion-panel>
  

      </mat-expansion-panel>
  </mat-accordion>

  
  <mat-accordion>
    <mat-expansion-panel id = "tab-style" class="mat-elevation-z0" style = "border-bottom: solid 2px white;">
      <mat-expansion-panel-header class="mat-elevation-z0" >
        
        <mat-panel-title >
          <mat-icon style = "margin-top: 22px;">science</mat-icon>
          <h1 id = "tab-text" style = "margin-left: 16px; margin-top: 18px;" >Conservation Tab</h1> 
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p></p>

      <mat-expansion-panel id = "secondary-tab" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
  
          <mat-panel-title>
            <h1 id = "secondary-text" > Peptide Conservation Search </h1>          
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>• This tool takes in a protein sequence, partial sequence, or other identifiers as input and will search the database to find similar sequences. The highlighted area is the conservation region or where the amino acids are consistent. 
          
          </p>
      </mat-expansion-panel>
    </mat-expansion-panel>

  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel id = "tab-style" class="mat-elevation-z0" style = "border-bottom: solid 2px white;">
      <mat-expansion-panel-header class="mat-elevation-z0" >
        
        <mat-panel-title >
          <mat-icon style = "margin-top: 22px;">help</mat-icon>
          <h1 id = "tab-text" style = "margin-left: 16px; margin-top: 18px;" >Other Tabs</h1> 
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p></p>

      <mat-expansion-panel id = "secondary-tab" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
  
          <mat-panel-title>
            <h1 id = "secondary-text" > Team </h1>          
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>• Clicking on this tab will take you to our team page, where you can find our project leaders and developers. P3DB has many investigators and collaborators, but any questions or comments regarding P3DB should go to project leaders Dr. <a href="mailto:qyao3@unl.edu">Qiuming Yao</a> or <a href="mailto:cx9p9@mail.missouri.edu">Chunhui Xu</a>.  
          
          </p>
      </mat-expansion-panel>

      <mat-expansion-panel id = "secondary-tab" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
  
          <mat-panel-title>
            <h1 id = "secondary-text" > Tutorial </h1>          
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>• You are currently on this page! Click on any of the tabs on the drop-down menu to see a description of the corresponding page.
          
          </p>
      </mat-expansion-panel>
    </mat-expansion-panel>

    

  </mat-accordion>

  

</div>
</mat-card>

</mat-card>
