<div class="page_container">
    <h1 id="title">Publications on P3DB</h1>
    <mat-card class="how_to_cite">
        <h2 id="secondary_title">How to cite P3DB</h2>
        <p class> We hope you found P3DB helpful! If you would like to cite us, please reference one of the publications below:</p>

    </mat-card>
    <mat-card class="citation_box">
        <div class="flex-container">
          <div class="flex-item">
            <h2 class="publication_year">2019
                <a href="https://pubmed.ncbi.nlm.nih.gov/31034103/" class="citation_link" target="_blank ">PubMed</a>
                <a href="https://onlinelibrary.wiley.com/doi/10.1111/tpj.14372" class="citation_link" target="_blank ">Wiley</a>
            </h2>
            <p class = "general_text"> Edward R. Cruz, Hung Nguyen, Tin Nguyen, and Ian S. Wallace. <strong>FAT‐PTM: A post‐translational modification database for analysis of proteins and metabolic pathways.  </strong>
            </p>        

          </div>
          <div class="flex-item image">
            <img src="assets\images\ptm2019.png" class = "old_screenshot">

          </div>
        </div>
      </mat-card>
      <mat-card class="citation_box">
        <div class="flex-container">

          <div class="flex-item">
            <h2 class="publication_year">2013
                <a href="http://www.ncbi.nlm.nih.gov/pubmed/24243849" class="citation_link" target="_blank ">PubMed</a>
                <a href="http://nar.oxfordjournals.org/content/42/D1/D1206.long" class="citation_link" target="_blank ">NAR</a>
            </h2>
            <p class = "general_text"> Yao Q, Ge H, Wu S, Zhang N, Chen W, Xu C, Gao J, Thelen JJ, Xu D. (2013)<strong> P3DB 3.0: From plant phosphorylation sites to protein networks. </strong>
            </p>          
        </div>
          <div class="flex-item image">
            <img src="assets\images\p3db2014.png" class = "old_screenshot">

 
        </div>

        </div>
      </mat-card>
      <mat-card class="citation_box">
        <div class="flex-container">

          <div class="flex-item">
            <h2 class="publication_year">2012
                <a href="http://www.ncbi.nlm.nih.gov/pubmed/22973285" class="citation_link" target="_blank ">PubMed</a>
                <a href="http://www.frontiersin.org/article/10.3389/fpls.2012.00206/full" class="citation_link" target="_blank ">Frontiers</a>
            </h2>
            <p class = "general_text"> Yao Q, Bollinger C, Gao J, Xu D and Thelen JJ. (2012) <strong>P3DB: an integrated database for plant protein phosphorylation. </strong>
            </p>         
        </div>
          <div class="flex-item image">
            <img src="assets\images\p3db2012.png" class = "old_screenshot">

          </div>

        </div>
      </mat-card>
      <mat-card class="citation_box">
        <div class="flex-container">

          <div class="flex-item">
            <h2 class="publication_year">2009
                <a href="http://www.ncbi.nlm.nih.gov/pubmed/18931372" class="citation_link" target="_blank ">PubMed</a>
                <a href="http://nar.oxfordjournals.org/content/42/D1/D1206.long" class="citation_link" target="_blank ">NAR</a>
            </h2>
            <p class = "general_text"> Gao JJ, Agrawal GK, Thelen JJ and Xu D. (2009) <strong>P3DB: a plant protein phosphorylation database. </strong>
            </p>        

          </div>
          <div class="flex-item image">
            <img src="assets\images\p3db2009.png" class = "old_screenshot">

          </div>

        </div>
      </mat-card>
    </div>