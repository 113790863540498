import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PeptideData, Phosphosite, Reference } from 'src/app/interfaces/peptide-data';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';


@Component({
  selector: 'app-peptide-data',
  templateUrl: './peptide-data.component.html',
  styleUrls: ['./peptide-data.component.css'],
  encapsulation: ViewEncapsulation.None,
})

//=============================================================================
// PeptideData Component
//=============================================================================
// Author(s): Michael Fisher (June 2021)
//=============================================================================
// This component is designed to provide functionality for the 
// https://dev.p3db.org/peptide-data/:pnrseq/:pro/:loc/:pos page. The following 
//sections are divided up based on role and tasks for the functionality of the
// page, and have their respective fields and methods assigned as such
//=============================================================================
// == Database access:
export class PeptideDataComponent implements OnInit {

  constructor(
    private database : DatabaseConnService,
    private activeRoute: ActivatedRoute) { }


  ngOnInit(): void {
    this.pnrseq = this.activeRoute.snapshot.params.pnrseq;
    this.pro = this.activeRoute.snapshot.params.pro;
    this.loc = this.activeRoute.snapshot.params.loc;
    this.pos = Number(this.activeRoute.snapshot.params.pos);
    this.pullPeptide();
  }

  //===========================================================================
  // Database access
  //===========================================================================
  public peptideData?: PeptideData;

  private pullPeptide(): void {
    // this.peptideService.get(this.pnrseq, this.pro, this.loc)
    this.database.get('peptide', [this.pnrseq, this.pro, this.loc])
    .subscribe(
      data => { console.log(data); 
                this.peptideData = this.unpackPeptide(data['data']);
                console.log(this.peptideData)},
      error => { console.log(error); }
    );
  }

  // ==========================================================================
  // JSON processing functions
  //===========================================================================

  private unpackPeptide(data: any) : PeptideData {
    let peptid : PeptideData = {
      accessions: data['accessions'],
      description : this.sanitize(data['description']),
      location : this.sanitize(data['location']),
      numSpectra : this.sanitize(data['no_of_spectra']),
      nrSequence : this.highlightSequence(this.sanitize(data['nr_sequence'])),
      organism : this.sanitize(data['organism']),
      phosphosites : this.unpackPhosphosite(data['phosphosites']),
      protein : this.sanitize(data['protein']),
      references : this.unpackReferences(data['reference'])
    }
    
    for (let i = 0; i < data['accessions'].length; ++i)
    {
      if (i % 2 == 0) this.proteinColumn1.push(data['accessions'][i]);
      else this.proteinColumn2.push(data['accessions'][i]);
    }

    return peptid;
  }

  private unpackPhosphosite(data : string) : Phosphosite[] {
    let phosphosites = [];

    for (let i = 0; i < data.length; ++i)
    {
      let phosphosite : Phosphosite = {
        locationInProtein : this.sanitize(data[i]['location_in_protein']),
        phosphositeIDLink : this.sanitize(data[i]['phosphosite_id_link']),
        aminoAcid : this.sanitize(data[i]['amino_acid'])
      }

      phosphosites.push(phosphosite);
    }

    return phosphosites;
  }

  private unpackReferences(data: any) : Reference[] {
    let references = [];

    for (let key in data)
    {
      let reference : Reference = {
        key : key,
        data : data[key]
      }

      references.push(reference);
    }

    return references;
  }

  private highlightLocation(data: string): string {
    for (let i = 0; i < data.length; ++i)
    {
      console.log(data[i]);
      if (data[i] == '#') 
      return data.replace(data[i - 1] + data[i], '<span class="highlightText">' + data[i - 1] + '</span>' )
    }
    return data;
  }

  //===========================================================================
  // Table Formatting
  //===========================================================================

  public proteinColumn1 = [];
  public proteinColumn2 = [];  
  public sequenceColumns = ['sequence', 'sequenceData'];
  public phosphositeColumns = ['position', 'aminoAcid', 'phosphosite'];
  public displayField?: Number | null;

  //===========================================================================
  // Sequence Formatting and highlighing 
  //===========================================================================
  public sequenceData = [];
  private pos?: number;

  private highlightSequence(data: string): string {
    let ret = '';

    return data.slice(0, this.pos) + 
    '<span class="highlightText">' + data[this.pos] + '</span>' + data.slice(this.pos + 1);
  }

  //============================================================================
  // Misc functions
  //============================================================================
  private pnrseq?: string;
  private pro?: string;
  private loc?: string;

  sanitize(obj: string): string { return obj ? String(obj).replace('\/', '/') : 'N/A'; }
  toggle(option: number): void 
  { 
    if (option == this.displayField) this.displayField = null;
    else this.displayField = option;
  }
}
