import { Component, OnInit } from '@angular/core';
import { fromEventPattern } from 'rxjs';

@Component({
  selector: 'app-pathway-entrance',
  templateUrl: './pathway-entrance.component.html',
  styleUrls: ['./pathway-entrance.component.css'],
})
export class PathwayEntranceComponent implements OnInit {
  constructor() {}
  preview_image_url: any = 'assets/images/upload.png';
  image: any = null;

  ngOnInit(): void {}

  handleResult(result) {
    console.log(result);
  }

  predict() {
    // console.log("asdf");
    let form_data = new FormData();
    let date = new Date().toLocaleDateString();
    let time = new Date().toLocaleTimeString();
    let job_name = date + '_' + time;
    form_data.append('image', this.image);
    var requestOptions = {
      method: 'POST',
      body: form_data,
    };
    // // ****** fetch post /predict */
    fetch('https://dev.p3db.org:5001/api/prediction/', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          console.log(result);
        } else {
          //****** success */
          this.handleResult(result);
        }
      })
      .catch((error) => console.log('error', error));
  }

  onDrop(event) {
    event.preventDefault();
    this.image = event.dataTransfer.items[0].getAsFile();
    var reader = new FileReader();
    // this.preview_image_url = files;
    reader.readAsDataURL(this.image);
    reader.onload = (_event) => {
      this.preview_image_url = reader.result;
    };
  }
  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    console.log('ondropover');
  }

  onChangeEvent(event) {
    let image = event.target.files[0];
    this.image = event.target.files[0];
    var reader = new FileReader();
    // this.preview_image_url = files;
    reader.readAsDataURL(image);
    reader.onload = (_event) => {
      this.preview_image_url = reader.result;
    };
  }
}
