<router-outlet></router-outlet>
<mat-card id="component-container">
    <mat-card>
        <h2 id="title">PTM Peptides</h2>
    </mat-card>
    <mat-card *ngIf="showWait" id="title-container" style="margin-top: 10px; overflow-x: scroll;text-align: center;">
        <nz-spin *ngIf="showWait" nzSimple [nzSize]="'large'"></nz-spin>
      </mat-card>
    <mat-card *ngIf="listOfDataProteinIDs.length" style="margin-top: 10px; overflow-x: scroll;">
        <h3 id="title">Protein IDs</h3>
    </mat-card>
    <mat-card *ngIf="listOfDataProteinIDs.length" id="title-container" style="overflow-x: scroll;">
        <p3dbTable [columnKeys]="columnKeysProteinIDs" [dataSourceShow]="listOfDataProteinIDs"
            [searchIndex]="searchIndex" [hasIcon]="true" [thisTip]="'This is not a Phosophory Protien'"
            [iconTip]="'This is Phosphorylation Protein'" (handleCellClick)="handleCellClick($event)">
        </p3dbTable>
    </mat-card>
    <mat-card *ngIf="listOfDataPeptides.length" style="margin-top: 10px; overflow-x: scroll;">
        <h3 id="title">Phosphorylation Peptides in Protein<button (click)="downloadData()" class="styleButton">Download
                CSV</button></h3>
    </mat-card>
    <mat-card *ngIf="listOfDataPeptides.length" id="title-container">
        <p3dbTable [columnKeys]="columnKeysPeptides" [dataSourceShow]="listOfDataPeptides" [searchIndex]="searchIndex"
            [expandable]="true" (handleCellClick)="handleCellClick($event)"></p3dbTable>
    </mat-card>

    <mat-card *ngIf="phosphorylation_sites.length" style="margin-top: 10px; overflow-x: scroll;">
        <h3 id="title">Phosphorylation Site in Protein</h3>
    </mat-card>

    <mat-card *ngIf="phosphorylation_sites.length" id="title-container">
        <div class="scrooll" *ngFor="let phosphorylation_sites of phosphorylation_sites;let i = index">
            <h4 style="color:#7eb742"># Protein:{{proteinId[i]}}</h4>
            <div>
                <div style="width: 80px; display: inline-block;text-align: center;color: #548Ddf;">
                    <p>Sequence</p>
                </div>
                <span *ngFor="let item of phosphorylation_sites;let i = index" [ngClass]="fn_seqHighlight(item)"
                    title="{{phosphorylation_sites[i].tooptip}}">
                    <a *ngIf="item.class== 'highlight'; else elseClass" data-toggle="tooltip" data-placement="top"
                        title="value will be printed" (click)="getData(i,item.protien_id)">{{item.name}}</a>
                    <ng-template #elseClass>
                        {{item.name}}
                    </ng-template>
                </span>
            </div>
            <div>
                <div style="width: 80px; display: inline-block;text-align: center;">
                    <p>|</p>
                </div>
                <span *ngFor="let item of phosphorylation_sites"
                    [ngClass]="{'seq_highlight01':'true'}">{{item.arrow}}</span>
            </div>
            <div>
                <div style="width: 80px;display: inline-block;text-align: center;color: #548Ddf;">
                    <p>Position:</p>
                </div>
                <span *ngFor="let item of phosphorylation_sites"
                    [ngClass]="{'seq_highlight01':'true'}">{{item.index}}</span>
            </div>
        </div>
    </mat-card>
    <script>
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    </script>

</mat-card>

<!-- <mat-card id="component-container">
    <mat-card>
        <h2 id="title">PTM Peptides</h2>
    </mat-card>
    <mat-card class='input_card' style="text-align: center;margin-top: 10px">
        <div class='input_protein'>
           
            <input type="textbox" value="search">
        </div>
        <br>
    </mat-card>
    <mat-card *ngIf="listOfDataProteinIDs.length" style="margin-top: 10px; overflow-x: scroll;">
        <h3 id="title">Protein IDs</h3>
    </mat-card>
</mat-card> -->