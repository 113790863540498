import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pubmed-id',
  templateUrl: './pubmed-id.component.html',
  styleUrls: ['./pubmed-id.component.css']
})
export class PubmedIDComponent implements OnInit {
  pubmedID: string | null;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.pubmedID = params.get('pubmedID');
      console.log('PubmedID:', this.pubmedID);
    });
  }
}
