import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PathwayViewerService {

  // for now, we need to use the old url so environment won't work
  // TODO: Update URL when API is added to the new one 
  BASE_API_URL = "https://dev.p3db.org:5000/api/";
  ID_REF_API = this.BASE_API_URL + "api/mapping/?GeneSymbol=";

  constructor(private http:HttpClient) { }

  getIdsFromGeneSymbol(symbol: string): Observable<any> {
    return this.http.get(this.ID_REF_API + symbol);
  }

  postMarksForReview(data): Observable<any> {

    return this.http.post(this.BASE_API_URL + "save_prediction_annotations", data);
  }

  getAllPathways() {

  }

  getPathwayByTitle(title:string) {

  }

  addPathway(pathway) {

  }

  uploadPathwayImage(imgSrc, imgName) {
  }

  uploadPathwayCoords(coordsObjs) {

  }

  deletePathway(pathwayId, imgUrl) {
  }

  //getGeneInfoByProtId(geneList) : Observable<any[]> {

  //}
}
