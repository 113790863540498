<mat-card class="card">


  <br>
  <mat-form-field class="input_sequence">
    <mat-label class="input_sequence_label">Input your sequence</mat-label>
    <textarea id="seq" matInput
      placeholder="MERSPAVCCQDPRAELVERVAAISVAHLEEAEEGPEPASNGVDPPPRARAASVIPGSASRPTPVRPSLSARKFSLQERPAGSCLEAQVGPYSTG"></textarea>
  </mat-form-field>

  <button mat-flat-button color="primary" class="predict_seq" (click)="fn_predictSeq()">
    Predict
  </button>
  <span (click)="fn_example()" style="color: red; margin-left: 10px;">Load example</span>

</mat-card>

<mat-card *ngIf="showWait">
  <div style="text-align:center;width:100%;">
    <mat-spinner style="margin-left: 45%;"></mat-spinner>
  </div>
</mat-card>

<mat-card class="card" *ngIf="test.length">
  <h2 style="text-align:center;">Result</h2>

  <div class="scrooll">
    <div style="display: inline-block;">
      <span style="color:red">* Mouse over characters to view details</span>
      <br />
      <br />
      <div>
        <div style="width: 80px; display: inline-block;text-align: center;color: #548Ddf;">
          <p>Sequence:</p>
        </div>
        <span *ngFor="let item of test;let i = index" [ngStyle]="fn_seqHighlight(item)"
          title="{{test[i].tooltip}}">{{item.name}}</span>
      </div>

      <div>
        <div style="width: 80px; display: inline-block;text-align: center;">
          <p>|</p>
        </div>
        <span *ngFor="let item of test" [ngClass]="{'seq_highlight01':'true'}">{{item.arrow}}</span>
      </div>

      <div>
        <div style="width: 80px;display: inline-block;text-align: center;color: #548Ddf;">
          <p>Position:</p>
        </div>
        <span *ngFor="let item of test" [ngClass]="{'seq_highlight01':'true'}">{{item.index}}</span>
      </div>
    </div>

  </div>
</mat-card>

<mat-card class="card" *ngIf="test.length">

  <h2 style="text-align:center;">Probability Slider</h2>
  <h4 style="text-align:center;">Only highlight modifications with probability greater than: {{ sliderValue }}</h4>

  <mat-slider class="slider" min="0.00" max="1" step="0.01" [(ngModel)]="sliderValue">
    <input matSliderThumb >
  </mat-slider>

</mat-card>

  <mat-card class="card" *ngIf="test.length">

  <div>
    <h2 style="text-align:center;">Tabled Result</h2>
    <app-table [tableData]="ELEMENT_DATA"></app-table>
  </div>
  <div class="button-container">
  <button mat-flat-button color="primary" class="show3d_button" (click)="fn_show3D()">
    <a>View predicted PTM sites in 3D structure</a>
  </button>
  <br />
  <button mat-flat-button color="primary" class="show3d_button" (click)="fn_download()">
    <a>Download the Result</a>
  </button>
  </div>
</mat-card>
