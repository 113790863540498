
<mat-card id="component-container" style="padding-bottom: 1px;">
    <mat-card>
        <h2 id="title">PTM Viewer</h2>
    </mat-card>

    <mat-card class='input_card' style="text-align: center;">
        <div class='ttttest'>
            PROTEIN SEARCH: 
            <mat-form-field appearance="outline" class='input_protein' style="margin-left: 10px;">
                <!-- <mat-label>Outline form field</mat-label> -->
                <input matInput id="protein_id" placeholder="AT4G38970.1">
            </mat-form-field>
    
            <button mat-flat-button color="primary" class="button_search" (click)="fn_search()">
                SEARCH
            </button>
        </div>
       
    </mat-card>

    <app-ptmcytoscape [cytoData]="ELEMENT_DATA" [dataType]="dataType"></app-ptmcytoscape>

</mat-card>
