<mat-card id="component-container" style="padding-bottom: 1px; padding-top: 1px;">

<mat-card class="img-upload">
    <mat-card-title id="exptitle" style="margin-top: 10px;">Upload a Pathway Image</mat-card-title>
    <mat-card-content class="upload-content">
        <div>
            <span class="file-name-display">{{fileName}}</span>
            <button mat-raised-button class="button-color" (click)="fileInput.click()">
                <span>Browse</span>
                <input #fileInput type="file" name="file" id="file" (change)="onFileChanged($event)" style="display:none;" accept="image/*"/>
            </button>
        </div>

        <button mat-raised-button class="button-color" (click)="uploadFile()">Upload</button>
    </mat-card-content>
    <mat-card-actions *ngIf="imageError != ''">{{imageError}}</mat-card-actions>
    <mat-progress-bar mode="indeterminate" *ngIf='loadingImage'></mat-progress-bar>
</mat-card>
<mat-card id="containerCard" style="min-width: 1000px">
    <div [class.show]="selectedGraph" id="side-graph" *ngIf="img != null">
        <mat-table [dataSource]='graphTable' id='side-table' *ngIf="graphTable != null">
    
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>Clickable Gene Components</mat-header-cell>
                <mat-cell *matCellDef="let prot" 
                           class="titleCell" 
                           (mouseover)="drawMapForTitle(prot.gene_name)" 
                           (mouseout)="clearMap()"
                           (click)="openLink(prot)"
                           >
    
                           <mat-checkbox (change)="checkItem(prot)"></mat-checkbox>
                           <span>{{ prot.gene_name }}</span>
                </mat-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="['title']"></mat-header-row>
            
            <mat-row *matRowDef="let row; columns: ['title']"></mat-row>
    
        </mat-table>
    </div>
    <div [class.hidden]="img == undefined" class="imageArea">
        <div class="canvas-container">
            <canvas #canvas id="imgCanvas">
            </canvas>
        </div>
    </div>
    <mat-card class="graph-card" id="component-info" *ngIf="img != null">
        <mat-card-header *ngIf="selectedComponent != null && selectedComponent.title != ''">
            <mat-card-title>{{selectedComponent.title}}</mat-card-title>
            <mat-card-subtitle>Selected Component</mat-card-subtitle>
        </mat-card-header>
        <mat-card-header *ngIf="selectedComponent == null || selectedComponent.title == ''">
            <mat-card-title>Selected Component</mat-card-title>
        </mat-card-header>

        <mat-progress-bar mode="indeterminate" *ngIf='loadingComponent'></mat-progress-bar>
        <mat-card-content *ngIf="selectedComponent != null">
            <b><a class="button-link" mat-raised-button *ngIf="selectedComponent.uniProtId" [href]="'https://www.uniprot.org/uniprot/' + selectedComponent.uniProtId" target="_blank">Uniprot Link</a></b>
        </mat-card-content>
        <mat-card-content *ngIf="selectedComponent != null && selectedComponent.p3dbId">
            <p><b>EMBL:</b></p>
            <p *ngFor="let e of selectedComponent.EMBL">{{e}}</p>

            <p><b>Gene Symbol: </b></p>
            <p *ngFor="let g of selectedComponent.GeneSymbol">{{ g }}</p>

            <p><b>Protein Name: </b></p>
            <p>{{ selectedComponent.title }}</p>
        </mat-card-content>
    </mat-card>
    <div class="no-image" *ngIf="img == null">
        <mat-card-title>No Image Selected</mat-card-title>
        <mat-card-content>
            Upload an image with the form field above.
        </mat-card-content>
    </div>
  </mat-card>

  <mat-card class="feedback-upload">
    <mat-card-content class="feedback-inputs">
        <form [formGroup]='passwordForm'>
            <mat-form-field>
                <mat-label>Password to Mark for Review</mat-label>
                <input matInput placeholder="Password" formControlName="password">
            </mat-form-field>
        </form>
        <button mat-raised-button [disabled]="!passwordForm.valid" (click)="submitCheckedData()">Submit</button>
    </mat-card-content>
    <mat-card-actions *ngIf="markError != ''">{{markError}}</mat-card-actions>
    <mat-card-footer>
        <mat-progress-bar mode="indeterminate" *ngIf='loadingMarks'></mat-progress-bar>
    </mat-card-footer>
</mat-card>