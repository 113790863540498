<mat-card class="container-card">
    <mat-card class="background-card">
        <div style="padding-bottom: 1%;">
            <mat-card>
                <h1>Protein Data</h1>
            </mat-card>
        </div>
        <div>
            <mat-card *ngif = "!proteinData">
                <h1>Loading Data...</h1>
            </mat-card>
            <mat-card *ngIf = "proteinData">
                <table style="font-size: large;">
                    <h2>Details</h2>
                    <tr>
                        <td>Description</td><td>{{ proteinData.description }}</td>
                    </tr>
                    <tr>
                        <td>Organism</td><td>{{ proteinData.organism }}</td>
                    </tr>
                    <tr>
                        <td>No. of Sites</td><td>{{ proteinData.noSites }}</td>
                    </tr>
                    <tr>
                        <td>No. of Spectra</td><td>{{ proteinData.noSpectra }}</td>
                    </tr>
                    <tr>
                        <td>Reference</td><td> <a href= {{proteinData.preferenceLink}}> {{ proteinData.preferenceLink }} </a></td>
                    </tr>
                </table>
                <br>
                <br>
                <table style="font-size: large;">
                    <h2>Protein Information</h2>
                    <tr>
                        <td style="width: 50%;">Phosphosites and Sequence</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(1)">
                                Display
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <td style="width: 20%;">Accesions</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(3)">
                                Display
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <td style="width: 20%;">Protein Interactions</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(4)">
                                Display
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <td style="width: 20%;">Ontology</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(5)">
                                Display
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <td style="width: 20%;">Kinase-Substrate</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(6)">
                                Display
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <td style="width: 20%;">Domain Information</td>
                        <td>
                            <button mat-raised-button
                            class="button-color"
                            (click) = "toggle(7)">
                                Display
                            </button>
                        </td>
                    </tr>
                </table>
                
            </mat-card>
        </div>

        <div style="padding-top: 1%;"  *ngIf = "displayField == 1">
            <mat-card>
                <h1>Phosphosites</h1>
                <table mat-table
                    [dataSource]="proteinData.phosphosites" multiTemplateDataRows>
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef> <h1> Position </h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.position }} 

                            </td>
                            
                        </ng-container>

                        <ng-container matColumnDef="aminoAcid">
                            <th mat-header-cell *matHeaderCellDef> <h1> Amino Acid </h1> </th>
                            <td mat-cell *matCellDef="let protein"> {{ protein.aminoAcid }} </td>
                        </ng-container>
                                <!-- [routerLink] = "['/phosphorylation-data/',  protein.id, surroundingSequ, activeElement]" -->

                        <ng-container matColumnDef="phosphosite">
                            <th mat-header-cell *matHeaderCellDef> <h1> Phosphosite Data </h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                <button mat-raised-button class="button-color"
                                (click) = "navigatePhosphorylation(protein.id)">

                                    Details
                                </button></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="phosphositeColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: phosphositeColumns;"
                            class="example-element-row"></tr>
                </table>
            </mat-card>
        </div>

        <div style="padding-top: 1%;" *ngIf = "displayField == 1">
            <mat-card>
                <h1>Sequence</h1>
                <table mat-table
                    [dataSource]="[proteinData]" multiTemplateDataRows>
                    <!-- class="mat-elevation-z8"> -->
                        <ng-container matColumnDef="sequence">
                            <th mat-header-cell *matHeaderCellDef> <button mat-raised-button cdkCopyToClipboard="{{ proteinData.sequence }}">Copy to clipboard</button></th>
                            <td mat-cell *matCellDef="let protein"></td>
                        </ng-container>

                        <ng-container matColumnDef="sequenceData">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                <table>
                                    <tr>
                                        <th>Position</th>
                                        <th>Sequence</th>
                                    </tr>
                                    <tr *ngFor = "let segment of sequenceData">
                                        <td> {{ segment['len'] }} </td>
                                        <td style="font-family: monospace;"> <div [innerHtml] = "segment['data']"></div></td>
                                    </tr>
                                </table> 
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="sequenceColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: sequenceColumns;"
                            class="mat-column-sequDetail">
                        </tr>
                </table>
            </mat-card>
        </div>

        

        <div style="padding-top: 1%; text-align: center;"  *ngIf = "displayField == 3">
            <mat-card style="margin: auto;">
                <h1 style="text-align: left;">
                    Accessions
                </h1>
                <div style="display: inline-block;">
                    <table mat-table style="text-align: left;"
                        [dataSource]="proteinColumn1" multiTemplateDataRows
                        >
                            <ng-container matColumnDef="recordCol1">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let protein"> 
                                    {{ protein}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="['recordCol1']"></tr>
                            <tr mat-row *matRowDef="let element; columns: ['recordCol1'];"
                                class="example-element-row"></tr>
                    </table>
                </div>

                <div style="display: inline-block;">
                    <table mat-table style="text-align: right;"
                        [dataSource]="proteinColumn2" multiTemplateDataRows
                        style="display: inline-block;">
                            <ng-container matColumnDef="recordCol1">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let protein"> 
                                    {{ protein}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="['recordCol1']"></tr>
                            <tr mat-row *matRowDef="let element; columns: ['recordCol1'];"
                                class="example-element-row"></tr>
                    </table>
                </div>
            </mat-card>
        </div>

        <div style="padding-top: 1%;"  *ngIf = "displayField == 4">
            <mat-card>
                <h1>Protein Interaction</h1>
                <table mat-table
                    [dataSource]="proteinData.proteinInteractions" multiTemplateDataRows>
                        <ng-container matColumnDef="interactorA">
                            <th mat-header-cell *matHeaderCellDef> <h1> Interactor A</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.interactorA }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="interactorB">
                            <th mat-header-cell *matHeaderCellDef> <h1> Interactor B</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.interactorB }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="experimentalSystem">
                            <th mat-header-cell *matHeaderCellDef> <h1> Experimental System</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.experimentalSystem }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="source">
                            <th mat-header-cell *matHeaderCellDef> <h1>Source</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.source }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="pubmedID">
                            <th mat-header-cell *matHeaderCellDef> <h1> PUBMED ID</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.pubmedID }} 
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="interactionColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: interactionColumns;"
                            class="example-element-row"></tr>
                </table>
            </mat-card>
        </div>

        <div style="padding-top: 1%;"  *ngIf = "displayField == 5">
            <mat-card>
                <h1>Ontology</h1>
                <table mat-table
                    [dataSource]="proteinData.ontologies" multiTemplateDataRows>
                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef > <h1> ID</h1> </th>
                            <td mat-cell *matCellDef="let protein" style="width: 25%;"> 
                                {{ protein.ontologyID }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> <h1> Name</h1> </th>
                            <td mat-cell *matCellDef="let protein" style="width: 25%;"> 
                                {{ protein.name }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="synonym">
                            <th mat-header-cell *matHeaderCellDef> <h1> Synonym</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.synonym }} 
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="ontologyColumns"
                            class ="mat-column-sequDetail"></tr>
                        <tr mat-row *matRowDef="let element; columns: ontologyColumns;"
                            class="example-element-row"></tr>
                </table>
            </mat-card>
        </div>

        <div style="padding-top: 1%;" *ngIf = "displayField == 6">
            <mat-card>
                <h1>Kinase-Substrate</h1>
                <table mat-table
                    [dataSource]="[proteinData]" multiTemplateDataRows>
                    <!-- class="mat-elevation-z8"> -->
                        <ng-container matColumnDef="sequence">
                            <th mat-header-cell *matHeaderCellDef> <button mat-raised-button cdkCopyToClipboard="{{ proteinData.kinaseSubstrate }}">Copy to clipboard</button></th>
                            <td mat-cell *matCellDef="let protein"></td>
                        </ng-container>

                        <ng-container matColumnDef="sequenceData">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                <div *ngIf = "protein.kinaseSubstrate.length > 0">
                                    {{ protein.kinaseSubstrate }}
                                </div>
                                <div *ngIf = "protein.kinaseSubstrate.length == 0">
                                    No Kinase-Substrate Data
                                </div>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="sequenceColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: sequenceColumns;"
                            class="mat-column-sequDetail">
                        </tr>
                </table>
            </mat-card>
        </div>

        <div style="padding-top: 1%;" *ngIf = "displayField == 7">
            <mat-card>
                <h1>Domain Information</h1>
                <table mat-table
                    [dataSource]="proteinData.domainInformation" multiTemplateDataRows>
                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef> <h1> Domain ID</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.domainID }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> <h1> Name</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.applicationName }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef> <h1>Domain Description</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.domainDescription }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="start">
                            <th mat-header-cell *matHeaderCellDef> <h1>Start</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.matchStart }} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="end">
                            <th mat-header-cell *matHeaderCellDef> <h1> End</h1> </th>
                            <td mat-cell *matCellDef="let protein"> 
                                {{ protein.matchEnd }} 
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="domainColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: domainColumns;"
                            class="example-element-row"></tr>
                </table>
            </mat-card>
        </div>
        
    </mat-card>
</mat-card>
