import { Component, OnInit } from '@angular/core';
import *  as am5 from '@amcharts/amcharts5';
import *  as am5map from '@amcharts/amcharts5/map';
import * as am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import * as am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { GeolocatorService } from 'src/app/Services/geolocator.service';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';
import * as am5geodata_data_countries2 from '@amcharts/amcharts5-geodata/data/countries2';

@Component({
  selector: 'app-globe',
  templateUrl: './globe.component.html',
  styleUrls: ['./globe.component.css']
})
export class GlobeComponent implements OnInit {

  constructor(private geolocator: GeolocatorService, private dbConn: DatabaseConnService) { }

  ngOnInit(): void {
    this.createGlobe();
  }

  createGlobe() {
    var root = am5.Root.new("globediv");

    // Set themes
    root.setThemes([
      am5themes_Animated.default.new(root)
    ]);

    // Create the map chart
    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        panY: "rotateY",
        projection: am5map.geoOrthographic(),
        zoomLevel: 1
      })
    );

    var cont = chart.children.push(
      am5.Container.new(root, {
        layout: root.horizontalLayout,
        x: 20,
        y: 40
      })
    );

    // Add labels and controls
    cont.children.push(
      am5.Label.new(root, {
        centerY: am5.p50,
        text: "Globe"
      })
    );

    var switchButton = cont.children.push(
      am5.Button.new(root, {
        themeTags: ["switch"],
        centerY: am5.p50,
        icon: am5.Circle.new(root, {
          themeTags: ["icon"]
        })
      })
    );

    var colorset = am5.ColorSet.new(root, {});

    switchButton.on("active", function (ev) {
      if (!switchButton.get("active")) {
        chart.goHome();
        chart.set("projection", am5map.geoOrthographic());
        chart.set("panY", "rotateY");
        chart.set("zoomLevel", 1);

        chart.animate({
          key: "rotationX",
          from: 0,
          to: 360,
          duration: 30000,
          loops: Infinity
        });

        backgroundSeries.mapPolygons.template.set("fill", am5.color(0x007efc));

      } else {
        chart.goHome();
        chart.set("projection", am5map.geoMercator());
        chart.set("panY", "translateY");
        chart.set("rotationY", 0);
        chart.set("zoomLevel", 1.4);

        chart.animate({
          key: "rotationX",
          from: 0,
          to: 0,
          duration: 0
        });
        backgroundSeries.mapPolygons.template.set("fill", am5.color(0x007efc));

      }
    });

    cont.children.push(
      am5.Label.new(root, {
        centerY: am5.p50,
        text: "Map"
      })
    );

    // Create series for background fill
    var backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
    backgroundSeries.mapPolygons.template.setAll({
      fill: am5.color(0xa8c9ff),//am5.color(0x007efc),//3571fc
      fillOpacity: 0.5,
      strokeOpacity: 0
    });

    // Add background polygon
    backgroundSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180)
    });

    // Create main polygon series for countries
    // var polygonSeries = chart.series.push(
    //   am5map.MapPolygonSeries.new(root, {
    //     geoJSON: am5geodata_worldLow.default,
    //     fill: am5.color(0x408249)//0x8adbcc,3e8a49
    //   })
    // );

    var polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
      geoJSON: am5geodata_worldLow.default,
      exclude: ["AQ"]
    }));

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      fill: am5.color(0xaaaaaa),
      templateField: "polygonSettings"
    });
    
    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: colorset.getIndex(9)
    });

    // Create line series for trajectory lines
    var lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
    lineSeries.mapLines.template.setAll({
      stroke: root.interfaceColors.get("alternativeBackground"),
      strokeOpacity: 0.3
    });

    var cont_reset = chart.children.push(
      am5.Container.new(root, {
        layout: root.horizontalLayout,
        x: 1000,
        y: 20
      })
    );

    let home = chart.chartContainer.children.push(am5.Button.new(root, {
      label: am5.Label.new(root, {
        text: "Reset",
        paddingBottom: 2,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 2,
        centerX: -100
      })
    }));

    home.events.on("click", function (ev) {
      chart.goHome();
      if (switchButton.get("active")) {
        chart.set("zoomLevel", 1.4);
      }
    });

    cont_reset.children.push(home);

    // Create point series for markers
    var pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

    pointSeries.bullets.push(function () {
      var container = am5.Container.new(root, {});

      var circle = container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: am5.Color.fromHex(0xff0000),
          strokeOpacity: 0,
          tooltipText: "{title}"
        })
      );
      var circle2 = container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: am5.Color.fromHex(0xff0000),
          strokeOpacity: 0,
          tooltipText: "{title}"
        })
      );

      circle.animate({
        key: "scale",
        from: 1,
        to: 3,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity
      });

      circle.animate({
        key: "opacity",
        from: 1,
        to: 0,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity
      });

      return am5.Bullet.new(root, {
        sprite: container
      });
    });

    chart.animate({
      key: "rotationX",
      from: 0,
      to: 360,
      duration: 30000,
      loops: Infinity
    });

    this.dbConn.getIPAddresses().subscribe(data => {
      let db_ips: string[] = [];
      data.data.forEach(element => {
        db_ips.push(element.ip.split(':')[3]);
      });

      let ips = ['5.118.237.207',
        '79.106.250.27',
        '0.101.202.213',
        '104.231.7.248',
        '194.42.156.178',
        '37.163.177.19',
        '241.179.155.230',
        '255.42.32.229',
        '134.55.146.73',
        '22.172.165.182',
        '70.38.53.45',
        '160.251.100.251',
        '234.39.103.126',
        '168.195.215.71',
        '151.231.95.225',
        '34.59.30.251',
        '20.144.69.83',
        '55.191.205.249',
        '15.73.192.124',
        '67.27.21.87'];
      db_ips = db_ips.concat(ips);
      db_ips.forEach(ip => {
        this.geolocator.getLocationDetails(ip).subscribe(data => {
          if (data.status == "success") {
            console.log(data);
            addLocation(data.lon, data.lat, data.city, data.regionName, data.country);
          }
        });
      });

      function addLocation(longitude, latitude, city, state, country) {
        pointSeries.data.push({
          geometry: { type: "Point", coordinates: [longitude, latitude] },
          title: 'City: ' + city + '\nState: ' + state + '\nCountry: ' + country
        });
      }
    });

    var continents = {
      "AF": 0,
      "AN": 1,
      "AS": 2,
      "EU": 3,
      "NA": 4,
      "OC": 5,
      "SA": 6
    };

    var data = [];
    
    for (var id in am5geodata_data_countries2.default) {
      console.log(id);
      if (am5geodata_data_countries2.default.hasOwnProperty(id)) {
        var country = am5geodata_data_countries2.default[id];
        if (country.maps.length) {
          data.push({
            id: id,
            map: country.maps[0],
            polygonSettings: {
              fill: colorset.getIndex(continents[country.continent_code]),
            }
          });
        }
      }
    }
    console.log('data');
    console.log(data);
    polygonSeries.data.setAll(data);

    chart.appear(1000, 100);
  }

}
