import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import cytoscape from 'cytoscape';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import euler from 'cytoscape-euler';
import dagre from 'cytoscape-dagre';
import spread from 'cytoscape-spread';
import cola from 'cytoscape-cola';
//import d3Force from 'cytoscape-d3-force'
// import {getData} from 'src/app/pages/kicnetwork/kicnetwork.component';

@Component({
  selector: 'app-ptmcytoscape',
  templateUrl: './cytoscape.component.html',
  styleUrls: ['./cytoscape.component.scss'],
})
export class CytoscapePTMComponent implements OnInit {
  @Input() cytoData;
  @Input() dataType;

  graph;
  cytoDataFull;
  nodeKeys;
  edgeKeys;
  graphElements;
  layouts = [
    { value: 'Euler', ref: euler },
    { value: 'Dagre', ref: dagre },
    { value: 'Spread', ref: spread },
    { value: 'Cola', ref: cola },
    //{ value: "d3Force", ref: d3Force },
  ];
  showSelected = false;
  showProteinInfo = false;
  isPtmViewer = true;
  showNodeInfo = false;
  selected = {
    name: ['id', 'group', 'value 1', 'value 2'],
    display: ['ID', 'Group', 'Value 1', 'Value 2'],
    data: [],
  };
  nodeDetail = {
    name: '',
    protein: '',
    sequence: '',
    siteAccuracy: '',
    mod: '',
    pubmed: '',
  };
  proteinDetail = {
    sequence: '',
    species: '',
    symbols: '',
    variants: '',
    Pscore: '',
    Ntype: '',
  };
  filterForm;
  filters = [];
  styleForm;
  styles = [];
  selectedDetails = [];

  constructor(private fb: FormBuilder, private http: HttpClient) {
    //initiate layouts
    this.layouts.forEach((x) => {
      cytoscape.use(x['ref']);
    });
  }

  ngOnInit(): void {
    this.populateGraph();
    this.buildFilterForm();
    this.buildStyleForm();
    this.addDefaultStlyes();
  }

  ngOnChanges(): void {
    this.selectedDetails = [];
    
    if (this.graph)
      this.graph.elements().remove();

    if (this.cytoData && this.cytoData.elements.nodes[0]) {
      this.populateGraph();
      this.buildFilterForm();
      this.buildStyleForm();
      this.addDefaultStlyes();
      this.showNodeInfo = false;
      this.showProteinInfo = false;
    }
  }

  //Function to change the graphs layout.
  //Takes in a string
  changeLayout(layout) {
    this.graph
      .layout({
        name: layout.toLowerCase(),
      })
      .run();
  }
   getData(): void{
  console.log('jhjg');
  
}
  alphaNumericValidator(control: FormControl): ValidationErrors | null {
    let ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9_/ ]*$/;
    let ALPHA_NUMERIC_VALIDATION_ERROR = {
      alphaNumericError: 'Only alphanumeric values are allowed',
    };

    return ALPHA_NUMERIC_REGEX.test(control.value)
      ? null
      : ALPHA_NUMERIC_VALIDATION_ERROR;
  }

  //builds the angular form for the filters section
  buildFilterForm() {
    this.filterForm = this.fb.group({
      filterType: ['', Validators.required],
      filterProp: ['', Validators.required],
      filterOperator: ['', Validators.required],
      filterValue: ['', [Validators.required, this.alphaNumericValidator]],
      filterAndOr: [false],
    });
  }

  //builds style form
  buildStyleForm() {
    this.styleForm = this.fb.group({
      selectorType: [''],
      selectorValue: [''],
      styleType: [''],
      styleValue: [''],
    });
  }

  //adds the filter in the form to the filter array
  addFilter() {
    let filterType = this.filterForm.get('filterType').value;

    let filterProp = this.filterForm.get('filterProp').value;
    let filterOperator = this.filterForm.get('filterOperator').value;
    let filterValue = this.filterForm.get('filterValue').value;
    let filterAndOr = this.filterForm.get('filterAndOr').value;

    // for string inputs, we need to wrap the value in quotes
    /*if (isNaN(Number(filterValue))) {
      filterValue = '"' + filterValue + '"';
    }*/

    this.filters.push({
      type: filterType,
      operator: filterOperator,
      value: filterValue,
      property: filterProp,
      filter:
        filterType +
        ': ' +
        filterProp +
        ' ' +
        filterOperator +
        ' ' +
        filterValue,
      // andOr: filterAndOr
    });

    this.filter();
  }

  //adds the style in the form
  addStyle() {
    let selectorType = this.styleForm.get('selectorType').value;
    let selectorValue = this.styleForm.get('selectorValue').value;
    let styleType = this.styleForm.get('styleType').value;
    let styleValue = this.styleForm.get('styleValue').value;
    this.styles.push({
      selector: selectorType + '[' + selectorValue + ']',
      style: {
        [styleType]: styleValue,
      },
    });

    this.style();
    console.log(this.styles);
  }

  //applies styles to graph
  style() {
    this.graph.style().fromJson(this.styles);
  }

  //set default styles
  addDefaultStlyes() {
    this.styles.push(
      {
        selector: 'node',
        style: {
          content: 'data(id)',
          'border-color': 'black',
          'border-opacity': 1,
          'border-width': '2px',
        },
      },
      {
        selector: 'edge',
        style: {
          'target-arrow-shape': 'triangle',
        },
      }
    );
  }

  //removes filter from the filter array
  removeFilter(filt) {
    for (let x = 0; x < this.filters.length; x++) {
      if (filt == this.filters[x]) {
        this.filters.splice(x, 1);
        x = this.filters.length;
      }
    }
    this.filter();
  }

  //remove styles
  removeStyle(style) {
    for (let x = 0; x < this.styles.length; x++) {
      if (style == this.styles[x]) {
        this.styles.splice(x, 1);
        x = this.styles.length;
      }
    }
    this.style();
  }

  //Handles the graph's filtering logic
  //Essentially removes nodes and edges that do not match the filters criteria
  filter() {
    this.cytoData = JSON.parse(JSON.stringify(this.cytoDataFull));

    // hash of ids to keep
    let nodesToRemove = {};
    let edgesToKeep = {};

    for (var x = 0; x < this.filters.length; x++) {
      let curFilter = this.filters[x];

      // generic node filtration
      if (curFilter.type == 'node') {
        switch (curFilter.operator) {
          case '=':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] != curFilter.value) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;

          case '<':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] >= curFilter.value) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;

          case '>':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] <= curFilter.value) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;

          case '<=':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] > curFilter.value) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;

          case '>=':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] < curFilter.value) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;
        }
      }

      // generic edge filtration
      // these get spliced right away, so our comparisons are inverse
      if (curFilter.type == 'edge') {
        switch (curFilter.operator) {
          case '=':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] != curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;

          case '<':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] >= curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;

          case '>':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] <= curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;

          case '<=':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] > curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;

          case '>=':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] < curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;
        }
      }
    }

    // if a node is not in the filter but is neighbors with one that is,
    // we'll keep it
    // we need to add these nodes back at once to keep this from repeating unintentionally

    let nodesToAddBack = [];

    for (var i = 0; i < this.cytoData.elements.edges.length; i++) {
      let curEdge = this.cytoData.elements.edges[i].data;

      if (
        nodesToRemove[curEdge.target] != 1 ||
        nodesToRemove[curEdge.source] != 1
      ) {
        nodesToAddBack.push(curEdge.target);
        nodesToAddBack.push(curEdge.source);
      }
    }

    // add nodes to our list that have neighbors who survived the filter
    nodesToAddBack.forEach((id) => {
      nodesToRemove[id] = 0;
    });

    // now let's remove all nodes that got filtered out
    for (var i = this.cytoData.elements.nodes.length - 1; i >= 0; i--) {
      if (nodesToRemove[this.cytoData.elements.nodes[i].data.id] == 1)
        this.cytoData.elements.nodes.splice(i, 1);
    }

    for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
      let curEdge = this.cytoData.elements.edges[i].data;

      if (
        nodesToRemove[curEdge.target] == 1 ||
        nodesToRemove[curEdge.source] == 1
      ) {
        this.cytoData.elements.edges.splice(i, 1);
      }
    }

    this.populateGraph();
  }
  // nodeDetail = {
  //   name: '',
  //   protein: '',
  //   sequence: '',
  //   siteAccuracy: '',
  //   mod: '',
  //   pubmed: '',
  // };
  // proteinDetail = {
  //   sequence: '',
  //   species: '',
  //   symbols: '',
  //   variants: '',
  //   Pscore: '',
  //   Ntype: '',
  // };
  //Updates the selected array to contain the elements selected on the graph
  updateSelected() {
    let elements = this.graph.elements('node:selected');
    let edge = this.graph.elements('edge:selected');
    elements.forEach((element) => {
      let element_info = JSON.parse(JSON.stringify(element.data()));
      console.log(element_info);
      let details = element_info.details;
      if(element_info.type == "protein"){
        details["Phophorylation Score"] = Math.floor(details.Pscore * 100) / 100+ '\n(PhoScore = log(Special Count * Site Count))';
        details["Number of Modification Types"] = details.Ntype;
        details["Sequence"] = details.sequence;
        details["Species"] = details.species;
        details["Gene Symbols"] = details.symbols;
        details["Variants"] = details.variants;
        delete details.sequence;
        delete details.species;
        delete details.symbols;
        delete details.variants;
        delete details.Ntype;
        delete details.Pscore;
      }else{
        details["Mod"] = details.mod;
        details["Sequence"] = details.sequence;
        details["Pubmed"] = details.pubmed;
        details["Site Accuracy"] = details.siteAccuracy;
        delete details.mod;
        delete details.sequence;
        delete details.pubmed;
        delete details.siteAccuracy;
        delete details.count;
        delete details.length;
        delete details.modUpperCase;
        delete details.phosphatIds;
        delete details.ptmList;
        delete details.sequenceHash;
        delete details.type;
      }
      this.showDetails(details);
    });

    edge.forEach((element) => {
      let element_info = JSON.parse(JSON.stringify(element.data()));
      console.log(element_info);
      let details = {
        Counts:element_info.count,
      }
      this.showDetails(details);
    });
  }

  showDetails(e) {
    let element = document.getElementById('detail_info') as HTMLDivElement;
    this.selectedDetails = [];
    for (let key in e) {
      this.selectedDetails.push({
        title: key,
        detail: e[key],
      });
    }
    /*console.log(e);
    let details = this.details[0];
    if (e.type != 'protein') {
      this.nodeDetail.name = e.id;
      this.nodeDetail.mod = e.type;
      for (let node of details.nodes) {
        if (node.type + node.length == e.id) {
          this.nodeDetail.sequence = node.sequence;
          this.nodeDetail.siteAccuracy = node.siteAccuracy;
          this.nodeDetail.pubmed = node.pubmed.toString();
        }
      }
      this.showNodeInfo = true;
      this.showProteinInfo = false;
    } else {
      this.proteinDetail.sequence = details.protein.sequence;
      this.proteinDetail.species = details.protein.species;
      this.proteinDetail.symbols = details.protein.symbols.toString();
      this.proteinDetail.variants = details.protein.variants.toString();
      this.proteinDetail.Ntype = details.protein.Ntype.toString();
      this.proteinDetail.Pscore = details.protein.Pscore.toFixed(2).toString();
      this.showProteinInfo = true;
      this.showNodeInfo = false;
    }*/
  }

  //assigns the input data to cytoscape
  populateGraph() {
    if (this.cytoData != null && this.cytoDataFull == null)
      this.cytoDataFull = JSON.parse(JSON.stringify(this.cytoData));
    
    if (this.cytoData) {
      this.cytoData['container'] = document.getElementById('cy');
      const options = {
        maxZoom: 2,
        minZoom: 0.3,
        autoResize: true,
      };
      this.graph = cytoscape({ ...this.cytoData, ...options });

      this.graph
        .layout({
          name: 'cola',
        })
        .run();
      this.graphElements = this.graph.elements().clone();

      this.nodeKeys = Object.keys(this.cytoData.elements.nodes[0].data);
      this.edgeKeys = Object.keys(this.cytoData.elements.edges[0].data);
    }
  }
}
